import { HelpTooltips } from 'components/help/helpTooltips'
import { ToolLayoutPrimary, ViewContext } from 'components/lib'
import { useContext, useEffect, useReducer, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GreatIdeasAPIService } from 'service/great-ideas-rest-api.service'
import {
  greatIdeasGeneratedResultHistory,
  greatIdeasInitialState,
  greatIdeasInputHistory,
  setGreatIdeasGeneratedResultHistory,
  setGreatIdeasInputHistory,
} from 'store/toolInputHistoriesHolder/greatIdeas'
import WriterResult from 'toolComponents/writer/WriterResult'
import { History } from 'ui'
import IdeaGenerator from './IdeaGenerator'
// import IdeasModal from './IdeasModal'
import { T, useTranslate } from '@tolgee/react'
import { ContextModal } from 'components/document-context/ContextModal'
import { addContextToFormData } from 'components/document-context/helper'
import { useAccess } from 'components/hooks/access'
import { useDocumentContext } from 'components/hooks/context'
import { useLanguages } from 'components/hooks/language'
import { useStream } from 'components/hooks/stream'
import Tour from 'components/tour'
import { clamp, errorToast, languages, queryToObj } from 'helpers'
import eleoLogo from 'images/eleo_raw.svg'
import { useLocation } from 'react-router-dom'
import ActionModal from 'toolComponents/writer/ActionModal'
import UpgradePlan from 'toolComponents/writer/UpgradePlan'
import { ToneInputData } from 'toolComponents/writer/writer-options-data'
import { IdeaFor } from './great-ideas-options-data'

const actionTypes = {
  SET_TONE: 'SET_TONE',
  SET_IDEA: 'SET_IDEA',
  SET_LANGUAGE: 'SET_LANGUAGE',
  LOAD_FROM_QUERY: 'LOAD_FROM_QUERY',
  RESET: 'RESET',
}

// Tour steps
const steps = [
  {
    target: '#context-field',
    content: <T keyName='eleo-tour-context'></T>,
  },
  {
    target: '#input',
    content: (
      <T keyName='eleo-tour-ideas-1'>
        Describe the type of idea you are looking for. Briefly explain what Eleo should generate for
        you.
      </T>
    ),
  },
  {
    target: '#creativity',
    content: (
      <T keyName='eleo-tour-ideas-4'>
        Set the creativity level. Higher creativity results in more imaginative and unpredictable
        responses.
      </T>
    ),
  },
  {
    target: '#guru',
    content: (
      <T keyName='eleo-tour-ideas-5'>
        Choose the Guru option. Use a more advanced artificial intelligence model to get a better
        response.
      </T>
    ),
  },
]

function reducer(state, action) {
  switch (action.type) {
    case actionTypes.SET_TONE:
      return { ...state, tone: action.payload }
    case actionTypes.SET_IDEA:
      return { ...state, ideaFor: action.payload }
    case actionTypes.SET_LANGUAGE:
      return { ...state, language: action.payload }
    case actionTypes.LOAD_FROM_QUERY:
      const values = action.payload
      let data = {
        tone: ToneInputData.find((t) => values.tone?.toLowerCase() === t.value.toLowerCase())
          ?.value,
        ideaFor: IdeaFor.find((i) => values.for?.toLowerCase() === i.value.toLowerCase())?.value,
        language: languages.find((l) => values.language?.toLowerCase() === l.value.toLowerCase())
          ?.value,
      }

      data = Object.fromEntries(Object.entries(data).filter(([key, value]) => Boolean(value)))

      return { ...state, ...data }
    case actionTypes.RESET:
      return greatIdeasInitialState.history.selectOptionValues
    default:
      return state
  }
}

const GreatIdeasLayout = () => {
  const { state: completionState, abortController, fetch: fetchCompletion } = useStream()
  const dispatchAction = useDispatch()
  const location = useLocation()
  const documentContext = useDocumentContext('ideas')
  const greatIdeasInputHistoryValues = useSelector(greatIdeasInputHistory)
  const generatedTextHistoryValue = useSelector(greatIdeasGeneratedResultHistory)
  const context = useContext(ViewContext)
  const [folded, setFolded] = useState(false)
  const [isShowAdvanced, setIsShowAdvanced] = useState(greatIdeasInputHistoryValues.showAdvanced)
  const [wordLength, setWordLength] = useState(greatIdeasInputHistoryValues.wordLength)
  const [writerLengthOption, setWriterLengthOption] = useState(
    greatIdeasInputHistoryValues.writerLengthOption
  )
  const [isGuruModeEnabled, setIsGuruModeEnabled] = useState(
    greatIdeasInputHistoryValues.isGuruModeEnabled
  )
  const { t } = useTranslate()
  const hasAccess = useAccess()

  const [modalIsVisible, setModalIsVisible] = useState({
    content: '',
    visible: false,
  })
  const [generatedText, setGeneratedText] = useState(generatedTextHistoryValue)
  const [loading, setLoading] = useState({ name: '', status: false })
  const [text, setText] = useState(greatIdeasInputHistoryValues.text)
  const [details, setDetails] = useState(greatIdeasInputHistoryValues.details)
  const [negativeWords, setNegativeWords] = useState(greatIdeasInputHistoryValues.negativeWords)
  const [history, setHistory] = useState([])
  const [isHistoryVisible, setIsHistoryVisible] = useState(false)
  const [reloadHistoryData, setReloadHistoryData] = useState(0)
  const [checkedHistory, setCheckedHistory] = useState({
    radioBoxName: '',
    checked: false,
  })
  const [model, setModel] = useState(greatIdeasInputHistoryValues.model)

  const [state, dispatch] = useReducer(reducer, greatIdeasInputHistoryValues.selectOptionValues)

  const [creativity, setCreativity] = useState(greatIdeasInputHistoryValues.creativity)
  const [timeStamp, setTimeStamp] = useState(Date.now())

  const { defaultToolOutPutLanguage } = useLanguages()

  const optionsData = {
    text,
    details,
    negativeWords,
    wordLength,
    writerLengthOption,
    isGuruModeEnabled,
    state,
  }

  // Load form data from query params
  useEffect(() => {
    const data = queryToObj(location.search)

    dispatch({
      type: 'LOAD_FROM_QUERY',
      payload: data,
    })

    if (data.text) setText(data.text)
    if (data.details) setDetails(data.details)
    if (data.avoid) setNegativeWords(data.avoid)
    if (data.creativity) setCreativity(clamp(0, data.creativity, 10))
    if (data.guru) setIsGuruModeEnabled(true)
  }, [location])

  const handleClear = () => {
    documentContext.clearContext()
    setText('')
    setDetails('')
    setNegativeWords('')
    setIsGuruModeEnabled(false)
    setWordLength({ id: '500', checked: true })
    setWriterLengthOption('words')
    dispatch({ type: actionTypes.RESET })
    setCreativity(8)
    dispatch({
      type: actionTypes[`SET_LANGUAGE`],
      payload: defaultToolOutPutLanguage.value,
    })
  }

  useEffect(() => {
    if (!completionState.message) return
    setGeneratedText(completionState.message)
  }, [completionState.message])

  useEffect(() => {
    if (!completionState.command) return
    const cmd = completionState.command
    if (cmd.wordsUsed) context.setWordsLeft((prev) => prev - cmd.wordsUsed)
    if (cmd.error) errorToast(t('eleo-error-generic'))
  }, [completionState.command])

  const handleGenerateIdea = async (loaderName) => {
    setTimeStamp(Date.now())
    setGeneratedText('')
    setLoading({ name: loaderName || 'generate', status: true })

    const kwargs = {
      stream: true,
      temperature: Number(creativity) / 10,
    }
    let formData = new FormData()
    formData.append('tool', 'ideas')
    formData.append('model', model?.value)
    formData.append('writerOptions', JSON.stringify(optionsData))
    formData.append('kwargs', JSON.stringify(kwargs))
    formData = await addContextToFormData(formData, documentContext.docContext)

    try {
      await fetchCompletion('/api/ai/ideas', formData)
      setReloadHistoryData(new Date())
    } catch (error) {
      console.log(error)
    } finally {
      setLoading({ name: loaderName || 'generate', status: false })
    }
  }

  const handleStopRequest = () => {
    if (abortController) {
      // Abort the request by aborting the controller
      abortController.abort()
    }
  }

  const handleSetValuesToInputs = (data, itemToSet, showGeneratedTextValue) => {
    setText(data.text)
    setDetails(data.details)
    setNegativeWords(data.negativeWords)
    setWordLength({ id: data.length, checked: true })
    setWriterLengthOption(data.length_type)
    setCreativity(data.creativity)
    setIsGuruModeEnabled(data.isGuruModeEnabled)
    setTimeStamp(Date.now())
    setGeneratedText(data.generated_response)
    dispatch({ type: actionTypes.SET_IDEA, payload: data.idea })
    dispatch({ type: actionTypes.SET_LANGUAGE, payload: data.language })
    dispatch({ type: actionTypes.SET_TONE, payload: data.tone })

    if (data.context) {
      const story = documentContext.stories.data.find((story) => story.id === data.context.id)
      documentContext.addDocument({
        label: story.label,
        content: story.content,
        id: story.id,
        type: 'story',
      })
    }
  }

  //save input values into history
  useEffect(() => {
    dispatchAction(
      setGreatIdeasInputHistory({
        text,
        negativeWords,
        details,
        writerLengthOption,
        creativity,
        wordLength,
        selectOptionValues: state,
        showAdvanced: isShowAdvanced,
        isGuruModeEnabled,
        model,
      })
    )
    dispatchAction(setGreatIdeasGeneratedResultHistory(generatedText))
  }, [
    text,
    negativeWords,
    details,
    writerLengthOption,
    creativity,
    wordLength,
    state,
    loading,
    isShowAdvanced,
    isGuruModeEnabled,
    model,
    dispatchAction,
  ])

  // Fetch history
  useEffect(() => {
    const getHistory = async () => {
      const response = await GreatIdeasAPIService.getHistories()
      const { data } = await response.json()

      const historyData = data?.history.map((item) => ({
        id: item.id,
        question: item.text,
        answer: item.generated_response,
        date_created: item.date_created,
        context: item.context,
        words: item.wordsUsed,
        data: item,
      }))
      setHistory(historyData ?? [])
    }

    getHistory()
  }, [reloadHistoryData])

  async function handleLoadItem(id) {
    if (!hasAccess('Standard')) {
      context.modal.show({
        children: <UpgradePlan availableFrom='Standard' />,
        modalCardClassNames: '!bg-transparent !px-[0px] !py-[0px]',
      })
      return
    }
    const historyItem = history.find((item) => item.id === id)?.data

    const data = {
      text: historyItem.text,
      details: historyItem.details,
      negativeWords: historyItem.negativeWords,
      isGuruModeEnabled: historyItem.isGuruModeEnabled,
      language: historyItem.language,
      tone: historyItem.tone,
      idea: historyItem.for,
      creativity: historyItem.creativity,
      generated_response: historyItem.generated_response,
      length: historyItem.length,
      length_type: historyItem.length_type,
      context: historyItem.context?.isStory ? historyItem.context : null,
    }

    handleSetValuesToInputs(data, checkedHistory.radioBoxName, true)
    setIsHistoryVisible(false)
  }

  async function handleDeleteHistoryItems(items) {
    setModalIsVisible({ content: 'DELETE_HISTORY', visible: true, items: items })
  }
  async function handleClearHistory() {
    setModalIsVisible({ content: 'CLEAR_HISTORY', visible: true })
  }

  function handleTourProgress(e) {
    if (e.action === 'reset') return setIsShowAdvanced(false)
    if (!e.action === 'update') return

    const step = e.index

    if (step === 1) setIsShowAdvanced(true)
  }

  return (
    <>
      <ToolLayoutPrimary
        folded={folded}
        setFolded={setFolded}
        storageKey='ideas'
        leftSideContent={
          isHistoryVisible ? (
            <History
              headerText={t('eleo-history-header-ideas', 'great ideas / history')}
              dateText={t('eleo-history-item-date', 'Started:')}
              storyText={t('eleo-history-item-story', 'Your story:')}
              wordCounterText={t('eleo-history-item-words', 'words:')}
              historyEmptyText={t('eleo-tool-history-is-empty', 'history is empty')}
              clearHistoryText={t('eleo-history-header-clear', 'Clear History')}
              storyMissingText={t('eleo-history-item-story-missing', 'None')}
              logo={eleoLogo}
              storageKey='ideas'
              items={history}
              closeHistory={() => setIsHistoryVisible(false)}
              loadItem={handleLoadItem}
              removeItems={handleDeleteHistoryItems}
              clearHistory={handleClearHistory}
            />
          ) : (
            <IdeaGenerator
              reloadHistoryData={reloadHistoryData}
              setCheckedHistory={setCheckedHistory}
              checkedHistory={checkedHistory}
              dispatch={dispatch}
              history={history}
              setHistory={setHistory}
              setText={setText}
              setDetails={setDetails}
              setNegativeWords={setNegativeWords}
              actionTypes={actionTypes}
              setWordLength={setWordLength}
              setWriterLengthOption={setWriterLengthOption}
              writerLengthOption={writerLengthOption}
              wordLength={wordLength}
              loading={loading}
              handleClearValues={handleClear}
              handleGenerateIdea={handleGenerateIdea}
              creativity={creativity}
              setCreativity={setCreativity}
              optionsData={optionsData}
              setModalIsVisible={setModalIsVisible}
              handleSetValuesToInputs={handleSetValuesToInputs}
              isShowAdvanced={isShowAdvanced}
              setIsShowAdvanced={setIsShowAdvanced}
              isGuruModeEnabled={isGuruModeEnabled}
              setIsGuruModeEnabled={setIsGuruModeEnabled}
              isHistoryVisible={isHistoryVisible}
              setIsHistoryVisible={setIsHistoryVisible}
              documentContext={documentContext}
              model={model}
              setModel={setModel}
            />
          )
        }
        rightSideContent={
          <WriterResult
            generatedText={generatedText}
            setGeneratedText={setGeneratedText}
            regenerate={() => handleGenerateIdea('regenerate')}
            folded={folded}
            loading={loading}
            toolName='greatIdeas'
            handleSaveGeneratedTextHistory={(text) =>
              dispatchAction(setGreatIdeasGeneratedResultHistory(text))
            }
            handleStopRequest={handleStopRequest}
            selectedLanguage={state.language}
            topic={text}
            key={timeStamp}
          />
        }
      >
        <>
          {modalIsVisible.visible && (
            <ActionModal
              modalKey={modalIsVisible.content}
              items={modalIsVisible.items}
              loading={loading}
              setModalIsVisible={setModalIsVisible}
              setReloadHistoryData={setReloadHistoryData}
              setCheckedHistory={setCheckedHistory}
              checkedHistory={checkedHistory}
              clearHistoryEndpoint={() => GreatIdeasAPIService.clearHistory()}
              deleteSelectedHistoryEndpoint={(data) => GreatIdeasAPIService.deleteHistory(data)}
            />
          )}
          <ContextModal documentContext={documentContext} />
          <HelpTooltips tool='greatIdeas' />
          <Tour steps={steps} name='ideas' callback={handleTourProgress} />
        </>
      </ToolLayoutPrimary>
    </>
  )
}

export default GreatIdeasLayout
