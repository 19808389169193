/***
 *
 *   VIEW
 *   The view houses global components that are common to all views
 *   (notification, modal), handles errors and renders the layout
 *
 **********/

import { useState, createContext, useEffect, useContext, useCallback } from 'react'
import {
  AppLayout,
  AuthLayout,
  HomeLayout,
  OnboardingLayout,
  Modal,
  Notification,
  Loader,
  useNavigate,
  ChatBotLayout,
  AuthContext,
} from '../lib'
import axios from 'axios'
import defaultAvatar from '../../images/avatars/default_avatar.svg'
import { successToast, errorToast } from 'helpers'
import { useTranslate } from '@tolgee/react'
import UpgradeModal from 'components/modal/modalTemplates/UpgradeModal'
import { errorCodes } from './errorCodes'
import Cookies from 'js-cookie'
import { getParentDomain } from 'helpers'

// import './scss/normalize.scss';
// import './scss/view.scss';
// import './scss/typography.scss';

export const ViewContext = createContext()

export function View(props) {
  const authContext = useContext(AuthContext)

  const navigate = useNavigate()
  const { t } = useTranslate()

  const avatar = JSON.parse(localStorage.getItem('user'))?.avatar ?? defaultAvatar

  useEffect(() => {
    function handleExtensionLogin() {
      successToast(t('eleo-extension-login', 'Successfully authorized the Eleo Web Extension'))
    }
    document.addEventListener('onExtensionLogin', handleExtensionLogin)

    return () => {
      document.removeEventListener('onExtensionLogin', handleExtensionLogin)
    }
  }, [t])

  // state
  const [notification, setNotification] = useState({
    visible: 'hide',
    autoclose: true,
  })
  const [modal, setModal] = useState({})
  const [loading, setLoading] = useState(false)
  const [wordsLeft, setWordsLeft] = useState(null)
  const [imagesLeft, setImagesLeft] = useState(null)

  // Refetch tokens from the server
  const updateLimits = useCallback(async () => {
    if (!authContext?.user?.authenticated || !authContext?.user?.plan?.id) return
    try {
      const res = await axios.get('/api/account/limits')

      setWordsLeft(res.data.data.words)
      setImagesLeft(res.data.data.images)
    } catch (err) {
      handleError(err)
    }
  }, [authContext?.user])

  useEffect(() => {
    updateLimits()
  }, [updateLimits])

  // layouts
  const layouts = {
    app: AppLayout,
    home: HomeLayout,
    auth: AuthLayout,
    chatBot: ChatBotLayout,
    onboarding: OnboardingLayout,
  }

  // set title & layout
  document.title = t(props.title, props.title)
  let Layout = props.layout ? layouts[props.layout] : AppLayout

  if (!props.display) return false

  function showNotification(text, type, autoclose, format, icon) {
    setNotification({
      text: text,
      type: type,
      show: true,
      format: format,
      icon: icon,
      autoclose: autoclose,
    })

    if (autoclose) setTimeout(hideNotification, 2000)
  }

  function hideNotification() {
    setNotification({
      text: '',
      type: '',
      show: false,
      format: null,
      icon: null,
      autoclose: true,
    })
  }

  function showModal(content, callback) {
    let data = { ...modal }

    if (content) {
      for (let key in content) data[key] = content[key]

      data.show = true
      data.callback = callback
    }

    setModal(data)
  }

  function hideModal(cancel, res) {
    // callback?
    if (!cancel && modal.callback) modal.callback(modal.form, res)

    // reset the modal
    setModal({
      title: null,
      text: null,
      buttonText: null,
      url: null,
      method: null,
      show: false,
    })
  }

  function redirect401() {
    const registered = JSON.parse(localStorage.getItem('registered'))
    const path = window.location.pathname
    const next = path.substring(1)

    const featureRoutes = [
      '/dashboard',
      '/chat',
      '/writer',
      '/writer/reply',
      '/writer/modify',
      '/writer/check',
      '/advisor',
      '/translator',
      '/great-ideas',
      '/image',
      '/image/modify',
      '/image/gallery',
      '/account/your-story',
      '/account/chat-bot',
    ]

    if (!featureRoutes.includes(path)) return navigate(registered ? `/signin` : `/signup`)
    return navigate(registered ? `/signin?next=${next}` : `/signup?next=${next}`)
  }

  // Refresh access token by providing refresh token, if refresh token not exists or it's expired redirect to login page
  function refreshToken() {
    const refresh_token = JSON.parse(localStorage.getItem('user'))?.refresh_token
    if (!refresh_token) {
      localStorage.removeItem('user')
      Cookies.remove('eleo-user-status', { domain: getParentDomain() })
      return redirect401()
    }
    axios
      .post('/api/auth/refresh', {
        refresh_token,
      })
      .then((response) => {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.jwt
        authContext.update({ token: response.data.jwt })
      })
      .catch((err) => {
        localStorage.removeItem('user')
        Cookies.remove('eleo-user-status', { domain: getParentDomain() })
        redirect401()
      })
  }

  const refreshExpiredTokenClosure = () => {
    let isCalled = false
    let runningPromise = undefined
    return () => {
      if (isCalled) {
        return runningPromise
      } else {
        isCalled = true
        runningPromise = refreshToken()
        return runningPromise
      }
    }
  }
  // stores the function returned by refreshExpiredTokenClosure
  const refreshExpiredToken = refreshExpiredTokenClosure()

  function handleError(err, showBanner = false) {
    if (!err || err.code === 'ERR_NETWORK') return

    let message

    message = err.toString()

    if (err.response) {
      if (err.response.data?.message) message = err.response.data.message

      // Handle error codes from the backend (for translations)
      if (err.response.data?.code) {
        if (errorCodes[err.response.data.code]) message = errorCodes[err.response.data.code]
      }

      if (err.response.status) {
        switch (err.response.status) {
          case 401:
            refreshExpiredToken()
            break

          case 404:
            navigate('/notfound')
            break

          case 429:
            showNotification(err.response.data, 'error', false)
            break

          case 402: // payment required
            const plan = err.response?.data?.plan
            let query = ''
            if (plan) {
              query += `?plan=${plan}`
              if (err.response.data.period) query += `&period=${err.response.data.period}`
              if (err.response.data.start_date)
                query += `&start_date=${err.response.data.start_date}`
              navigate('/account/upgrade' + query)
            } else {
              // navigate('/account/plans')
              // errorToast(message)
              showModal({
                title: t('eleo-payment-required', 'Payment required'),
                children: <UpgradeModal tokens={err.response?.data?.code} />,
              })
            }
            break

          default:
            if (showBanner) showNotification(message, 'error', false)
            else {
              if (message) errorToast(message)
              else errorToast(t('eleo-error-generic', 'There was a problem with your request'))
            }
            break
        }
      }
    } else {
      // showNotification(message, 'error', false)
      errorToast(message)
    }
  }

  const context = {
    notification: {
      show: showNotification,
      hide: hideNotification,
      data: notification,
    },
    modal: {
      show: showModal,
      hide: hideModal,
      data: modal,
    },

    setLoading: (state) => setLoading(state),
    handleError: handleError,
    setWordsLeft: setWordsLeft,
    setImagesLeft: setImagesLeft,
    words: wordsLeft,
    images: imagesLeft,
    updateLimits: updateLimits,
    avatar: avatar,
  }

  return (
    <ViewContext.Provider value={context}>
      {notification.show && <Notification {...notification} />}

      {loading && <Loader fullScreen />}

      {modal.show && <Modal {...modal} />}

      {!props.widget ? (
        <Layout title={props.title} data={props.data} tokens={props.tokens ? props.tokens : null}>
          {props.display}
        </Layout>
      ) : (
        <> {props.display}</>
      )}
    </ViewContext.Provider>
  )
}
