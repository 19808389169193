import {
  AccountCircle,
  AutoStories,
  HelpCenter,
  Image,
  Lightbulb,
  QuestionAnswer,
  SmartToy,
  Stars,
  Subject,
  Translate,
  Groups,
} from '@mui/icons-material'
import { T } from '@tolgee/react'

export const AppNavData = [
  {
    label: <T keyName='eleo-nav-chat'>Chat</T>,
    icon: QuestionAnswer,
    link: '/chat',
  },
  {
    label: <T keyName='eleo-nav-writer'>Writer</T>,
    icon: Subject,
    link: '/writer',
    dropDown: [
      {
        label: <T keyName='eleo-nav-writer-write-text'>Write text</T>,
        link: '/writer',
      },
      {
        label: <T keyName='eleo-nav-writer-reply'>Reply</T>,
        link: '/writer/reply',
      },
      {
        label: <T keyName='eleo-nav-writer-modify-text'>Modify text</T>,
        link: '/writer/modify',
      },
      {
        label: <T keyName='eleo-nav-writer-check-text'>Check text</T>,
        link: '/writer/check',
      },
      {
        label: <T keyName='eleo-nav-advisor'>Advisor</T>,
        icon: Stars,
        link: '/advisor',
      },
      {
        label: <T keyName='eleo-nav-great-ideas'>Great Ideas</T>,
        icon: Lightbulb,
        link: '/great-ideas',
      },
    ],
  },
  {
    label: <T keyName='eleo-nav-translator'>Translator</T>,
    icon: Translate,
    link: '/translator',
  },
  {
    label: <T keyName='eleo-nav-images'>Images</T>,
    icon: Image,
    link: '/image',
    dropDown: [
      {
        label: <T keyName='eleo-nav-images-create-image'>Create Image</T>,
        link: '/image',
      },
      {
        label: <T keyName='eleo-nav-images-modify-image'>Modify Image</T>,
        link: '/image/modify',
      },
      {
        label: <T keyName='eleo-nav-images-my-gallery'>My gallery</T>,
        link: '/image/gallery',
      },
    ],
  },
  {
    label: <T keyName='eleo-chat-bot'>ChatBots</T>,
    icon: SmartToy,
    link: '/chatbot',
    dropDown: [
      {
        label: <T keyName='eleo-nav-chatbot-forum'>Chat Forum</T>,
        link: '/chatbot/library',
      },
      // {
      //   label: <T keyName='eleo-nav-chatbot-create'>Create Chatbot</T>,
      //   link: '/account/chat-bot',
      // },
      {
        label: <T keyName='eleo-nav-chatbot-my-chatbots'>My Chatbots</T>,
        link: '/chatbot/library/profile',
      },
    ],
  },
]

export const getAccountNavData = ({ isOwner, planName, signOut }) => [
  {
    label: <T keyName='eleo-your-story'>Your story</T>,
    icon: AutoStories,
    link: '/account/your-story',
  },
  {
    label: <T keyName='eleo-nav-profile-info'>Profile info</T>,
    icon: AccountCircle,
    link: '/account/profile',
    dropDown: [
      {
        label: <T keyName='eleo-nav-profile-edit-profile'>Edit profile</T>,
        icon: AutoStories,
        link: '/account/profile',
      },
      {
        label: <T keyName='eleo-nav-profile-user-groups'>User Groups</T>,
        icon: Groups,
        link: '/account/user-groups',
      },
      planName === 'Team' &&
        isOwner && {
          label: <T keyName='eleo-nav-profile-users'>Users</T>,
          link: '/account/users',
        },
      {
        divider: true,
      },
      // {
      //   label: <T keyName='eleo-your-story'>Your story</T>,
      //   icon: AutoStories,
      //   link: '/account/your-story',
      // },
      // {
      //   label: <T keyName='eleo-chat-bot'>ChatBot</T>,
      //   link: '/account/chat-bot',
      // },
      ...(isOwner
        ? [
            {
              label: <T keyName='eleo-nav-profile-billing-plans'>Plans</T>,
              link: '/account/plans',
            },
            {
              label: <T keyName='eleo-nav-profile-billing-buy-extra'>Buy extra words/images</T>,
              link: '/account/packs',
            },
            {
              label: <T keyName='eleo-nav-profile-billing-invoices'>Invoices</T>,
              link: '/account/invoices',
            },
            {
              label: <T keyName='eleo-nav-profile-billing-update-card'>Update credit card</T>,
              link: '/account/card',
            },
            {
              label: <T keyName='eleo-nav-profile-redeem'>Redeem gift code</T>,
              link: '/account/redeem',
            },
            {
              divider: true,
            },
          ]
        : []),
      {
        label: <T keyName='eleo-nav-profile-billing-security-password'>Password</T>,
        link: '/account/password',
      },
      {
        label: <T keyName='eleo-nav-profile-billing-security-2fa'>2FA</T>,
        link: '/account/2fa',
      },
      {
        label: <T keyName='eleo-app-sign-out'>Sign out</T>,
        action: signOut,
      },
    ],
  },
]

export const getBottomItem = () => {
  return {
    label: <T keyName='eleo-help-docs'>User guide</T>,
    icon: HelpCenter,
  }
}
