import React from 'react'

export const ToolGeneratorLayoutPrimary = ({ topContent, options, actions }) => {
  return (
    <div className='flex h-full'>
      <div className='flex h-full w-full flex-col justify-between '>
        <div className='flex h-[calc(100%-77px)] w-full flex-col'>
          {topContent}
          <div
            className='flex h-full flex-col overflow-y-auto overflow-x-clip px-4 pt-4'
            style={{ scrollbarGutter: 'stable both-edges' }}
          >
            {options}
          </div>
        </div>
        <div className='writer-actions bg-brand-grey-bg border-brand-gray-light sticky bottom-0 z-[300] flex items-center gap-[10px] border-t p-4'>
          {actions}
        </div>
      </div>
    </div>
  )
}
