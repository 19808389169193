import axios from 'axios'
import { ViewContext } from 'components/lib'
import { useContext } from 'react'
import LibraryItem from './LibraryItem'
import { Breadcrumbs, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { T } from '@tolgee/react'

export const ChatbotsList = ({ items, title, fetchItems }) => {
  const context = useContext(ViewContext)

  const handleLikeChatbot = async (id) => {
    try {
      const url = items.find((item) => item.id === id)?.isLiked
        ? '/api/user/liked_chatbots/remove'
        : '/api/user/liked_chatbots/add'
      await axios.post(url, { chatbot_id: id })

      fetchItems()
    } catch (err) {
      context.handleError(err)
    }
  }

  const handleRecommendChatbot = async (id) => {
    try {
      const url = items.find((item) => item.id === id)?.isRecommended
        ? '/api/user/recommended_chatbots/remove'
        : '/api/user/recommended_chatbots/add'
      await axios.post(url, { chatbot_id: id })

      fetchItems()
    } catch (err) {
      context.handleError(err)
    }
  }

  return (
    <div className='flex flex-col px-5'>
      <Breadcrumbs aria-label='breadcrumb'>
        <Link to='/chatbot/library'>
          <T keyName='eleo-library'>Library</T>
        </Link>

        <Typography sx={{ color: 'text.primary' }}>{title}</Typography>
      </Breadcrumbs>
      <div className='flex flex-wrap gap-4 p-4 md:gap-8'>
        {items.map((item) => {
          return (
            <LibraryItem
              key={item.id}
              item={item}
              handleLikeChatbot={handleLikeChatbot}
              handleRecommendChatbot={handleRecommendChatbot}
            />
          )
        })}
      </div>
    </div>
  )
}
