import { Animate, AuthContext } from 'components/lib'
import React, { useContext } from 'react'
import { ChatBotGeneralLayout } from 'toolComponents/chat-bot'

const ChatBot = ({ chatBotName, visible, setIsVisible, setChatIconSizes, widget = false }) => {
  const userInfo = useContext(AuthContext)
  return (
    <Animate>
      <ChatBotGeneralLayout
        chatBotName={chatBotName}
        widget={widget}
        visible={visible}
        setIsVisible={setIsVisible}
        setChatIconSizes={setChatIconSizes}
        userAccountId={userInfo?.user?.account_id}
      />
    </Animate>
  )
}

export default ChatBot
