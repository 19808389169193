import { useTranslate } from '@tolgee/react'
import axios from 'axios'
import { ViewContext } from 'components/lib'
import { useCallback, useContext, useEffect, useState } from 'react'
import { ChatbotsCarousel } from '../ChatbotsCarousel'
import { useSearchParams } from 'react-router-dom'
import { AccessTimeFilled, Search } from '@mui/icons-material'
import SectionSkeletonLoader from './SectionSkeletonLoader'

export const RecentlyAdded = ({ filter, signal, refetchData, searchHintsUI, clearSearch }) => {
  const context = useContext(ViewContext)
  const [items, setItems] = useState([])
  const { t } = useTranslate()
  const params = useSearchParams()[0]
  const [isLoading, setIsLoading] = useState(true)

  const fetchItems = useCallback(async () => {
    setIsLoading(true)
    try {
      const category = params.get('category')
      const res = await axios.get(
        `/api/chat-bot/library?page=1&pageSize=200&filter=${filter}${category ? `&category=${category}` : ''}`
      )
      setItems(res.data)
    } catch (err) {
      context.handleError(err)
    } finally {
      setIsLoading(false)
    }
  }, [filter, params])

  useEffect(() => {
    fetchItems()
  }, [fetchItems, signal])

  if (isLoading && !items.length) return <SectionSkeletonLoader />

  const title = t('recently-added-chatbots-title', 'RECENTLY ADDED CHATBOTS')

  return (
    <div className='flex flex-col gap-10 bg-[#f5f5fa]'>
      <ChatbotsCarousel
        items={items}
        title={title}
        icon={<AccessTimeFilled sx={{ height: 18, width: 18 }} />}
        refetchData={refetchData}
        seeMoreUrl='/chatbot/library/new'
      />
    </div>
  )
}
