import { Search } from '@mui/icons-material'
import SmartToyIcon from '@mui/icons-material/SmartToy'
import { T } from '@tolgee/react'
import ChatbotsCarouselHeader from './library/ChatbotsCarouselHeader'
import LibraryItem from './library/LibraryItem'

const MyChatBotList = ({
  myChatBots,
  isSearchActive,
  searchHintsUI,
  clearSearch,
  setCreateChatbotModalData,
  showChangeUserRoleModal,
  handleDeleteChatBot,
}) => {
  return (
    <div className='flex flex-col gap-[10px] p-[14px] lg:gap-[16px] lg:px-[50px] lg:pb-[30px] lg:pt-[25px]'>
      <ChatbotsCarouselHeader
        icon={
          isSearchActive ? (
            <Search sx={{ height: '18px', width: '18px' }} />
          ) : (
            <SmartToyIcon sx={{ height: '18px', width: '18px' }} />
          )
        }
        isSearchActive={isSearchActive}
        title={<T keyName='eleo-chatbot-library-my-chatbot-list'>created by me</T>}
        searchHintsUI={searchHintsUI}
        clearSearch={clearSearch}
      />
      <div>
        <div className='grid grid-cols-2 gap-[10px] sm:flex sm:flex-wrap sm:items-center lg:gap-[20px]'>
          <LibraryItem tertiary setCreateChatbotModalData={setCreateChatbotModalData} />
          {myChatBots?.length ? (
            <>
              {myChatBots.map((chatbot) => (
                <LibraryItem
                  key={chatbot.id}
                  item={chatbot}
                  secondary
                  setCreateChatbotModalData={setCreateChatbotModalData}
                  showChangeUserRoleModal={showChangeUserRoleModal}
                  handleDeleteChatBot={handleDeleteChatBot}
                />
              ))}
            </>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default MyChatBotList
