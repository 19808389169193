import { T } from '@tolgee/react'
import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'ui'

const ActionGroup = ({
  permission,
  groupInfo,
  showChatBotList,
  showAddMembersModal,
  showDeleteConfirmationModal,
}) => {
  return (
    <div className='flex flex-wrap items-center justify-between lg:flex-nowrap'>
      <div className='flex flex-wrap items-center justify-between gap-[10px] lg:flex-nowrap'>
        <Button
          color='violet'
          disabled={!groupInfo?.chatBots?.length && !permission.modifyToolAccessStatus}
          onClick={() => showChatBotList()}
          className='block !h-[36px] w-full whitespace-nowrap rounded-md px-3 py-2 text-center text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 lg:w-auto'
        >
          <T keyName='eleo-user-groups-see-chat-bot'> See attached chatbots </T>
        </Button>

        {permission.showUserInfo ? (
          <Link to='/chatbot/library/profile' className='w-full'>
            <Button
              color='violet'
              className='block !h-[36px] w-full whitespace-nowrap rounded-md px-3 py-2 text-center text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 lg:w-auto'
            >
              <T keyName='eleo-user-groups-my-chat-bot'>My Chatbots</T>
            </Button>
          </Link>
        ) : null}

        {permission.addMembers && (
          <Button
            onClick={() => showAddMembersModal()}
            color='violet'
            className='block !h-[36px] w-full whitespace-nowrap  rounded-md px-3 py-2 text-center text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 lg:w-auto'
          >
            <T keyName='eleo-user-groups-add-member-btn-text'> Add Member</T>
          </Button>
        )}
        {permission.addAdmins && (
          <Button
            onClick={() => showAddMembersModal(true)}
            color='violet'
            className='block !h-[36px] w-full whitespace-nowrap rounded-md  px-3 py-2 text-center text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 lg:w-auto'
          >
            <T keyName='eleo-user-groups-add-admins-btn-text'> Add Admins</T>
          </Button>
        )}
        {permission.deleteGroup && (
          <Button
            onClick={() => showDeleteConfirmationModal()}
            className='bg-brand-pink hover:bg-brand-pink/70 block !h-[36px] w-full whitespace-nowrap rounded-md px-3 py-2 text-center text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 lg:w-auto'
          >
            <T keyName='eleo-user-groups-delete-group-btn-text'> Delete Group</T>
          </Button>
        )}
      </div>
    </div>
  )
}

export default ActionGroup
