import { T, useTranslate } from '@tolgee/react'
import { ContextModal } from 'components/document-context/ContextModal'
import { addContextToFormData } from 'components/document-context/helper'
import { HelpTooltips } from 'components/help/helpTooltips'
import { useAccess } from 'components/hooks/access'
import { useDocumentContext } from 'components/hooks/context'
import { useLanguages } from 'components/hooks/language'
import { useStream } from 'components/hooks/stream'
import { AuthContext, ToolLayoutPrimary, ViewContext } from 'components/lib'
import Tour from 'components/tour'
import { convertCreativityToTemperature, errorToast, languages, queryToObj } from 'helpers'
import eleoLogo from 'images/eleo_raw.svg'
import { useContext, useEffect, useReducer, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { WriterAPIService } from 'service/writer-rest-api.service'
import {
  replyTextGeneratedResultHistory,
  replyTextInitialState,
  replyTextInputHistory,
  setReplyTextGeneratedResultHistory,
  setReplyTextInputHistory,
} from 'store/toolInputHistoriesHolder/reply'
import { History } from 'ui'
import ActionModal from '../ActionModal'
import UpgradePlan from '../UpgradePlan'
import WriterResult from '../WriterResult'
import { useGetTemplateList } from '../hooks/useGetTemplateList'
import { Creativity, replyTextActions } from '../writer-options-data'
import { replyTextReadyMadeTemplates } from '../writer-options-data/ready-made-templates'
import ReplyTextGenerator from './ReplyTextGenerator'

const actionTypes = {
  SET_LANGUAGE: 'SET_LANGUAGE',
  SET_ACTION: 'SET_ACTION',
  LOAD_FROM_QUERY: 'LOAD_FROM_QUERY',
  RESET: 'RESET',
}

// Tour steps
const steps = [
  {
    target: '#context-field',
    content: <T keyName='eleo-tour-context'></T>,
  },
  {
    target: '#template',
    content: (
      <T keyName='eleo-tour-reply-1'>
        Use a response template. You can use a ready-made or custom template.
      </T>
    ),
  },
  {
    target: '#text-to-reply-to',
    content: (
      <T keyName='eleo-tour-reply-2'>
        Enter the text you are responding to. Write or paste here a list, question, complaint, or
        suggestion.
      </T>
    ),
  },
  {
    target: '#what-to-reply',
    content: (
      <T keyName='eleo-tour-reply-3'>
        Write what you want to reply. Describe exactly what you want to include in the response.
      </T>
    ),
  },
  {
    target: '.writer-tour-options',
    content: (
      <T keyName='eleo-tour-reply-4'>
        Choose additional options. Indicate what you want to reply, select the length or language of
        the response.
      </T>
    ),
  },
  {
    target: '#save-template',
    content: (
      <T keyName='eleo-tour-reply-5'>
        Set your response template. This way, you can quickly respond to similar queries.
      </T>
    ),
  },
]

function reducer(state, action) {
  switch (action.type) {
    case actionTypes.SET_LANGUAGE:
      console.log(action.payload)
      return { ...state, language: action.payload }
    case actionTypes.SET_ACTION:
      return { ...state, action: action.payload }
    case actionTypes.LOAD_FROM_QUERY:
      const values = action.payload
      let data = {
        language: languages.find((l) => values.language?.toLowerCase() === l.value.toLowerCase())
          ?.value,
        action: replyTextActions.find((a) => values.action?.toLowerCase() === a.value.toLowerCase())
          ?.value,
      }

      data = Object.fromEntries(Object.entries(data).filter(([key, value]) => Boolean(value)))

      return { ...state, ...data }
    case actionTypes.RESET:
      return replyTextInitialState.history.selectOptionValues
    default:
      return state
  }
}

const ReplyLayout = () => {
  const { state: completionState, abortController, fetch: fetchCompletion } = useStream()
  const replyTextHistoryValues = useSelector(replyTextInputHistory)
  const generatedTextHistoryValue = useSelector(replyTextGeneratedResultHistory)
  const dispatchAction = useDispatch()
  const location = useLocation()
  const hasAccess = useAccess()
  const documentContext = useDocumentContext('writer-reply')

  const authContext = useContext(AuthContext)
  const [folded, setFolded] = useState(false)
  const [isShowAdvanced, setIsShowAdvanced] = useState(replyTextHistoryValues.showAdvanced)
  const context = useContext(ViewContext)
  const [generatedText, setGeneratedText] = useState(generatedTextHistoryValue)
  const [textToReply, setTextToReply] = useState(replyTextHistoryValues.textToReply)
  const [whatToReply, setWhatToReply] = useState(replyTextHistoryValues.whatToReply)
  const [wordLength, setWordLength] = useState(replyTextHistoryValues.wordLength)
  const [templateId, setTemplateId] = useState(replyTextHistoryValues.templateId)
  const [templateName, setTemplateName] = useState('')
  const [templateToDelete, setTemplateToDelete] = useState('')
  const [loading, setLoading] = useState({ name: '', status: false })
  const [modalIsVisible, setModalIsVisible] = useState({
    content: '',
    visible: false,
  })
  const [model, setModel] = useState(replyTextHistoryValues.model)

  const [writerLengthOption, setWriterLengthOption] = useState(
    replyTextHistoryValues.writerLengthOption
  )

  const [isGuruModeEnabled, setIsGuruModeEnabled] = useState(
    replyTextHistoryValues.isGuruModeEnabled
  )
  const [timeStamp, setTimeStamp] = useState(Date.now())

  const [creativity, setCreativity] = useState(replyTextHistoryValues.creativity)
  const [state, dispatch] = useReducer(reducer, replyTextHistoryValues.selectOptionValues)
  const [history, setHistory] = useState([])
  const [isHistoryVisible, setIsHistoryVisible] = useState(false)
  const [checkedHistory, setCheckedHistory] = useState({
    radioBoxName: '',
    checked: false,
  })

  const [reloadHistoryData, setReloadHistoryData] = useState(0)

  const { t } = useTranslate()

  const { defaultToolOutPutLanguage } = useLanguages()

  const { templateList, templateOptions, reload, templatesRecordId } = useGetTemplateList(
    'reply_text_templates',
    '/writer/reply/templates'
  )

  const optionsData = {
    textToReply,
    whatToReply,
    wordLength,
    writerLengthOption,
    creativity,
    isGuruModeEnabled,
    state,
  }

  // Load form data from query params
  useEffect(() => {
    const data = queryToObj(location.search)

    dispatch({
      type: 'LOAD_FROM_QUERY',
      payload: data,
    })

    if (data.source) setTextToReply(data.source)
    if (data.reply) setWhatToReply(data.reply)
    if (data.creativity)
      setCreativity(
        Creativity.find((c) => data.creativity?.toLowerCase() === c.value.toLowerCase())?.value
      )
    if (data.guru) setIsGuruModeEnabled(true)
  }, [location])

  const clearInputs = () => {
    documentContext.clearContext()
    setTextToReply('')
    setWhatToReply('')
    setCreativity('Medium')
    setIsGuruModeEnabled(false)
    setWordLength({ id: '500', checked: true })
    dispatch({ type: actionTypes.RESET })
    setTemplateId({ label: '', value: '' })
    setTemplateName('')
    dispatch({
      type: actionTypes[`SET_LANGUAGE`],
      payload: defaultToolOutPutLanguage.value,
    })
  }

  const handleDeleteTemplate = async (templateId) => {
    setTemplateToDelete(templateId)
    setModalIsVisible({ content: 'DELETE_TEMPLATE', visible: true })
  }

  useEffect(() => {
    if (!completionState.message) return
    setGeneratedText(completionState.message)
  }, [completionState.message])

  useEffect(() => {
    if (!completionState.command) return
    const cmd = completionState.command
    if (cmd.wordsUsed) {
      context.setWordsLeft((prev) => prev - cmd.wordsUsed)
    }
    if (cmd.error) errorToast(t('eleo-error-generic'))
  }, [completionState.command])

  const handleSubmitWriterOptions = async (loaderName) => {
    setTimeStamp(Date.now())
    setGeneratedText('')
    setLoading({ name: loaderName || 'generate', status: true })

    const kwargs = {
      stream: true,
      temperature: convertCreativityToTemperature(optionsData.creativity),
    }
    let formData = new FormData()
    formData.append('tool', 'replyText')
    formData.append('model', model?.value)
    formData.append('writerOptions', JSON.stringify(optionsData))
    formData.append('kwargs', JSON.stringify(kwargs))
    formData = await addContextToFormData(formData, documentContext.docContext)

    try {
      await fetchCompletion('/api/ai/writer', formData)
      setTimeout(() => setReloadHistoryData(new Date()), 500)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading({ name: loaderName || 'generate', status: false })
    }
  }

  const handleStopRequest = () => {
    if (abortController) {
      // Abort the request by aborting the controller
      abortController.abort()
    }
  }

  const handleTemplate = async () => {
    const data = {
      templatesRecordId,
      accountId: authContext.user.account_id,
      templateId: templateId.value || null,
      reply_text_templates: {
        templateName: templateName,
        textToReply,
        whatToReply,
        isGuruModeEnabled,
        wordLength: wordLength.id,
        writerLengthOption,
        creativity,
        language: state.language,
        action: state.action,
      },
    }

    if (!templateName.length) {
      toast.error(
        t('eleo-save-template-error-please-point-template-name', 'Please point template name')
      )
      return
    }

    // if (templateName && templateName.length < 5) {
    //   toast.error(
    //     t('eleo-save-template-error-name-length', 'Template name should be more than 5 characters')
    //   )
    //   return
    // }

    try {
      setLoading({ name: 'template', status: true })
      const response = templateId.value
        ? await WriterAPIService.updateReplyTextTemplate(data)
        : await WriterAPIService.saveReplyTextTemplate(data)
      const responseJSON = await response.json()
      if (responseJSON.success) {
        toast.success(t(`eleo-success-${responseJSON.message}`, responseJSON.message))
        setModalIsVisible({ content: '', visible: false })
        setTemplateName('')
        reload()
      } else {
        toast.error(t(`eleo-error-${responseJSON.message}`, responseJSON.message))
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      setLoading({ name: 'template', status: false })
    }
  }

  const handleSetValuesToInputs = (data, itemToSet, showGeneratedTextValue) => {
    const template = itemToSet.data?.isReadyMade
      ? replyTextReadyMadeTemplates.find((item) => item.id === itemToSet.value)
      : data.find((item) => item.id === itemToSet.value)

    if (!template) return

    if (itemToSet.data?.isReadyMade) {
      documentContext.addDocument({
        label: itemToSet.label.toString(),
        type: 'template',
        content: template.value,
        id: template.id,
      })
    }

    if (template.context) {
      const story = documentContext.stories.data.find((story) => story.id === template.context.id)
      documentContext.addDocument({
        label: story.label,
        content: story.content,
        id: story.id,
        type: 'story',
      })
    }

    setTemplateName(template?.templateName)

    if (template?.isGuruModeEnabled) {
      setIsGuruModeEnabled(template.isGuruModeEnabled)
    }

    if (template.writerLengthOption) {
      setWriterLengthOption(template.writerLengthOption)
    }

    if (template.wordLength) {
      setWordLength({ id: template.wordLength, checked: true })
    }

    if (template.textToReply) {
      setTextToReply(template.textToReply)
    }
    if (template.whatToReply) {
      setWhatToReply(template.whatToReply)
    }

    if (template.creativity) {
      setCreativity(template.creativity)
    }
    if (showGeneratedTextValue) {
      setTimeStamp(Date.now())
      setGeneratedText(template.generatedText)
    }

    Object.keys(state).forEach((key) => {
      if (template[key]) {
        dispatch({
          type: actionTypes[`SET_${key.toUpperCase()}`],
          payload: template[key],
        })
      }
    })
  }

  useEffect(() => {
    if (templateId.value) {
      documentContext.removeDocumentsOfType('template')
      handleSetValuesToInputs(templateList, templateId, false)
    }
  }, [templateId, templateList])

  //save input values into history
  useEffect(() => {
    dispatchAction(
      setReplyTextInputHistory({
        model,
        textToReply,
        whatToReply,
        writerLengthOption,
        wordLength,
        creativity,
        isGuruModeEnabled,
        selectOptionValues: state,
        templateId,
        showAdvanced: isShowAdvanced,
      })
    )
    dispatchAction(setReplyTextGeneratedResultHistory(generatedText))
  }, [
    textToReply,
    whatToReply,
    writerLengthOption,
    wordLength,
    creativity,
    loading,
    state,
    templateId,
    isShowAdvanced,
    isGuruModeEnabled,
    dispatchAction,
    model,
  ])

  // Fetch history
  useEffect(() => {
    const getHistory = async () => {
      const response = await WriterAPIService.getReplyTextHistories()
      const { data } = await response.json()

      const historyData = data?.history.map((item) => ({
        id: item.id,
        question: item.replyText_template?.textToReply,
        answer: item.generated_response,
        date_created: item.date_created,
        context: item.replyText_template?.context,
        words: item.replyText_template?.wordsUsed,
        data: item,
      }))

      setHistory(historyData ?? [])
    }

    getHistory()
  }, [reloadHistoryData])

  async function handleLoadItem(id) {
    if (!hasAccess('Standard')) {
      context.modal.show({
        children: <UpgradePlan availableFrom='Standard' />,
        modalCardClassNames: '!bg-transparent !px-[0px] !py-[0px]',
      })
      return
    }
    const historyItem = history.find((item) => item.id === id)?.data

    const item = historyItem.replyText_template
    const template = {
      textToReply: item.textToReply,
      whatToReply: item.whatToReply,
      creativity: item.creativity,
      action: item.action,
      language: item.language,
      wordLength: item.wordLength,
      writerLengthOption: item.writerLengthOption,
      generatedText: historyItem.generated_response,
      isGuruModeEnabled: item.isGuruModeEnabled,
      context: historyItem.replyText_template?.context?.isStory
        ? historyItem.replyText_template.context
        : null,
    }

    handleSetValuesToInputs([template], checkedHistory.radioBoxName, true)
    setIsHistoryVisible(false)
  }

  async function handleDeleteHistoryItems(items) {
    setModalIsVisible({ content: 'DELETE_HISTORY', visible: true, items: items })
  }

  async function handleClearHistory() {
    setModalIsVisible({ content: 'CLEAR_HISTORY', visible: true })
  }

  return (
    <>
      <ToolLayoutPrimary
        folded={folded}
        setFolded={setFolded}
        storageKey='reply'
        leftSideContent={
          isHistoryVisible ? (
            <History
              headerText={t('eleo-history-header-reply', 'reply / history')}
              dateText={t('eleo-history-item-date', 'Started:')}
              storyText={t('eleo-history-item-story', 'Your story:')}
              wordCounterText={t('eleo-history-item-words', 'words:')}
              historyEmptyText={t('eleo-tool-history-is-empty', 'history is empty')}
              clearHistoryText={t('eleo-history-header-clear', 'Clear History')}
              storyMissingText={t('eleo-history-item-story-missing', 'None')}
              logo={eleoLogo}
              storageKey='reply'
              items={history}
              closeHistory={() => setIsHistoryVisible(false)}
              loadItem={handleLoadItem}
              removeItems={handleDeleteHistoryItems}
              clearHistory={handleClearHistory}
            />
          ) : (
            <ReplyTextGenerator
              dispatch={dispatch}
              actionTypes={actionTypes}
              setWordLength={setWordLength}
              setTextToReply={setTextToReply}
              setWhatToReply={setWhatToReply}
              wordLength={wordLength}
              setWriterLengthOption={setWriterLengthOption}
              writerLengthOption={writerLengthOption}
              setCreativity={setCreativity}
              creativity={creativity}
              handleSubmitWriterOptions={handleSubmitWriterOptions}
              setModalIsVisible={setModalIsVisible}
              clearInputs={clearInputs}
              optionsData={optionsData}
              templateOptions={templateOptions}
              setTemplateId={setTemplateId}
              loading={loading}
              history={history}
              setHistory={setHistory}
              checkedHistory={checkedHistory}
              setCheckedHistory={setCheckedHistory}
              reloadHistoryData={reloadHistoryData}
              templateId={templateId}
              handleDeleteTemplate={handleDeleteTemplate}
              handleSetValuesToInputs={handleSetValuesToInputs}
              isShowAdvanced={isShowAdvanced}
              setIsShowAdvanced={setIsShowAdvanced}
              isGuruModeEnabled={isGuruModeEnabled}
              setIsGuruModeEnabled={setIsGuruModeEnabled}
              isHistoryVisible={isHistoryVisible}
              setIsHistoryVisible={setIsHistoryVisible}
              documentContext={documentContext}
              model={model}
              setModel={setModel}
            />
          )
        }
        rightSideContent={
          <WriterResult
            generatedText={generatedText}
            setGeneratedText={setGeneratedText}
            regenerate={() => handleSubmitWriterOptions('regenerate')}
            folded={folded}
            loading={loading}
            toolName='writer-reply'
            handleSaveGeneratedTextHistory={(text) =>
              dispatchAction(setReplyTextGeneratedResultHistory(text))
            }
            handleStopRequest={handleStopRequest}
            selectedLanguage={state.language}
            topic={textToReply}
            key={timeStamp}
          />
        }
      >
        <>
          {modalIsVisible.visible && (
            <ActionModal
              modalKey={modalIsVisible.content}
              items={modalIsVisible.items}
              templateId={templateId}
              templateName={templateName}
              setTemplateName={setTemplateName}
              handleTemplate={handleTemplate}
              loading={loading}
              setModalIsVisible={setModalIsVisible}
              setReloadHistoryData={setReloadHistoryData}
              setCheckedHistory={setCheckedHistory}
              checkedHistory={checkedHistory}
              reloadTemplatesData={reload}
              clearHistoryEndpoint={() => WriterAPIService.clearReplyTextHistory()}
              deleteSelectedHistoryEndpoint={(data) =>
                WriterAPIService.deleteReplyTextHistory(data)
              }
              deleteTemplateEndpoint={() =>
                WriterAPIService.deleteReplyTextTemplate({
                  templateId: templateToDelete,
                })
              }
            />
          )}
          <ContextModal documentContext={documentContext} />
          <HelpTooltips group='writer' tool='reply' />
          <Tour steps={steps} name='writer-reply' />
        </>
      </ToolLayoutPrimary>
    </>
  )
}

export default ReplyLayout
