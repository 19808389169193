import CategoriesSwitch from './CategoriesSwitch'
import { ChatbotsList } from './ChatbotsList'

// Layour for chatbot library sections from 'see more'
export const SectionLayout = ({ items, title, fetchItems, filter, setFilter }) => {
  return (
    <div className='flex h-full flex-col'>
      <CategoriesSwitch filter={filter} setFilter={setFilter} />
      <ChatbotsList items={items} title={title} fetchItems={fetchItems} />
    </div>
  )
}
