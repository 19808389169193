import { createSlice } from '@reduxjs/toolkit'
import aiController from 'helpers/AIController'

export const chatbotInitialState = {
  default: {
    history: {
      chatLog: [],
      activeHistory: [],
      chatId: null,
      showHistory: false,
    },
    form: {
      inputValue: '',
      language: 'auto',
      isGuru: false,
      // story: null,
      isAutoAudio: false,
      isSearchWeb: false,
      model: { ...aiController.getDefaultTextModel(), description: undefined },
    },
  },
}

export const chatbotSlice = createSlice({
  name: 'chatbotLibrary',
  initialState: chatbotInitialState,
  reducers: {
    setLibraryChatbotData: (state, action) => {
      state[action.payload.chatbotId] = action.payload.data
    },
  },
})

// Action creators are generated for each case reducer function
export const { setLibraryChatbotData } = chatbotSlice.actions

//selectors
export const chatbotLibrary = (state) => state.chatbotLibrary
export default chatbotSlice.reducer
