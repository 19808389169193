import React from 'react'
import { Button } from 'ui'
import eleoLogo from '../../images/new-logos/new-logo.png'

const ChatNotActive = ({ showButton = true }) => {
  return (
    <div className='m-auto flex h-full   items-center justify-center'>
      <div className='flex flex-col gap-[15px]'>
        <img className='h-20 w-full object-contain' src={eleoLogo} alt='eleo logo' />
        <h1 className='text-brand-violet text-center text-2xl font-bold uppercase'>
          chat is not active
        </h1>
        {showButton ? (
          <Button color='violet' className='mx-auto max-w-[260px]' href='/'>
            <p className='text-[17px] font-medium uppercase leading-none'>explore eleo.ai</p>
          </Button>
        ) : null}
      </div>
    </div>
  )
}

export default ChatNotActive
