export function compareChatbotConfigs(obj1, obj2, keysToSkip = []) {
  let res = true
  const differences = []
  if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
    return [obj1 === obj2, differences]
  }

  if (Array.isArray(obj1) && Array.isArray(obj2)) {
    if (obj1.length !== obj2.length) {
      return [false, ['array']]
    }
    for (let i = 0; i < obj1.length; i++) {
      const isEqual = compareChatbotConfigs(obj1[i], obj2[i])[0]
      if (!isEqual) return [false, ['array']]
    }
    return [true, differences]
  }

  const keys1 = Object.keys(obj1)
  const keys2 = Object.keys(obj2)

  const commonKeys = keys1.filter((key) => keys2.includes(key))
  for (let key of commonKeys) {
    if (!compareChatbotConfigs(obj1[key], obj2[key])[0]) {
      if (key === 'author') continue
      if (keysToSkip.includes(key)) continue
      res = false

      differences.push(key)
    }
  }

  return [res, differences]
}
