import { useTolgee } from '@tolgee/react'
import classNames from 'classnames'
import { appSupportedLanguages } from 'helpers'
import { CaretDown } from 'phosphor-react'
import Select, { components } from 'react-select'

import { CircleFlag } from 'react-circle-flags'
import axios from 'axios'
import { useContext } from 'react'
import { AuthContext } from 'app/auth'

export const LangSelector = ({ className }) => {
  const tolgee = useTolgee(['pendingLanguage'])
  const authContext = useContext(AuthContext)

  const handleLanguageChange = async (locale) => {
    if (!locale) return
    tolgee.changeLanguage(locale.value)

    if (!authContext.user || authContext.user.language === locale.value) return
    // Save language to db
    try {
      const data = {
        language: locale.value,
      }
      const res = await axios.patch('/api/user/language', data)
      if (res.data?.data?.language) authContext.update({ language: res.data.data.language })
    } catch (err) {
      console.log(err)
    }
  }

  const selectedLanguage = appSupportedLanguages.find(
    (lang) => lang?.value === tolgee.getPendingLanguage()
  )

  const baseClasses = 'w-[120px] lg:w-[140px]'
  const containerClasses = classNames(baseClasses, className)

  return (
    <div className={containerClasses}>
      <div className='w-full'>
        <Select
          value={selectedLanguage}
          onChange={(data) => handleLanguageChange(data)}
          options={appSupportedLanguages}
          components={{
            IndicatorSeparator: () => null,
            DropdownIndicator: Indicator,
            Option: (props) => <Option {...props} />,
            SingleValue: (props) => <SingleValue {...props} />,
          }}
          isSearchable={false}
          styles={{
            control: (baseStyles) => ({
              ...baseStyles,
              padding: '4px 6px ',
              borderRadius: '14px',
              backgroundColor: '#e6e6e60',
              border: '1px solid #E6E6E6',
              boxShadow: '0 !important',
              '&:hover': {
                border: '1px solid #E6E6E6',
              },
              // This line disable the blue border
            }),
            dropdownIndicator: (baseStyles) => ({
              ...baseStyles,
              padding: '6px',
            }),
            option: (baseStyles, { isFocused }) => ({
              ...baseStyles,
              borderRadius: 4,
              margin: 0,
              padding: '5px 10px',
              '&:hover': {
                backgroundColor: '#6363E5',
                color: '#ffffff',
              },
              cursor: 'pointer',
              lineHeight: 1.2,
              backgroundColor: isFocused ? '#6363E5' : '#ffffff',
              color: isFocused ? '#ffffff' : '#363636',
            }),
            menu: (baseStyles) => ({
              ...baseStyles,
              // top: 52,
              zIndex: 99999999,
              borderRadius: 10,
              paddingTop: 0,
              // border: "1px solid #E6E6E6",
              boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
              marginTop: 0,
            }),
            menuList: (baseStyles) => ({
              ...baseStyles,
              paddingTop: 0,
              paddingBottom: 0,
            }),
            singleValue: (baseStyles) => ({
              ...baseStyles,
              color: '#363636',
              fontWeight: '400',
              fontSize: '14px',
              // This line disable the blue border
            }),

            valueContainer: (baseStyles) => ({
              ...baseStyles,
              padding: '2px 1px',
            }),
            input: (baseStyles) => ({
              ...baseStyles,
              margin: 0,
              padding: 0,
              // This line disable the blue border
            }),
          }}
        />
      </div>
    </div>
  )
}

function Indicator({ children, isFocused, hasValue, ...props }) {
  return (
    // @ts-ignore
    <components.DropdownIndicator {...props}>
      <CaretDown size={18} color='#363636' weight='bold' />
    </components.DropdownIndicator>
  )
}

function Option({ showDeleteIcon, isFocused, deleteAction, ...props }) {
  return (
    // @ts-ignore
    <components.Option {...props}>
      <div className='flex items-center gap-[10px]'>
        <div className='object-contain'>
          <CircleFlag countryCode={props.data.iso} style={{ height: '20px', width: '20px' }} />
        </div>
        <div className='overflow-hidden text-ellipsis  capitalize'>{props.label}</div>
      </div>
    </components.Option>
  )
}

function SingleValue({ showDeleteIcon, isFocused, deleteAction, ...props }) {
  return (
    // @ts-ignore
    <components.SingleValue {...props}>
      <div className='flex items-center gap-[10px]'>
        <div className='object-contain'>
          <CircleFlag countryCode={props.data.iso} style={{ height: '20px', width: '20px' }} />
        </div>
        <div className='overflow-hidden text-ellipsis'>{props.data.label}</div>
      </div>
    </components.SingleValue>
  )
}
