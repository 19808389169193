import classNames from 'classnames'
import React from 'react'
import ContentLoader from 'react-content-loader'

const SectionSkeletonLoader = ({ disableHeader, disablePadding, yPosition = '56' }) => {
  return (
    <div
      className={classNames(
        'rounded-[30px] bg-[#f5f5fa] ',
        !disablePadding ? 'p-[14px] lg:px-[50px] lg:py-[16px]' : ''
      )}
    >
      <ContentLoader
        speed={2}
        width='100%'
        height={360}
        backgroundColor='#e6e6ef'
        foregroundColor='#f5f5fa'
      >
        {!disableHeader && <rect x='0' y='0' rx='3' ry='3' width='100%' height='40' />}
        <rect x='0' y={yPosition} rx='3' ry='3' width='100%' height='360' />
      </ContentLoader>
    </div>
  )
}

export default SectionSkeletonLoader
