import { T } from '@tolgee/react'
import classNames from 'classnames'
import { ArrowsClockwise, Trash } from 'phosphor-react'
import EditIcon from '@mui/icons-material/Edit'
import React from 'react'
import { getGroupInitials } from '../groupList'
import moment from 'moment'

const GroupListItem = ({
  groupData,
  showDeleteUser,
  showResend,
  showInvitationStatus,
  showLastInteraction,
  handleResend,
  resendLoading,
  showAccessStatus,
  showManageChatbotAccessStatus,
  showDeleteGroupMemberConfirmationModal,
  showUserInfo,
  showChangeUserRoleModal,
}) => {
  const getNewestInteraction = (grantedChatBots) => {
    if (grantedChatBots?.length) {
      const newestInteraction = grantedChatBots.reduce((newest, bot) => {
        if (bot.lastInteraction && (!newest || moment(bot.lastInteraction).isAfter(newest))) {
          return moment(bot.lastInteraction)
        }
        return newest
      }, null)

      return newestInteraction ? newestInteraction.format('DD/MM/YYYY HH:mm') : '--'
    }
  }

  return (
    <div className='mt-8 flow-root'>
      <div className='-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
        <div className='inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8'>
          <table className='min-w-full divide-y divide-gray-300'>
            <thead>
              <tr>
                <th
                  scope='col'
                  className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0'
                >
                  <T keyName='eleo-groups-list-table-name'>Name</T>
                </th>
                {showInvitationStatus && (
                  <th
                    scope='col'
                    className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                  >
                    <T keyName='eleo-groups-list-table-invitation-status'>Invitation status</T>
                  </th>
                )}

                <th
                  scope='col'
                  className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                >
                  <T keyName='eleo-groups-list-table-role'>Role</T>
                </th>
                {showLastInteraction && (
                  <th
                    scope='col'
                    className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                  >
                    <T keyName='eleo-groups-list-table-last-interaction'>Last Interaction</T>
                  </th>
                )}

                {showAccessStatus && (
                  <th
                    scope='col'
                    className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                  >
                    <T keyName='eleo-groups-list-table-access-status'>Access Status</T>
                  </th>
                )}

                <th scope='col' className='relative py-3.5 pl-3 pr-4 sm:pr-0'>
                  <span className='sr-only'>resend</span>
                </th>
                <th scope='col' className='relative py-3.5 pl-3 pr-4 sm:pr-0'>
                  <span className='sr-only'>Remove</span>
                </th>
              </tr>
            </thead>
            <tbody className='divide-y divide-gray-200 bg-white'>
              {groupData?.map((person) => (
                <tr key={person.email}>
                  <td className='whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0'>
                    <div className='flex items-center'>
                      <div className='h-11 w-11 flex-shrink-0'>
                        {person.avatar ? (
                          <img
                            alt={person.name}
                            src={person.avatar}
                            className='h-11 w-11 rounded-full'
                          />
                        ) : (
                          <div class='relative inline-flex h-10 w-10 items-center justify-center overflow-hidden rounded-full bg-gray-100 dark:bg-gray-600'>
                            <span class='font-medium text-gray-600 dark:text-gray-300'>
                              {getGroupInitials(person.name || person.email)}
                            </span>
                          </div>
                        )}
                      </div>
                      <div className='ml-4'>
                        {person.name ? (
                          <div className='font-medium text-gray-900'>{person.name}</div>
                        ) : null}
                        {showUserInfo ? (
                          <div className='mt-1 text-gray-500'>{person.email}</div>
                        ) : null}
                      </div>
                    </div>
                  </td>
                  {showInvitationStatus && (
                    <td className='whitespace-nowrap px-3 py-5 text-sm text-gray-500'>
                      <span
                        className={classNames(
                          'inline-flex items-center rounded-md  px-2 py-1 text-xs font-medium  ring-1 ring-inset ring-green-600/20',
                          {
                            'bg-green-50 text-green-700': person.status === 'accepted',
                            'text-brand-pink bg-red-50': person.status === 'pending',
                          }
                        )}
                      >
                        <T
                          keyName={`eleo-groups-list-table-remove-from-group-status-${person.status}`}
                        >
                          {person.status}
                        </T>
                      </span>
                    </td>
                  )}
                  <td
                    onClick={() =>
                      showAccessStatus && showChangeUserRoleModal(person.id, person.permission)
                    }
                    className={classNames('whitespace-nowrap px-3 py-5 text-sm text-gray-500', {
                      underline: showAccessStatus,
                    })}
                  >
                    <T
                      keyName={`eleo-groups-list-table-remove-from-group-permission-${person.permission}`}
                    >
                      {person.permission}
                    </T>
                    {showAccessStatus && (
                      <EditIcon
                        style={{ fontSize: '15px' }}
                        titleAccess='Edit'
                        className='mb-[3px] ml-[4px] cursor-pointer'
                      />
                    )}
                  </td>
                  {showLastInteraction && (
                    <td className='whitespace-nowrap px-3 py-5 text-sm text-gray-500'>
                      {person?.grantedChatBots?.length
                        ? getNewestInteraction(person?.grantedChatBots)
                        : '--'}
                    </td>
                  )}

                  {showAccessStatus && (
                    <td className='whitespace-nowrap px-3 py-5 text-sm text-gray-500'>
                      <span
                        onClick={() =>
                          showManageChatbotAccessStatus(person?.grantedChatBots, person.id)
                        }
                        className={classNames(
                          'hover:bg-brand-violet inline-flex cursor-pointer  items-center rounded-md px-2 py-1   text-xs font-medium ring-1 ring-inset ring-green-600/20 hover:text-white'
                        )}
                      >
                        <T keyName='eleo-users-group-manage-access'>Manage chatbot access</T>
                      </span>
                    </td>
                  )}

                  {showResend && (
                    <td className='relative whitespace-nowrap py-5 pl-3 pr-4 text-center text-sm font-medium sm:pr-0'>
                      <div
                        onClick={() => handleResend(person.email)}
                        role='button'
                        className={classNames(
                          'text-brand-violet hover:text-brand-violet/700 flex cursor-pointer items-center justify-center gap-[10px]',
                          {
                            //not allow resend if user accepted invitation
                            'pointer-events-none opacity-60':
                              person.status !== 'pending' || resendLoading,
                          }
                        )}
                      >
                        <T keyName='eleo-groups-list-table-invitation-status-resend'>
                          Resend Invitation
                        </T>
                        <ArrowsClockwise />
                      </div>
                    </td>
                  )}
                  {showDeleteUser && (
                    <td className='relative whitespace-nowrap py-5 pl-3 pr-4 text-center text-sm font-medium sm:pr-0'>
                      <div
                        role='button'
                        className='text-brand-pink hover:text-brand-pink/700 flex cursor-pointer items-center justify-center gap-[10px]'
                        onClick={() => showDeleteGroupMemberConfirmationModal(person.id)}
                      >
                        <T keyName='eleo-groups-list-table-remove-from-group'>Remove From Group</T>
                        <Trash />
                      </div>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default GroupListItem
