import { CancelOutlined, Check, CheckBoxTwoTone } from '@mui/icons-material'
import { CircularProgress } from '@mui/material'
import { T } from '@tolgee/react'
import classNames from 'classnames'
import { useWindowDimensions } from 'components/hooks/window'
import { Loader } from 'components/lib'
import { successToast } from 'helpers'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import {
  SelectChatBotInputValues,
  SelectChatBotSettings,
  setClearFields,
  setSelectedChatBotSettings,
  setSelectedChatBotValues,
} from 'store/account/chatBot'
import { Microbutton } from 'ui'

const ChatActions = ({
  handleGenerateChatBot,
  isEditMode,
  loadingState,
  handleResetToServerState,
  isUpdatePending,
  isDraftSaved,
}) => {
  const dispatch = useDispatch()
  const { width } = useWindowDimensions()
  const chatBotInputValues = useSelector(SelectChatBotInputValues)
  const chatBotSettings = useSelector(SelectChatBotSettings)

  return (
    <>
      <Microbutton
        variant='transparent-light'
        type='large-font'
        className='h-[40px] w-[40px] min-w-0 truncate uppercase lg:w-auto lg:flex-1'
        onClick={() => {
          successToast(
            <div>
              <div className='mb-2 text-[13px] font-semibold'>
                <T keyName='eleo-chatbot-changes-reset'>Changes have been reset</T>
              </div>
              <div
                className='-m-2 p-2 text-[12px] font-light underline'
                onClick={() => {
                  dispatch(setSelectedChatBotValues(chatBotInputValues))
                  dispatch(setSelectedChatBotSettings(chatBotSettings))
                }}
              >
                <T keyName='eleo-undo'>undo</T>
              </div>
            </div>
          )
          isEditMode ? handleResetToServerState() : dispatch(setClearFields())
        }}
        data-tooltip-id='chatBotPanel-clear'
        text={width >= 1024 && <T keyName='eleo-chatbot-reset'>Reset changes</T>}
        icon={width < 1024 && <CancelOutlined fontSize='inherit' />}
      />

      <Microbutton
        variant='transparent-light'
        type='large-font'
        className={classNames(
          'h-[40px] min-w-[100px] flex-1 uppercase',
          (!isUpdatePending || loadingState.publish) && 'opacity-70',
          isDraftSaved && 'bg-brand-green-secondary hover:bg-brand-green-secondary/80 !text-white'
        )}
        onClick={() => handleGenerateChatBot(true)}
        data-tooltip-id='chatBotPanel-clear'
        disabled={!isUpdatePending || loadingState.publish || isDraftSaved}
        icon={isDraftSaved && <Check fontSize='inherit' />}
        iconSize={18}
      >
        <div className='flex items-center gap-[6px] truncate'>
          <div className='truncate'>
            {isDraftSaved ? (
              <T keyName='eleo-chatbot-draft-saved'>Draft up to date</T>
            ) : (
              <T keyName='eleo-chatbot-save-draft'>Save as draft</T>
            )}
          </div>
          {loadingState.draft && (
            <CircularProgress size={14} sx={{ animationDuration: '1000ms' }} color='inherit' />
          )}
        </div>
      </Microbutton>

      <Microbutton
        variant='solid'
        type='large-font'
        className={classNames(
          'h-[40px] min-w-[80px] flex-1 uppercase',
          loadingState.draft && 'opacity-70',
          isEditMode && !isUpdatePending && '!bg-brand-green-secondary'
        )}
        onClick={() => handleGenerateChatBot()}
        data-tooltip-id='chatBotPanel-clear'
        disabled={loadingState.draft || (isEditMode && !isUpdatePending)}
        icon={isEditMode && !isUpdatePending && <Check fontSize='inherit' />}
        iconSize={18}
      >
        <div className='flex items-center gap-[6px] truncate'>
          <div className='truncate'>
            {isEditMode ? (
              isUpdatePending ? (
                <T keyName='eleo-chatbot-publish-changes'>Publish changes</T>
              ) : (
                <T keyName='eleo-chatbot-published'>Published</T>
              )
            ) : (
              <T keyName='eleo-chatbot-publish'>Publish</T>
            )}
          </div>
          {loadingState.publish && (
            <CircularProgress size={14} sx={{ animationDuration: '1000ms' }} color='inherit' />
          )}
        </div>
      </Microbutton>
    </>
  )
}

export default ChatActions
