import axios from 'axios'

export class ChatBotAPIService {
  static generateRequest(data, segment, method = 'POST', signal) {
    if (method === 'POST') {
      return fetch(`${axios.defaults.baseURL}/api${segment}`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          Authorization: axios.defaults.headers.common['Authorization'],
        },
        ...signal,
      })
    } else {
      return fetch(`${axios.defaults.baseURL}/api${segment}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: axios.defaults.headers.common['Authorization'],
        },
      })
    }
  }

  static async generateChatBot(params) {
    delete params.wordsConsumed

    const data = {
      data: {
        chatBotId: params.selectedChatBot || null,
        ...params,
        tone: params.tone ?? null,
        storyId: params.storyId ?? null,
        intro: params.intro,
      },
    }
    return this.generateRequest(data, '/chat-bot/create', 'POST')
  }

  static async generateChatBotDraft(params) {
    delete params.wordsConsumed

    const data = {
      data: {
        originalChatBotId: params.selectedChatBot || null,
        ...params,
        tone: params.tone ?? null,
        storyId: params.storyId ?? null,
        intro: params.intro,
      },
    }
    return this.generateRequest(data, '/chat-bot/draft/create', 'POST')
  }

  static async deleteChatBot(data) {
    return this.generateRequest(data, '/chat-bot/delete', 'POST')
  }

  static async getChatBotList(data) {
    return this.generateRequest(data, '/chat-bot/list', 'GET')
  }

  static async getRecentLibraryChatBots(data) {
    return this.generateRequest(data, '/chat-bot/library/recent', 'GET')
  }
}
