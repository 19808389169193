import axios from 'axios'

export class UserGroupsAPIService {
  static async createGroup({ invitedEmails, groupName }) {
    const response = await axios.post('/api/user-groups/create', {
      invitedEmails,
      groupName,
    })
    return response.data
  }

  static async acceptToJoin(invitationId) {
    const response = await axios.post('/api/user-groups/accept-to-join', {
      invitationId,
    })
    return response.data
  }

  static async deleteGroup(groupId) {
    const response = await axios.delete(`/api/user-groups/${groupId}`)
    return response.data
  }

  static async addMember({ groupId, invitedEmails }) {
    const response = await axios.post('/api/user-groups/add-member', {
      groupId,
      invitedEmails,
    })
    return response.data
  }

  static async resendInvitation({ groupId, email }) {
    const response = await axios.post('/api/user-groups/user/resend-invitation', {
      groupId,
      email,
    })
    return response.data
  }

  static async deleteUserFromGroup(invitationId, groupId) {
    const response = await axios.delete(`/api/user-groups/user/${invitationId}`, {
      data: { groupId },
    })
    return response.data
  }

  static async getGroupList(restrictGroupUser = false) {
    const response = await axios.get(`/api/user-groups/list?restrictGroupUser=${restrictGroupUser}`)
    return response.data
  }

  static async getGroupById(groupId) {
    const response = await axios.get(`/api/user-groups/list/${groupId}`)
    return response.data
  }

  static async addAdmin({ groupId, invitedEmails }) {
    const response = await axios.post('/api/user-groups/add-admin', {
      groupId,
      invitedEmails,
    })
    return response.data
  }

  static async getUserInvitationList() {
    const response = await axios.get('/api/user-groups/my-invitations')
    return response.data
  }

  static async removeAdmin({ groupId, adminId }) {
    const response = await axios.post('/api/user-groups/remove-admin', {
      groupId,
      adminId,
    })
    return response.data
  }

  static async grantAccessToGroup({ chatBotId, groupId, grantStatus }) {
    const response = await axios.post('/api/user-groups/grant-access', {
      chatBotId,
      groupId,
      grantStatus,
    })
    return response.data
  }

  static async grantAccessToUser({ chatBotId, groupId, grantStatus, invitationId }) {
    const response = await axios.post('/api/user-groups/user/grant-access', {
      chatBotId,
      groupId,
      grantStatus,
      invitationId,
    })
    return response.data
  }

  static async changeUserRole({ groupId, newRole, invitationId }) {
    const response = await axios.post('/api/user-groups/role', {
      groupId,
      newRole,
      invitationId,
    })
    return response.data
  }

  static async removeChatBotFromGroup({ groupId, chatBotId }) {
    const response = await axios.post('/api/user-groups/chatbot/detach', {
      groupId,
      chatBotId,
    })
    return response.data
  }

  static async addChatBotToTheGroup({ groupId, chatBotId }) {
    const response = await axios.post('/api/user-groups/chatbot/attach', {
      groupId,
      chatBotId,
    })
    return response.data
  }

  static async checkChatbotEligibility({ chatBotId }) {
    const response = await axios.post('/api/user-groups/chatbot/check-eligibility', {
      chatBotId,
    })
    return response.data
  }

  static async checkPermission({ groupId }) {
    const response = await axios.post('/api/user-groups/check-role', {
      groupId,
    })
    return response.data
  }
}
