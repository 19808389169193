import { T, useTranslate } from '@tolgee/react'
import classNames from 'classnames'
import React from 'react'
import { Button } from 'ui'
import { CircleNotch } from 'phosphor-react'

const DeleteActionAlert = ({ loading, handleDelete, title }) => {
  return (
    <>
      <p className='text-brand-body mb-[10px] text-[18px] font-medium leading-[1.2em]'>
        <br />
        <div className='my-[10px]'>{title}</div>
        <span className='text-brand-pink'>
          <T keyName='eleo-delete-template-sub-header'>This action is irreversible.</T>
        </span>
      </p>
      <Button
        className={classNames('!hover:bg-[#FB427C] !float-none h-[45px] !w-full !bg-[#FB427C]', {
          'pointer-events-none opacity-60': loading,
        })}
        onClick={handleDelete}
      >
        <div className='flex items-center gap-[5px]'>
          {loading && (
            <CircleNotch weight='fill' color='#FFFFFF' size={24} className='animate-spin' />
          )}
          <p className='!mb-0 uppercase'>
            <T keyName='eleo-delete-template-confirm-action'>Delete</T>
          </p>
        </div>
      </Button>
    </>
  )
}

export default DeleteActionAlert
