import React, { useEffect } from 'react'
import UsersInGroupList from './usersInGroupList'
import { useParams } from 'react-router-dom'
import useGetGroupInfo from '../hooks/useGetGroupInfo'
const UserGroupView = ({}) => {
  const { userGroup } = useParams()

  const { handleGetGroupInfo, isGroupInfoLoading, groupInfo } = useGetGroupInfo(userGroup)
  useEffect(() => {
    handleGetGroupInfo()
  }, [userGroup])

  return (
    <div className='px-5 py-5'>
      <UsersInGroupList
        isGroupInfoLoading={isGroupInfoLoading}
        groupInfo={groupInfo}
        userGroup={userGroup}
        handleGetGroupInfo={handleGetGroupInfo}
      />
    </div>
  )
}

export default UserGroupView
