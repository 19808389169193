import React from 'react'
import { T, useTranslate } from '@tolgee/react'
import { Card, Form } from 'components/lib'
import { errorToast, successToast } from 'helpers'

const AddMembersModalView = ({ callBack, title, label, endPoint, groupId }) => {
  const { t } = useTranslate()
  return (
    <Card className='px-0' title={title} restrictWidth>
      <Form
        buttonText={<T keyName='eleo-profile-edit-profile-save-button'>Save</T>}
        url={endPoint}
        method='POST'
        inputs={{
          groupId: {
            value: groupId,
            type: 'hidden',
          },
          invitedEmails: {
            label,
            placeholder: t(
              'eleo-user-groups-create-group-invite-emails-placeholder',
              'Enter comma-separated email addresses (Max 10)'
            ),
            type: 'textarea',
            required: true,
            errorMessage: (
              <T keyName='eleo-user-groups-create-group-invite-emails-alert'>
                Please enter at least one valid email address
              </T>
            ),
          },
        }}
        callback={(res) => {
          if (res.data.success) {
            callBack()
            successToast(
              t('eleo-user-groups-add-member-invitation-sent', 'Invitation successfully sent')
            )
          } else {
            errorToast('eleo-error-generic')
          }
        }}
      />
    </Card>
  )
}

export default AddMembersModalView
