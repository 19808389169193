import { T, useTranslate } from '@tolgee/react'
import { ContextModal } from 'components/document-context/ContextModal'
import { addContextToFormData } from 'components/document-context/helper'
import { HelpTooltips } from 'components/help/helpTooltips'
import { useAccess } from 'components/hooks/access'
import { useDocumentContext } from 'components/hooks/context'
import { useLanguages } from 'components/hooks/language'
import { useStream } from 'components/hooks/stream'
import { AuthContext, ToolLayoutPrimary, ViewContext } from 'components/lib'
import Tour from 'components/tour'
import { errorToast, languages, queryToObj } from 'helpers'
import eleoLogo from 'images/eleo_raw.svg'
import { useContext, useEffect, useReducer, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { AdvisorAPIService } from 'service/advisor-rest-api.service'
import {
  advisorGeneratedResultHistory,
  advisorInitialState,
  advisorInputHistory,
  setAdvisorGeneratedResultHistory,
  setAdvisorInputHistory,
} from 'store/toolInputHistoriesHolder/advisor'
import ActionModal from 'toolComponents/writer/ActionModal'
import UpgradePlan from 'toolComponents/writer/UpgradePlan'
import WriterResult from 'toolComponents/writer/WriterResult'
import { useGetTemplateList } from 'toolComponents/writer/hooks/useGetTemplateList'
import { History } from 'ui'
import AdvisorGenerator from './AdvisorGenerator'
import { Author } from './advisor-options-data'
import { advisorReadyMadeTemplates } from './advisor-options-data/ready-made-templates'

const actionTypes = {
  SET_AUTHOR: 'SET_AUTHOR',
  SET_LANGUAGE: 'SET_LANGUAGE',
  LOAD_FROM_QUERY: 'LOAD_FROM_QUERY',
  RESET: 'RESET',
}

// Tour steps
const steps = [
  {
    target: '#context-field',
    content: <T keyName='eleo-tour-context'></T>,
  },
  {
    target: '#template',
    content: (
      <T keyName='eleo-tour-advisor-1'>
        Utilize a query template. You can use a ready-made template or create your own.
      </T>
    ),
  },
  {
    target: '#topic',
    content: (
      <T keyName='eleo-tour-advisor-3'>
        Enter the topic of the advice. Write precisely how Eleo can help you.
      </T>
    ),
  },
  {
    target: '#advanced',
    content: (
      <T keyName='eleo-tour-advisor-4'>
        Select additional options. Set the author, style, length, or language of the response.
      </T>
    ),
  },
  {
    target: '#guru',
    content: (
      <T keyName='eleo-tour-advisor-5'>
        Choose the Guru option. Use a more advanced artificial intelligence model to get a better
        response.
      </T>
    ),
  },
]

function reducer(state, action) {
  switch (action.type) {
    case actionTypes.SET_AUTHOR:
      return { ...state, author: action.payload }
    case actionTypes.SET_LANGUAGE:
      return { ...state, language: action.payload }
    case actionTypes.LOAD_FROM_QUERY:
      const values = action.payload
      let data = {
        author: Author.find((i) => values.author?.toLowerCase() === i.value.toLowerCase())?.value,
        language: languages.find((l) => values.language?.toLowerCase() === l.value.toLowerCase())
          ?.value,
      }

      data = Object.fromEntries(Object.entries(data).filter(([key, value]) => Boolean(value)))

      return { ...state, ...data }
    case actionTypes.RESET:
      return advisorInitialState.history.selectOptionValues
    default:
      return state
  }
}

const AdvisorLayout = () => {
  const { state: completionState, abortController, fetch: fetchCompletion } = useStream()
  const dispatchAction = useDispatch()
  const location = useLocation()
  const advisorInputHistoryValues = useSelector(advisorInputHistory)
  const generatedTextHistoryValue = useSelector(advisorGeneratedResultHistory)
  const authContext = useContext(AuthContext)
  const context = useContext(ViewContext)
  const [generatedText, setGeneratedText] = useState(generatedTextHistoryValue)
  const [topic, setTopic] = useState(advisorInputHistoryValues.topic)
  const [advisorContext, setAdvisorContext] = useState(advisorInputHistoryValues.advisorContext)
  const [folded, setFolded] = useState(false)
  const [isShowAdvanced, setIsShowAdvanced] = useState(advisorInputHistoryValues.showAdvanced)
  const hasAccess = useAccess()
  const documentContext = useDocumentContext('advisor')

  const [loading, setLoading] = useState({ name: '', status: false })
  const [state, dispatch] = useReducer(reducer, advisorInputHistoryValues.selectOptionValues)
  const [wordLength, setWordLength] = useState(advisorInputHistoryValues.wordLength)
  const [keyWords, setKeyWords] = useState(advisorInputHistoryValues.keyWords)
  const [writerLengthOption, setWriterLengthOption] = useState(
    advisorInputHistoryValues.writerLengthOption
  )
  const [isGuruModeEnabled, setIsGuruModeEnabled] = useState(
    advisorInputHistoryValues.isGuruModeEnabled
  )
  const [includeSources, setIncludeSources] = useState(advisorInputHistoryValues.includeSources)
  const [history, setHistory] = useState([])
  const [isHistoryVisible, setIsHistoryVisible] = useState(false)
  const [templateId, setTemplateId] = useState(advisorInputHistoryValues.templateId)
  const [templateToDelete, setTemplateToDelete] = useState('')
  const [reloadHistoryData, setReloadHistoryData] = useState(0)
  const [checkedHistory, setCheckedHistory] = useState({
    radioBoxName: '',
    checked: false,
  })
  const [timeStamp, setTimeStamp] = useState(Date.now())
  const [modalIsVisible, setModalIsVisible] = useState({
    content: '',
    visible: false,
  })

  const [contextPlaceHolder, setContextPlaceHolder] = useState({
    tolgee_id: '',
    value: '',
  })

  const [topicPlaceHolder, setTopicPlaceHolder] = useState({
    tolgee_id: '',
    value: '',
  })

  const [templateName, setTemplateName] = useState('')
  const { templateList, templateOptions, reload, templatesRecordId } = useGetTemplateList(
    'advisor_templates',
    '/advisor/templates'
  )
  const [model, setModel] = useState(advisorInputHistoryValues.model)

  const { t } = useTranslate()

  const { defaultToolOutPutLanguage } = useLanguages()

  const optionsData = {
    topic,
    advisorContext,
    wordLength,
    writerLengthOption,
    state,
    keyWords,
    isGuruModeEnabled,
    includeSources,
  }

  // Load form data from query params
  useEffect(() => {
    const data = queryToObj(location.search)

    dispatch({
      type: 'LOAD_FROM_QUERY',
      payload: data,
    })

    if (data.topic) setTopic(data.topic)
    if (data.context) setAdvisorContext(data.context)
    if (data.keywords) setKeyWords(data.keywords)
    if (data.guru) setIsGuruModeEnabled(true)
    if (data.sources) setIncludeSources(true)
  }, [location])

  const handleClear = () => {
    documentContext.clearContext()
    setTopic('')
    setAdvisorContext('')
    setWordLength({ id: '500', checked: true })
    setKeyWords('')
    setWriterLengthOption('words')
    setIsGuruModeEnabled(false)
    setIncludeSources(false)
    dispatch({ type: actionTypes.RESET })
    setTemplateId({ label: '', value: '' })
    setTemplateName('')
    dispatch({
      type: actionTypes[`SET_LANGUAGE`],
      payload: defaultToolOutPutLanguage.value,
    })
    setTopicPlaceHolder({ tolgee_id: '', value: '' })
    setContextPlaceHolder({ tolgee_id: '', value: '' })
  }

  const handleDeleteTemplate = async (templateId) => {
    setTemplateToDelete(templateId)
    setModalIsVisible({ content: 'DELETE_TEMPLATE', visible: true })
  }

  const handleSetValuesToInputs = (data, itemToSet, showGeneratedTextValue) => {
    const template = itemToSet.data?.isReadyMade
      ? advisorReadyMadeTemplates.find((item) => item.id === itemToSet.value)
      : data.find((item) => item.id === itemToSet.value)

    if (!template) return

    setTemplateName(template.templateName)
    if (itemToSet.data?.isReadyMade) {
      documentContext.addDocument({
        label: itemToSet.label.toString(),
        type: 'template',
        content: template.value,
        id: template.id,
      })
    }
    if (template?.contextPlaceHolder) {
      setContextPlaceHolder({
        tolgee_id: template.contextPlaceHolder.tolgee_id,
        value: template.contextPlaceHolder.value,
      })
    }

    if (template?.topicPlaceHolder) {
      setTopicPlaceHolder({
        tolgee_id: template.topicPlaceHolder.tolgee_id,
        value: template.topicPlaceHolder.value,
      })
    }

    if (template.writerLengthOption) {
      setWriterLengthOption(template.writerLengthOption)
    }

    if (template.isGuruModeEnabled) {
      setIsGuruModeEnabled(template.isGuruModeEnabled)
    }

    if (template.includeSources) {
      setIncludeSources(template.includeSources)
    }

    if (template.wordLength) {
      setWordLength({ id: template.wordLength, checked: true })
    }

    if (template.topic) {
      setTopic(template.topic)
    }
    if (template.advisorContext) {
      setAdvisorContext(template.advisorContext)
    }

    if (template.keyWords) {
      setKeyWords(template.keyWords)
    }

    if (showGeneratedTextValue) {
      setTimeStamp(Date.now())
      setGeneratedText(template.generatedText)
    }

    if (template.context) {
      const story = documentContext.stories.data?.find((story) => story.id === template.context.id)
      documentContext.addDocument({
        label: story.label,
        content: story.content,
        id: story.id,
        type: 'story',
      })
    }

    Object.keys(state).forEach((key) => {
      if (template[key]) {
        dispatch({
          type: actionTypes[`SET_${key.toUpperCase()}`],
          payload: template[key],
        })
      }
    })
  }

  const handleClearTemplate = () => {
    setTopicPlaceHolder({ tolgee_id: '', value: '' })
    setContextPlaceHolder({ tolgee_id: '', value: '' })
  }

  useEffect(() => {
    if (!completionState.message) return
    setGeneratedText(completionState.message)
  }, [completionState.message])

  const { setWordsLeft } = context
  useEffect(() => {
    if (!completionState.command) return
    const cmd = completionState.command
    if (cmd.wordsUsed) {
      setWordsLeft((prev) => prev - cmd.wordsUsed)
    }
    if (cmd.error) errorToast(t('eleo-error-generic'))
  }, [completionState.command, setWordsLeft])

  const submitAdvisor = async (loaderName) => {
    setTimeStamp(Date.now())
    setGeneratedText('')
    setLoading({ name: loaderName || 'generate', status: true })

    const kwargs = { stream: true, temperature: 0.2 }
    let formData = new FormData()
    formData.append('tool', 'advice')
    formData.append('model', model?.value)
    formData.append('writerOptions', JSON.stringify(optionsData))
    formData.append('kwargs', JSON.stringify(kwargs))
    formData = await addContextToFormData(formData, documentContext.docContext)

    try {
      await fetchCompletion('/api/ai/advisor', formData)
      setTimeout(() => setReloadHistoryData(new Date()), 500)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading({ name: loaderName || 'generate', status: false })
    }
  }

  const handleStopRequest = () => {
    if (abortController) {
      // Abort the request by aborting the controller
      abortController.abort()
    }
  }

  const handleTemplate = async () => {
    const data = {
      templatesRecordId,
      accountId: authContext.user.account_id,
      templateId: templateId.value || null,
      advisor_templates: {
        templateName: templateName,
        topic,
        advisorContext,
        keyWords,
        wordLength: wordLength.id,
        isGuruModeEnabled,
        includeSources,
        writerLengthOption,
        author: state.author,
        language: state.language,
      },
    }

    if (!templateName.length) {
      toast.error(
        t('eleo-save-template-error-please-point-template-name', 'Please point template name')
      )
      return
    }

    // if (templateName && templateName.length < 5) {
    //   toast.error(
    //     t('eleo-save-template-error-name-length', 'Template name should be more than 5 characters')
    //   )
    //   return
    // }

    try {
      setLoading({ name: 'template', status: true })
      const response = templateId.value
        ? await AdvisorAPIService.updateTemplate(data)
        : await AdvisorAPIService.saveTemplate(data)
      const responseJSON = await response.json()
      if (responseJSON.success) {
        toast.success(t(`eleo-success-${responseJSON.message}`, responseJSON.message))
        setModalIsVisible({ content: '', visible: false })
        setTemplateName('')
        reload()
      } else {
        toast.error(responseJSON.message)
      }
    } catch (error) {
      toast.error(t(`eleo-success-${error.message}`, error.message))
    } finally {
      setLoading({ name: 'template', status: false })
    }
  }

  useEffect(() => {
    if (templateId.value) {
      documentContext.removeDocumentsOfType('template')
      handleSetValuesToInputs(templateList, templateId, false)
    }
  }, [templateId, templateList])

  //save input values into history
  useEffect(() => {
    dispatchAction(
      setAdvisorInputHistory({
        topic,
        advisorContext,
        keyWords,
        writerLengthOption,
        isGuruModeEnabled,
        includeSources,
        wordLength,
        selectOptionValues: state,
        templateId,
        showAdvanced: isShowAdvanced,
        model,
      })
    )
    dispatchAction(setAdvisorGeneratedResultHistory(generatedText))
  }, [
    topic,
    advisorContext,
    keyWords,
    writerLengthOption,
    isGuruModeEnabled,
    includeSources,
    wordLength,
    loading,
    state,
    templateId,
    isShowAdvanced,
    generatedText,
    model,
    dispatchAction,
  ])

  // Fetch history
  useEffect(() => {
    const getHistory = async () => {
      const response = await AdvisorAPIService.getHistories()
      const { data } = await response.json()

      const historyData = data?.history.map((item) => ({
        id: item.id,
        question: item.advisor_template?.topic,
        answer: item.generated_response,
        date_created: item.date_created,
        context: item.advisor_template?.context,
        words: item.advisor_template?.wordsUsed,
        data: item,
      }))
      setHistory(historyData ?? [])
    }

    getHistory()
  }, [reloadHistoryData])

  async function handleLoadItem(id) {
    if (!hasAccess('Standard')) {
      context.modal.show({
        children: <UpgradePlan availableFrom='Standard' />,
        modalCardClassNames: '!bg-transparent !px-[0px] !py-[0px]',
      })
      return
    }
    const historyItem = history.find((item) => item.id === id)?.data

    const advisor = historyItem.advisor_template

    const template = {
      author: advisor.author,
      isGuruModeEnabled: advisor.isGuruModeEnabled,
      keyWords: advisor.keyWords,
      language: advisor.language,
      templateName: '',
      topic: advisor.topic,
      wordLength: advisor.wordLength,
      advisorContext: advisor.advisorContext,
      writerLengthOption: advisor.writerLengthOption,
      generatedText: historyItem.generated_response,
      context: advisor.context?.isStory ? advisor.context : null,
      includeSources: advisor.includeSources,
    }

    handleSetValuesToInputs([template], checkedHistory.radioBoxName, true)
    setIsHistoryVisible(false)
  }

  async function handleDeleteHistoryItems(items) {
    setModalIsVisible({ content: 'DELETE_HISTORY', visible: true, items: items })
  }
  async function handleClearHistory() {
    setModalIsVisible({ content: 'CLEAR_HISTORY', visible: true })
  }

  function handleTourProgress(e) {
    if (e.action === 'reset') return setIsShowAdvanced(false)
    if (!e.action === 'update') return

    const step = e.index

    if (step === 2) setIsShowAdvanced(true)
  }

  return (
    <ToolLayoutPrimary
      folded={folded}
      setFolded={setFolded}
      storageKey='advisor'
      leftSideContent={
        isHistoryVisible ? (
          <History
            headerText={t('eleo-history-header-advisor', 'advisor / history')}
            dateText={t('eleo-history-item-date', 'Started:')}
            storyText={t('eleo-history-item-story', 'Your story:')}
            wordCounterText={t('eleo-history-item-words', 'words:')}
            historyEmptyText={t('eleo-tool-history-is-empty', 'history is empty')}
            clearHistoryText={t('eleo-history-header-clear', 'Clear History')}
            storyMissingText={t('eleo-history-item-story-missing', 'None')}
            logo={eleoLogo}
            storageKey='advisor'
            items={history}
            closeHistory={() => setIsHistoryVisible(false)}
            loadItem={handleLoadItem}
            removeItems={handleDeleteHistoryItems}
            clearHistory={handleClearHistory}
          />
        ) : (
          <AdvisorGenerator
            loading={loading}
            dispatch={dispatch}
            actionTypes={actionTypes}
            setWordLength={setWordLength}
            wordLength={wordLength}
            writerLengthOption={writerLengthOption}
            setWriterLengthOption={setWriterLengthOption}
            optionsData={optionsData}
            setKeyWords={setKeyWords}
            setTopic={setTopic}
            setAdvisorContext={setAdvisorContext}
            isGuruModeEnabled={isGuruModeEnabled}
            setIsGuruModeEnabled={setIsGuruModeEnabled}
            submitAdvisor={submitAdvisor}
            handleClear={handleClear}
            handleSetValuesToInputs={handleSetValuesToInputs}
            history={history}
            setHistory={setHistory}
            checkedHistory={checkedHistory}
            setCheckedHistory={setCheckedHistory}
            reloadHistoryData={reloadHistoryData}
            setModalIsVisible={setModalIsVisible}
            setTemplateId={setTemplateId}
            templateOptions={templateOptions}
            templateId={templateId}
            handleDeleteTemplate={handleDeleteTemplate}
            isShowAdvanced={isShowAdvanced}
            setIsShowAdvanced={setIsShowAdvanced}
            contextPlaceHolder={contextPlaceHolder}
            topicPlaceHolder={topicPlaceHolder}
            handleClearTemplate={handleClearTemplate}
            isHistoryVisible={isHistoryVisible}
            setIsHistoryVisible={setIsHistoryVisible}
            includeSources={includeSources}
            setIncludeSources={setIncludeSources}
            documentContext={documentContext}
            model={model}
            setModel={setModel}
          />
        )
      }
      rightSideContent={
        <WriterResult
          generatedText={generatedText}
          setGeneratedText={setGeneratedText}
          regenerate={() => submitAdvisor('regenerate')}
          folded={folded}
          loading={loading}
          toolName='advisor'
          handleSaveGeneratedTextHistory={(text) =>
            dispatchAction(setAdvisorGeneratedResultHistory(text))
          }
          handleStopRequest={handleStopRequest}
          selectedLanguage={state.language}
          topic={topic}
          key={timeStamp}
        />
      }
    >
      <>
        {modalIsVisible.visible && (
          <ActionModal
            templateId={templateId}
            items={modalIsVisible.items}
            templateName={templateName}
            setTemplateName={setTemplateName}
            handleTemplate={handleTemplate}
            loading={loading}
            modalKey={modalIsVisible.content}
            setModalIsVisible={setModalIsVisible}
            setReloadHistoryData={setReloadHistoryData}
            setCheckedHistory={setCheckedHistory}
            checkedHistory={checkedHistory}
            clearHistoryEndpoint={() => AdvisorAPIService.clearHistory()}
            deleteSelectedHistoryEndpoint={(data) => AdvisorAPIService.deleteHistory(data)}
            deleteTemplateEndpoint={() =>
              AdvisorAPIService.deleteTemplate({ templateId: templateToDelete })
            }
            reloadTemplatesData={reload}
          />
        )}
        <ContextModal documentContext={documentContext} />

        <HelpTooltips tool='advisor' />
        <Tour steps={steps} name='advisor' callback={handleTourProgress} />
      </>
    </ToolLayoutPrimary>
  )
}

export default AdvisorLayout
