import { Settings } from '@mui/icons-material'
import { useTranslate } from '@tolgee/react'
import classNames from 'classnames'
import { useChatBotVisibilityOptions } from 'components/hooks/useChatBotVisibilityOptions'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const MyChatBotBadge = ({ chatBotVisibilityOption, chatbotPublishing, isDraft, name }) => {
  const { visibilityOptionsEnum } = useChatBotVisibilityOptions()
  const { t } = useTranslate()
  const navigate = useNavigate()
  const iconStyles = { height: '14px', width: '14px', color: '#ffffff' }

  if (isDraft) {
    return (
      <BadgeWrapper>
        <BadgeUI
          text={t('eleo-chatbot-library-draft', 'draft')}
          bgClasses='bg-brand-body'
          badgeIcon={<Settings sx={iconStyles} />}
        />
      </BadgeWrapper>
    )
  }

  // if (chatBotVisibilityOption === visibilityOptionsEnum.GROUPS) {
  //   return (
  //     <BadgeWrapper isFlex>
  //       <BadgeUI
  //         text={t('eleo-chatbot-library-published-on-forum', 'Published on forum')}
  //         bgClasses='bg-brand-green-secondary hover:bg-brand-green-tertiary transition-all duration-300'
  //         badgeIcon={<Settings sx={iconStyles} />}
  //       />
  //     </BadgeWrapper>
  //   )
  // }

  return (
    <BadgeWrapper isFlex>
      {(chatbotPublishing?.forum || chatBotVisibilityOption === visibilityOptionsEnum.PUBLIC) && (
        <BadgeUI
          text={t('eleo-chatbot-library-published-on-forum', 'Published on forum')}
          bgClasses='bg-brand-green-secondary hover:bg-brand-green-tertiary transition-all duration-300'
          badgeIcon={<Settings sx={iconStyles} />}
          onClick={() => navigate(`/chatbot/chat/${name}`)}
        />
      )}

      {(chatbotPublishing?.public || chatBotVisibilityOption === visibilityOptionsEnum.LINK) && (
        <BadgeUI
          text={t('eleo-chatbot-library-published-on-link', 'Published link')}
          bgClasses='bg-brand-violet-secondary hover:bg-brand-violet-tertiary transition-all duration-300'
          badgeIcon={<Settings sx={iconStyles} />}
          onClick={() => window.open(`/chat/${name}`, '_blank')}
        />
      )}
      {(chatbotPublishing?.private ||
        chatBotVisibilityOption === visibilityOptionsEnum.PRIVATE) && (
        <BadgeUI
          text={t('eleo-chatbot-library-published-privately', 'Published privately')}
          bgClasses='bg-brand-violet-secondary hover:bg-brand-violet-tertiary transition-all duration-300'
          badgeIcon={<Settings sx={iconStyles} />}
          onClick={() => navigate(`/chatbot/chat/${name}`)}
        />
      )}
    </BadgeWrapper>
  )
}

export default MyChatBotBadge

const BadgeWrapper = ({ children, isFlex }) => {
  return (
    <div
      className={classNames('absolute top-[10px] z-[11] -ml-[2px]', {
        'flex flex-col gap-[4px]': isFlex,
      })}
    >
      {children}
    </div>
  )
}

const BadgeUI = ({ text, badgeIcon, bgClasses, onClick }) => {
  return (
    <div
      onClick={onClick}
      className={classNames(
        'flex min-h-[26px] w-full max-w-[190px] cursor-pointer items-center gap-[5px]  rounded-br-[4px] rounded-tr-[4px] px-[6px] shadow-[1px_1px_4px_0px_rgba(0,0,0,0.35)]',
        bgClasses
      )}
    >
      {badgeIcon}
      <p className=' whitespace-pre text-wrap text-[12px]  font-medium uppercase leading-[1.2em] text-white'>
        {text}
      </p>
    </div>
  )
}
