import classNames from 'classnames'
import { CircleNotch } from 'phosphor-react'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { Button } from 'ui'

import { T } from '@tolgee/react'

const TemplateDeleteAlertContent = ({
  setModalIsVisible,
  reloadTemplatesData,
  deleteTemplateEndpoint,
  hideGlobalModal,
  mainTitle,
  callback,
}) => {
  const [loading, setLoading] = useState(false)

  const handleDeleteTemplate = async () => {
    try {
      setLoading(true)
      const response = await deleteTemplateEndpoint()

      const responseJSON = await response.json()
      if (responseJSON.success) {
        toast.success(
          <T keyName={`eleo-success-${responseJSON.message}`}>{responseJSON.message}</T>
        )
        reloadTemplatesData()
        if (callback) callback()
        if (hideGlobalModal) {
          hideGlobalModal()
        } else {
          setModalIsVisible({ content: '', visible: false })
        }
      } else {
        toast.error(<T keyName={`eleo-error-${responseJSON.message}`}>{responseJSON.message}</T>)
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      {' '}
      <p className='text-brand-body text-[18px] font-medium leading-[1.2em]'>
        {mainTitle ? (
          mainTitle
        ) : (
          <T keyName='eleo-delete-template-header'>
            Are you sure you want to delete this template?
          </T>
        )}{' '}
        <br />
        <span className='text-brand-pink'>
          <T keyName='eleo-delete-template-sub-header'>This action is irreversible.</T>
        </span>
      </p>
      <Button
        className={classNames(
          '!hover:bg-[#FB427C] !float-none !mt-[10px] h-[45px] !w-full !bg-[#FB427C]',
          {
            'pointer-events-none opacity-60': loading,
          }
        )}
        onClick={handleDeleteTemplate}
      >
        <div className='flex items-center gap-[5px]'>
          {loading && (
            <CircleNotch weight='fill' color='#FFFFFF' size={24} className='animate-spin' />
          )}
          <p className='!mb-0 uppercase'>
            <T keyName='eleo-delete-template-confirm-action'>Delete</T>
          </p>
        </div>
      </Button>
    </>
  )
}

export default TemplateDeleteAlertContent
