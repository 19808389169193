import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import { useTranslate } from '@tolgee/react'
import axios from 'axios'
import classNames from 'classnames'
import { useWindowDimensions } from 'components/hooks/window'
import { ViewContext } from 'components/lib'
import { useContext, useEffect, useRef, useState } from 'react'
import Slider from 'react-slick'
import ChatbotsCarouselHeader from './ChatbotsCarouselHeader'
import LibraryItem from './LibraryItem'

export const ChatbotsCarousel = ({
  items,
  title,
  refetchData,
  seeMoreUrl,
  searchHintsUI,
  clearSearch,
  isSearchActive,
  icon,
  disableSlider = false,
}) => {
  const { t } = useTranslate()
  const { width } = useWindowDimensions()
  const cardWidth = 220
  const context = useContext(ViewContext)
  const rowRef = useRef()
  const [itemsInRow, setItemsInRow] = useState(1)
  const useCarousel = width > 1024 ? itemsInRow < items.length && itemsInRow > 1 : true

  useEffect(() => {
    if (rowRef.current) {
      setItemsInRow(
        rowRef.current.clientWidth ? Math.floor(rowRef.current.clientWidth / cardWidth) : 1
      )
    }
  }, [width, cardWidth])

  const settings = {
    dots: false,
    infinite: items.length > 1,
    autoplay: false,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: items.length > 5 ? 5 : items.length,
    slidesToScroll: 1,

    className: 'library-carousel',

    centerMode: items.length > 1,
    centerPadding: '60px',
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: items.length >= 4,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3.5,
          infinite: items.length >= 3.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 3.5,
          slidesToScroll: 1,
          centerMode: items.length > 1,
          infinite: items.length >= 3.5,
          centerPadding: '40px',
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1,
          infinite: items.length >= 2.5,
          centerMode: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1,
          infinite: items.length >= 2.5,
          centerMode: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          infinite: items.length >= 2,
          slidesToScroll: 1,
          centerMode: false,
        },
      },
    ],
  }

  const handleLikeChatbot = async (id) => {
    try {
      const url = items.find((item) => item.id === id)?.isLiked
        ? '/api/user/liked_chatbots/remove'
        : '/api/user/liked_chatbots/add'
      await axios.post(url, { chatbot_id: id })

      refetchData(new Date())
    } catch (err) {
      context.handleError(err)
    }
  }

  const handleRecommendChatbot = async (id) => {
    try {
      const url = items.find((item) => item.id === id)?.isRecommended
        ? '/api/user/recommended_chatbots/remove'
        : '/api/user/recommended_chatbots/add'
      await axios.post(url, { chatbot_id: id })

      refetchData(new Date())
    } catch (err) {
      context.handleError(err)
    }
  }

  return (
    <div
      className={classNames(
        'flex h-full flex-col p-[14px] lg:px-[50px] lg:pb-[30px] lg:pt-[25px]',
        disableSlider ? 'gap-[16px]' : 'gap-[6px]'
      )}
    >
      <ChatbotsCarouselHeader
        isSearchActive={isSearchActive}
        icon={icon}
        title={title}
        clearSearch={clearSearch}
        searchHintsUI={searchHintsUI}
      />
      {disableSlider ? (
        <div className='flex  flex-wrap items-center gap-[20px]'>
          {items.map((item) => {
            return (
              <LibraryItem
                key={item.id}
                item={item}
                handleLikeChatbot={handleLikeChatbot}
                handleRecommendChatbot={handleRecommendChatbot}
              />
            )
          })}
        </div>
      ) : (
        <>
          <div
            ref={rowRef}
            className={classNames(
              'slider-container relative py-[10px]',
              useCarousel ? 'px-0' : 'flex gap-8 overflow-x-auto'
            )}
          >
            {useCarousel ? (
              <>
                <div className='absolute -left-[1rem] z-[9] hidden h-full w-24 bg-gradient-to-r from-[#f5f5fa] to-transparent  md:block'></div>
                <div className='absolute -right-[1rem] z-[9] hidden h-full w-24 bg-gradient-to-l from-[#f5f5fa] to-transparent  md:block'></div>
                <Slider {...settings}>
                  {items.map((item) => {
                    return (
                      <div key={item.id} className='pr-[10px]'>
                        <LibraryItem
                          key={item.id}
                          item={item}
                          handleLikeChatbot={handleLikeChatbot}
                          handleRecommendChatbot={handleRecommendChatbot}
                        />
                      </div>
                    )
                  })}
                </Slider>
              </>
            ) : (
              items.map((item) => {
                return (
                  <LibraryItem
                    key={item.id}
                    item={item}
                    handleLikeChatbot={handleLikeChatbot}
                    handleRecommendChatbot={handleRecommendChatbot}
                  />
                )
              })
            )}
          </div>
        </>
      )}

      {/* {seeMoreUrl && (
        <h4 className='flex justify-end'>
          <Link to={seeMoreUrl}>
            <T keyName={'eleo-see-more'}>See more</T>
          </Link>
        </h4>
      )} */}
    </div>
  )
}

function SampleNextArrow(props) {
  const { onClick } = props
  return (
    <div className='hidden lg:block'>
      <div
        className='absolute -right-[25px] top-[38%] z-[11]  flex h-[90px] w-[50px]  items-center justify-center rounded-[25px] bg-white shadow-[0_0_10px_rgba(0,0,0,0.25)]'
        onClick={onClick}
      >
        <button className='flex items-center justify-center'>
          <ChevronRight className='!text-brand-body' sx={{ height: '28px', width: '28px' }} />
        </button>
      </div>
    </div>
  )
}

function SamplePrevArrow(props) {
  const { onClick } = props
  return (
    <div className='hidden lg:block'>
      <div
        className='absolute -left-[20px] top-[38%] z-[11]  flex h-[90px] w-[50px]  items-center justify-center rounded-[25px] bg-white shadow-[0_0_10px_rgba(0,0,0,0.25)]'
        onClick={onClick}
      >
        <button className='flex items-center justify-center'>
          <ChevronLeft className='!text-brand-body' sx={{ height: '28px', width: '28px' }} />
        </button>
      </div>
    </div>
  )
}
