import { toast } from 'react-toastify'
import { T } from '@tolgee/react'

export const CopyToClipBoard = async (text, notificationText, htmlContent) => {
  if (!text && !htmlContent) return
  try {
    if (htmlContent) {
      const inlinedText = inlineAllStyles(htmlContent)
      const textBlob = new Blob([text], { type: 'text/plain' })
      const htmlBlob = new Blob([inlinedText], { type: 'text/html' })

      const clipboardItem = new ClipboardItem({
        [htmlBlob.type]: htmlBlob,
        [textBlob.type]: textBlob,
      })

      await navigator.clipboard.write([clipboardItem])
    } else await navigator.clipboard.writeText(text)

    toast(notificationText ?? <T keyName='eleo-copy-success'>Successfully copied to clipboard</T>, {
      position: 'bottom-right',
      type: 'success',
      theme: 'colored',
      icon: false,
      toastId: notificationText ?? 'copy-toast',
      autoClose: 2000,
    })
    console.log('Async: Copying to clipboard was successful!')
    return true
  } catch (err) {
    console.error('Async: Could not copy text: ', err)
    toast(err.toString(), {
      position: 'bottom-right',
      type: 'error',
      theme: 'colored',
      icon: false,
    })
    return false
  }
}

function inlineAllStyles(htmlString) {
  const wrapper = document.createElement('div')
  wrapper.innerHTML = htmlString
  document.body.appendChild(wrapper)

  const elements = wrapper.querySelectorAll('*')
  elements.forEach((el) => {
    const originalClasses = Array.from(el.classList)

    // Identify and remove 'hover:' classes
    const classesToRemove = originalClasses.filter((className) => className.startsWith('hover:'))
    el.classList.remove(...classesToRemove)

    const computedStyle = getComputedStyle(el)
    const styleString = Array.from(computedStyle)
      .filter((prop) => !prop.startsWith('background'))
      .map((prop) => {
        return `${prop}: ${computedStyle.getPropertyValue(prop)};`
      })
      .join(' ')
    el.style.cssText = styleString

    el.classList.add(...classesToRemove)
  })

  const inlinedHtml = wrapper.innerHTML
  document.body.removeChild(wrapper)

  return inlinedHtml
}

export const handleCopyWithStyles = (e) => {
  e.preventDefault()

  const selection = window.getSelection()
  if (!selection || selection.rangeCount === 0) {
    return
  }

  const range = selection.getRangeAt(0)
  const clonedSelection = range.cloneContents()

  const container = document.createElement('div')
  container.appendChild(clonedSelection)

  applyComputedStyles(range, container)

  const selectedHtml = container.innerHTML
  const selectedText = selection.toString()

  e.clipboardData.setData('text/html', selectedHtml)
  e.clipboardData.setData('text/plain', selectedText)
}

const applyComputedStyles = (range, container) => {
  const originalNodes = getNodesInRange(range)
  const clonedNodes = getAllNodes(container)

  for (let i = 0; i < originalNodes.length; i++) {
    const originalNode = originalNodes[i]
    const clonedNode = clonedNodes[i]

    if (originalNode.nodeType === Node.ELEMENT_NODE) {
      applyStyles(originalNode, clonedNode)
    } else if (originalNode.nodeType === Node.TEXT_NODE) {
      const originalParent = originalNode.parentNode
      const clonedParent = clonedNode.parentNode
      applyStyles(originalParent, clonedParent)
    }
  }
}

const getNodesInRange = (range) => {
  const nodes = []
  const treeWalker = document.createTreeWalker(
    range.commonAncestorContainer,
    NodeFilter.SHOW_ALL,
    {
      acceptNode: (node) => {
        return range.intersectsNode(node) ? NodeFilter.FILTER_ACCEPT : NodeFilter.FILTER_REJECT
      },
    },
    false
  )

  while (treeWalker.nextNode()) {
    nodes.push(treeWalker.currentNode)
  }

  return nodes
}

const getAllNodes = (container) => {
  const nodes = []
  const treeWalker = document.createTreeWalker(container, NodeFilter.SHOW_ALL, null, false)

  while (treeWalker.nextNode()) {
    nodes.push(treeWalker.currentNode)
  }

  return nodes
}

const applyStyles = (originalNode, clonedNode) => {
  const computedStyle = window.getComputedStyle(originalNode)

  let cssText = ''
  for (let prop of computedStyle) {
    const value = computedStyle.getPropertyValue(prop)
    cssText += `${prop}: ${value}; `
  }

  clonedNode.style.cssText = cssText
}
