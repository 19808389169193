import { T } from '@tolgee/react'
import axios from 'axios'
import { ContextModal } from 'components/document-context/ContextModal'
import { HelpTooltips } from 'components/help/helpTooltips'
import { useDocumentContext } from 'components/hooks/context'
import { useWindowDimensions } from 'components/hooks/window'
import { useYourStory } from 'components/hooks/yourStory'
import { Loader, ToolLayoutPrimary } from 'components/lib'
import { errorToast } from 'helpers'
import { compareChatbotConfigs } from 'helpers/chatbot'
import {
  PUBLISH_STATE_ACTIVE,
  PUBLISH_STATE_PENDING,
  PUBLISH_STATE_UNPUBLISHED,
} from 'helpers/enums'
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  chatBotInitialState,
  generateChatBot,
  generateChatBotDraft,
  getChatBotList,
  resetChatBotData,
  SelectChatBotInputValues,
  SelectChatBotListData,
  SelectChatBotSettings,
  SelectLoadChatsAsyncStates,
  setChatBotInputValues,
  setClearFields,
  setSelectedChatBotSettings,
  setSelectedChatBotValues,
} from 'store/account/chatBot'
import { DEFAULT_MODAL_DATA, Modal } from 'toolComponents/generic/modal'
import ChatActions from './chatActions'
import { ChatbotGeneratorHeader } from './chatbotGeneratorHeader'
import ChatbotPreviewWrapper from './chatbotPreviewWrapper'
import { ChatbotPublishing } from './chatBotPublishing'
import ChatGenerator from './chatGenerator'

// Tour steps
const steps = [
  {
    target: '#selector',
    content: (
      <T keyName='eleo-tour-chatbot-1'>
        Update your Chatbot. If you have previously created a chatbot, you can choose it here for
        editing.
      </T>
    ),
  },
  {
    target: '#name',
    content: (
      <T keyName='eleo-tour-chatbot-2'>
        Choose a name for your Chatbot. This name will also appear in the chatbot link.
      </T>
    ),
  },
  {
    target: '#story',
    content: (
      <T keyName='eleo-tour-chatbot-3'>
        Add Your Story. Your Chatbot will draw information about you, your company, or how to behave
        from the Your Story indicated here.
      </T>
    ),
  },
  {
    target: '#advanced',
    content: (
      <T keyName='eleo-tour-chatbot-4'>
        Select additional options. Set the tone, language, introduction, avatar, and Chatbot
        response length.
      </T>
    ),
  },
  {
    target: '#submit',
    content: (
      <T keyName='eleo-tour-chatbot-5'>
        Create or update the Chatbot. You can have multiple different Chatbots running.
      </T>
    ),
  },
  {
    target: '#output',
    content: (
      <T keyName='eleo-tour-chatbot-6'>
        Manage your Chatbot. Copy the chatbot link, place the Chatbot on your website or on
        Telegram.
      </T>
    ),
  },
]

const ChatBot = forwardRef(({ handleHideModal, chatbotId, onCreateCallback }, ref) => {
  const { width } = useWindowDimensions()
  const [folded, setFolded] = useState(false)
  const [uplodLoading, setUploadLoading] = useState(false)
  const [isShowAdvanced, setIsShowAdvanced] = useState(false)
  const [isShowPublishing, setIsShowPublishing] = useState(true)
  const [chatIdToDelete, setChatIdToDelete] = useState('')
  const dispatch = useDispatch()
  const chatBotInputValues = useSelector(SelectChatBotInputValues)
  const chatBotList = useSelector(SelectChatBotListData)
  const chatBotSettings = useSelector(SelectChatBotSettings)
  const [isTourOverride, setIsTourOverride] = useState(false)
  const [modalIsVisible, setModalIsVisible] = useState({
    content: 'DELETE_TEMPLATE',
    visible: true,
  })
  const [chatbotServerState, setChatbotServerState] = useState()
  const [chatbotDraftServerState, setChatbotDraftServerState] = useState()
  const [modalData, setModalData] = useState(DEFAULT_MODAL_DATA)
  const [publishState, setPublishState] = useState(
    chatbotId ? PUBLISH_STATE_PENDING : PUBLISH_STATE_UNPUBLISHED
  )

  const documentContext = useDocumentContext('chatbot-creator')
  const { formatted: stories } = useYourStory()
  const [loadingState, setLoadingState] = useState({
    publish: false,
    draft: false,
    chatbot: Boolean(chatbotId),
  })

  // Update is pending if either:
  // No data from server and the form is not in default state
  // There is data from server and its not matching the form
  // There is data from server and the publishing options dont match
  const isUpdatePending =
    (!chatbotServerState && chatBotInputValues !== chatBotInitialState.inputValues) ||
    (chatbotServerState &&
      !compareChatbotConfigs(chatbotServerState.inputValues, chatBotInputValues)[0]) ||
    (chatbotServerState &&
      Object.keys(chatBotSettings.publishing || {}).some(
        (type) => chatBotSettings.publishing[type] !== chatbotServerState.settings.publishing[type]
      ))

  const isDraftSaved = Boolean(
    chatbotDraftServerState &&
      compareChatbotConfigs(chatbotDraftServerState.inputValues, chatBotInputValues)[0] &&
      Object.keys(chatBotSettings.publishing || {}).every(
        (type) =>
          chatBotSettings.publishing[type] === chatbotDraftServerState.settings.publishing[type]
      )
  )

  // Compare current form values to config from server to update the publishing section badges
  useEffect(() => {
    if (chatbotServerState) {
      let areEqual = compareChatbotConfigs(chatbotServerState?.inputValues, chatBotInputValues, [
        'forum',
        'relatedGroups',
      ])[0]

      setPublishState((prev) =>
        Object.fromEntries(
          Object.entries(prev).map(([key, _]) =>
            chatbotServerState.settings.publishing?.[key]
              ? [key, areEqual ? 'active' : 'pending']
              : [key, 'unpublished']
          )
        )
      )

      let differentKeys = compareChatbotConfigs(
        chatbotServerState?.inputValues,
        chatBotInputValues
      )[1]
      if (differentKeys.includes('forum') || differentKeys.includes('relatedGroups')) {
        setPublishState((prev) => ({
          ...prev,
          forum: chatbotServerState.settings.publishing?.forum ? 'pending' : 'unpublished',
        }))
      }
    }
  }, [chatBotInputValues, chatbotServerState, setPublishState])

  const isFetchChatbotlistLoading = useSelector(SelectLoadChatsAsyncStates).loading

  useEffect(() => {
    dispatch(getChatBotList())
    documentContext.clearContext()

    return () => {
      dispatch(setClearFields())
    }
  }, [dispatch])

  const chatbotLoaded = useRef(false)
  useEffect(() => {
    if (!chatbotLoaded.current) {
      if (!chatbotId) {
        chatbotLoaded.current = true
        dispatch(setClearFields())
      } else if (chatBotList.length) {
        chatbotLoaded.current = true
        setTimeout(() => {
          setLoadingState((prev) => ({ ...prev, chatbot: false }))
        }, 500)
        const requestedChatbot = chatBotList.find((bot) => bot.id === chatbotId)
        if (requestedChatbot) handleSelectBot(requestedChatbot.id)
      }
    }
  }, [chatBotList, chatbotId, dispatch])

  const handleDeleteChatBot = async (chatId) => {
    setChatIdToDelete(chatId)
    setModalIsVisible({ content: 'DELETE_TEMPLATE', visible: true })
  }

  const handleGenerateChatBot = async (saveAsDraft = false) => {
    if (!chatBotInputValues.name.length) {
      if (saveAsDraft)
        errorToast(
          <T keyName='eleo-chat-bot-name-required-draft'>Enter chatbot name before saving</T>
        )
      else
        errorToast(
          <T keyName='eleo-chat-bot-name-required'>Enter chatbot name before publishing</T>
        )
      return
    }
    if (
      !saveAsDraft &&
      !Object.values(chatBotSettings.publishing || {}).some((type) => type === true)
    ) {
      errorToast(<T keyName='eleo-chat-bot-publishing-type-required'>Select publication type</T>)
      setIsShowPublishing(true)
      return
    }

    if (saveAsDraft) setLoadingState((prev) => ({ ...prev, draft: true }))
    else setLoadingState((prev) => ({ ...prev, publish: true }))
    // if (!chatBotInputValues.monthlyLimit.length) {
    //   // if (chatBotInputValues.name.length < 5) {
    //   //   errorToast(
    //   //     <T keyName='eleo-chat-bot-name-length-error'>
    //   //       Chat Bot Name Should Contain At Least 5 Characters!
    //   //     </T>
    //   //   )
    //   //   return
    //   // }

    //   errorToast(
    //     <T keyName='eleo-chat-bot-name-monthly-limit-error'>Please Point Monthly Limit!</T>
    //   )
    //   return
    // }

    if (!saveAsDraft)
      dispatch(
        generateChatBot({
          ...chatBotInputValues,
          settings: chatBotSettings,
          stories: documentContext.docContext,
        })
      ).then((res) => {
        setLoadingState((prev) => ({ ...prev, publish: false }))

        if (res.type === 'chatBot/generate/fulfilled' && res.payload) {
          onCreateCallback?.()
          setPublishState(PUBLISH_STATE_ACTIVE)

          const { settings, ...inputValues } = res.payload
          setChatbotServerState({ inputValues, settings })
          setChatbotDraftServerState()
        }
      })
    else
      dispatch(
        generateChatBotDraft({
          ...chatbotDraftServerState?.inputValues,
          ...chatBotInputValues,
          settings: chatBotSettings,
          stories: documentContext.docContext,
        })
      ).then((res) => {
        setLoadingState((prev) => ({ ...prev, draft: false }))

        if (res.type === 'chatBot/draft/generate/fulfilled' && res.payload) {
          onCreateCallback?.()

          const { settings, ...inputValues } = res.payload
          setChatbotDraftServerState({ inputValues, settings })
        }
      })
  }

  async function handleFileUpload(e, handler, dir) {
    try {
      setUploadLoading(true)

      const url = '/api/utility/upload'
      let data = new FormData()
      data.append('file', e.target.files[0])
      data.append('dir', dir ? dir : 'chatBot/')

      const config = {
        headers: { 'Content-Type': 'multipart/form-data' },
      }

      const res = await axios.post(url, data, config)
      handler(res.data.items[0])
    } catch (err) {
      console.log(err)
    } finally {
      setUploadLoading(false)
    }
  }

  function handleTourProgress(e) {
    if (e.action === 'reset' || e.action === 'close' || e.action === 'stop') {
      setIsTourOverride(false)
      return
    }
    if (!e.action === 'update') return

    const step = e.index
    if (step === 0) setIsTourOverride(true)
  }

  const handleSelectBot = (chatbotId) => {
    const selectedChatbot = chatBotList.find((bot) => bot.id === chatbotId)
    if (!selectedChatbot) return

    const values = {
      avatar: selectedChatbot.avatar,
      language: selectedChatbot.language,
      intro: selectedChatbot.intro,
      monthlyLimit: selectedChatbot.monthlyLimit,
      name: selectedChatbot.name,
      responseLength: selectedChatbot.responseLength,
      storyId: selectedChatbot.storyId,
      tone: selectedChatbot.tone,
      wordsConsumed: selectedChatbot.wordsConsumed,
      category: selectedChatbot.category,
      voice: selectedChatbot.voice,
      isPublic: selectedChatbot.isPublic,
      modelId: selectedChatbot.modelId,
      displayName: selectedChatbot.displayName,
      shortDescription: selectedChatbot.shortDescription,
      longDescription: selectedChatbot.longDescription,
      allowWebSearch: selectedChatbot.allowWebSearch,
      allowContext: selectedChatbot.allowContext,
      relatedGroups: selectedChatbot?.relatedGroups,
      visibilityOption: selectedChatbot?.visibilityOption,
      author: selectedChatbot.author,
      allowLanguageChange: selectedChatbot.allowLanguageChange,
      isStartWithAudio: selectedChatbot.isStartWithAudio,
      instructions: selectedChatbot.instructions ?? '',
      forum: selectedChatbot.forum ?? {
        displayName: '',
        shortDescription: '',
        longDescription: '',
        category: 'eleo-chatbot-category-other',
      },
    }

    dispatch(setSelectedChatBotValues(values))

    dispatch(setSelectedChatBotSettings(selectedChatbot.settings))

    documentContext.clearContext()
    selectedChatbot.stories?.map((story) =>
      documentContext.addDocument({
        label: story.name,
        type: 'story',
        id: story.id,
      })
    )

    dispatch(
      setChatBotInputValues({
        field: 'selectedChatBot',
        value:
          selectedChatbot.type === 'draft' ? selectedChatbot.originalChatBotId : selectedChatbot.id,
      })
    )

    if (selectedChatbot.type === 'draft')
      setChatbotDraftServerState({
        inputValues: { ...values, id: selectedChatbot.id },
        settings: selectedChatbot.settings,
      })
    else
      setChatbotServerState({
        inputValues: values,
        settings: selectedChatbot.settings,
      })
  }

  const handleCloseModal = useCallback(() => {
    if (isUpdatePending && !isDraftSaved) {
      setModalData({
        isVisible: true,
        title: (
          <T
            keyName='eleo-chatbot-modal-unsaved-changes-title'
            defaultValue='You have unsaved changes'
          />
        ),
        subtitle: (
          <T
            keyName='eleo-chatbot-modal-unsaved-changes-subtitle'
            defaultValue='You will lose permamently all the changes you applied.'
          />
        ),
        acceptLabel: <T keyName='eleo-discard-and-close'>Discard and close</T>,
        cancelLabel: <T keyName='eleo-back'>Back</T>,
        type: 'unsaved-changes',
        context: {},
      })
    } else handleHideModal()
  }, [handleHideModal, isDraftSaved, isUpdatePending])

  const generatorLayout = (
    <ChatGenerator
      isShowAdvanced={isTourOverride || isShowAdvanced}
      setIsShowAdvanced={setIsShowAdvanced}
      isShowPublishing={isShowPublishing}
      setIsShowPublishing={setIsShowPublishing}
      storyTemplateOptions={stories}
      handleGenerateChatBot={handleGenerateChatBot}
      handleDeleteChatBot={handleDeleteChatBot}
      handleFileUpload={handleFileUpload}
      documentContext={documentContext}
      publishState={publishState}
      setPublishState={setPublishState}
      chatbotServerState={chatbotServerState}
      chatbotDraftServerState={chatbotDraftServerState}
      isEditMode={Boolean(chatbotServerState)}
      loadingState={loadingState}
      handleResetToServerState={() =>
        chatbotServerState && handleSelectBot(chatBotInputValues.selectedChatBot)
      }
      isUpdatePending={isUpdatePending}
      isDraftSaved={isDraftSaved}
    />
  )

  const previewLayout = (
    <ChatbotPreviewWrapper
      chatBotSettings={chatBotSettings}
      chatBotInputValues={chatBotInputValues}
    />
  )

  const publishingLayout = (
    <div className='flex h-full flex-col items-center justify-between pt-2'>
      <div className='h-[calc(100%-73px)] w-full overflow-x-clip overflow-y-scroll'>
        <div className='flex justify-center'>
          <ChatbotPublishing
            isPanelLarge={true}
            isShowPublishing={true}
            publishState={publishState}
            setPublishState={setPublishState}
            chatbotServerState={chatbotServerState}
            chatbotDraftServerState={chatbotDraftServerState}
          />
        </div>
      </div>
      <div className='writer-actions bg-brand-grey-bg border-brand-gray-light sticky bottom-0 z-[300] flex w-full items-center gap-[10px] border-t p-4'>
        <ChatActions
          handleGenerateChatBot={handleGenerateChatBot}
          isEditMode={Boolean(chatbotServerState)}
          loadingState={loadingState}
          handleResetToServerState={() =>
            chatbotServerState && handleSelectBot(chatBotInputValues.selectedChatBot)
          }
          isUpdatePending={isUpdatePending}
          isDraftSaved={isDraftSaved}
        />
      </div>
    </div>
  )

  useImperativeHandle(ref, () => {
    return {
      handleCloseModal() {
        handleCloseModal()
      },
    }
  }, [handleCloseModal])

  if (loadingState.chatbot || (isFetchChatbotlistLoading && !chatBotList.length)) return <Loader />

  return (
    <>
      <div className='relative flex h-full flex-col'>
        {/* Header */}
        <ChatbotGeneratorHeader
          handleHideModal={handleCloseModal}
          chatbotId={chatBotInputValues?.selectedChatBot || chatbotId}
          handleSelectBot={handleSelectBot}
        />

        <div className='h-[calc(100%-57px)]'>
          <ToolLayoutPrimary
            useTabularLayout
            folded={folded}
            setFolded={setFolded}
            storageKey='chatbot'
            maxPanelWidth={800}
            minPanelWidth={350}
            leftSideContent={generatorLayout}
            rightSideContent={previewLayout}
            tabs={{
              form: {
                title: <T keyName='eleo-chatbot-creation-tab-form'>Settings</T>,
                component: generatorLayout,
              },
              preview: {
                title: <T keyName='eleo-chatbot-creation-tab-preview'>Preview</T>,
                component: previewLayout,
              },
              publishing: {
                title: (
                  <div className='flex items-center gap-[3px] '>
                    <div className>
                      <T keyName='eleo-chatbot-creation-tab-publishing'>Publishing</T>
                    </div>
                    {Object.keys(publishState).some(
                      (type) =>
                        publishState[type] === 'unpublished' && chatBotSettings.publishing?.[type]
                    ) && (
                      <div className='bg-brand-pink size-3 rounded-full [&:nth-child(2)]:ml-1' />
                    )}
                    {Object.keys(publishState).some(
                      (type) =>
                        publishState[type] === 'pending' && chatBotSettings.publishing?.[type]
                    ) && <div className='size-3 rounded-full bg-[#F38F2B] [&:nth-child(2)]:ml-1' />}
                  </div>
                ),
                component: publishingLayout,
              },
            }}
          />
        </div>
        {/* {modalIsVisible.visible && (
          <ActionModal
            modalKey={modalIsVisible.content}
            setModalIsVisible={setModalIsVisible}
            reloadTemplatesData={() => {
              dispatch(getChatBotList())
              if (chatIdToDelete === chatBotInputValues.selectedChatBot) {
                dispatch(setClearFields())
              }
            }}
            modalHeaderText={<T keyName='eleo-delete-bot-modal-title'>Delete Chat Bot</T>}
            deleteTemplateEndpoint={() =>
              ChatBotAPIService.deleteChatBot({ chatBotId: chatIdToDelete })
            }
            mainTitle={
              <T keyName='eleo-delete-bot-header'>Are you sure you want to delete this Chat Bot?</T>
            }
          />
        )} */}
        <Modal
          isVisible={modalData.isVisible}
          containerClasses='bg-black bg-opacity-[3%]'
          hideModal={(shouldSave) => {
            setModalData(DEFAULT_MODAL_DATA)

            if (shouldSave) handleHideModal()
          }}
          title={modalData.title}
          subtitle={modalData.subtitle}
          acceptLabel={modalData.acceptLabel}
          cancelLabel={modalData.cancelLabel}
          // content={modalContent[modalData.type]}
          isValid
          isPrimary
          width={450}
        />
        <HelpTooltips tool='chatBotPanel' />
        {/* <Tour steps={steps} name='chatbot' callback={handleTourProgress} /> */}
        <ContextModal documentContext={documentContext} docOptions={['stories']} />
      </div>
    </>
  )
})

export default ChatBot
