import { Animate } from 'components/lib'
import React from 'react'
import { ChatBotGeneralLayout } from 'toolComponents/chat-bot'

const ChatbotLibraryChatbot = ({
  chatBotName,
  visible,
  setIsVisible,
  setChatIconSizes,
  widget = false,
}) => {
  return (
    <Animate>
      <ChatBotGeneralLayout
        chatBotName={chatBotName}
        widget={widget}
        visible={visible}
        setIsVisible={setIsVisible}
        setChatIconSizes={setChatIconSizes}
        showUserHistory={true}
        showHeader={false}
        url='/api/chat-bot/library/conversation'
        isFromLibrary
      />
    </Animate>
  )
}

export default ChatbotLibraryChatbot
