import { Close, KeyboardArrowDown } from '@mui/icons-material'
import { Popover } from '@mui/material'
import { T } from '@tolgee/react'
import { useWindowDimensions } from 'components/hooks/window'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { SelectChatBotListData } from 'store/account/chatBot'
import { ListPosition, Microbutton } from 'ui'

export function ChatbotGeneratorHeader({ handleHideModal, chatbotId, handleSelectBot }) {
  const { width } = useWindowDimensions()
  const chatBotList = useSelector(SelectChatBotListData)
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const popoverId = open ? 'save-template' : undefined

  return (
    <div className='border-brand-form-accent flex items-center justify-between rounded-t-[4px] border-b py-[14px] pl-[24px] pr-[16px]'>
      <span className='dialog-title text-brand-violet font-medium'>
        {chatbotId ? (
          <T
            keyName='eleo-edit-chatbot-named'
            defaultValue='Edit {chatbotName}'
            params={{ chatbotName: chatBotList.find((bot) => bot.id === chatbotId)?.name }}
          />
        ) : (
          <T keyName='eleo-create-new-chatbot'>Create new chatbot</T>
        )}
      </span>

      <div className='flex gap-[10px]'>
        <Microbutton
          variant='transparent-light'
          iconPlacement='right'
          text={
            width >= 1024 ? (
              <T keyName='eleo-select-chatbot-for-editing'>Select chatbot for editing</T>
            ) : (
              <T keyName='eleo-edit-chatbot'>Edit chatbot</T>
            )
          }
          icon={<KeyboardArrowDown fontSize='inherit' />}
          onClick={handleClick}
        />
        <Popover
          id={popoverId}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <div className='max-h-[200px] min-w-32 p-2 pb-[10px]'>
            {chatBotList
              .filter((bot) => bot.type !== 'draft')
              .map((bot) => (
                <ListPosition
                  key={bot.id}
                  text={bot.name}
                  onClick={() => {
                    handleSelectBot(bot.id)
                    handleClose()
                  }}
                />
              ))}
          </div>
        </Popover>
        <Microbutton
          variant='plain'
          icon={<Close fontSize='inherit' />}
          className='hover:bg-brand-form-accent-light text-brand-violet'
          onClick={handleHideModal}
        />
      </div>
    </div>
  )
}
