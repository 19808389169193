import { T } from '@tolgee/react'

export const CursorMode = {
  Help: 'HELP',
}

export const PUBLISH_STATE_ACTIVE = {
  forum: 'active',
  public: 'active',
  private: 'active',
  embed: 'active',
  telegram: 'active',
}
export const PUBLISH_STATE_PENDING = {
  forum: 'pending',
  public: 'pending',
  private: 'pending',
  embed: 'pending',
  telegram: 'pending',
}
export const PUBLISH_STATE_UNPUBLISHED = {
  forum: 'unpublished',
  public: 'unpublished',
  private: 'unpublished',
  embed: 'unpublished',
  telegram: 'unpublished',
}
