import {
  Add,
  ArticleOutlined,
  Close,
  DescriptionOutlined,
  DesignServicesOutlined,
  Edit,
  Error,
  WebAssetOutlined,
} from '@mui/icons-material'
import { useTranslate } from '@tolgee/react'
import classNames from 'classnames'
import { errorToast, isValidUrl } from 'helpers'
import { useState } from 'react'
import AutosizeInput from 'react-input-autosize'
import { useNavigate } from 'react-router-dom'
import { Microbutton } from 'ui'
import { v4 as uuidv4 } from 'uuid'

const docTypes = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
]

const iconMap = {
  story: <ArticleOutlined fontSize='inherit' />,
  document: <DescriptionOutlined fontSize='inherit' />,
  template: <DesignServicesOutlined fontSize='inherit' />,
  website: <WebAssetOutlined fontSize='inherit' />,
}

export function ContextModal({
  documentContext,
  // docOptions = ['documents', 'websites', 'stories'],
  docOptions = ['documents', 'stories', 'websites'],
}) {
  const { t } = useTranslate()
  const navigate = useNavigate()
  const [newUrlState, setNewUrlState] = useState({ active: false, content: '' })

  const handleCloseModal = (e) => {
    e.stopPropagation()
    e.target === e.currentTarget && documentContext.closeModal({ saveChanges: true })
  }

  async function handleFileUpload(e) {
    e.preventDefault()

    const files = e.target.files
    if (!files.length) return

    for (const file of files) {
      if (!docTypes.includes(file.type)) {
        return errorToast(
          t('eleo-your-story-document-not-supported', 'This document type is not supported')
        )
      }

      documentContext.addDocument({
        label: file.name,
        type: 'document',
        content: file,
        id: uuidv4(),
      })
    }

    // Clear file input
    e.target.value = null
  }

  function submitNewUrl() {
    const tempUrlState = newUrlState
    setNewUrlState({ active: false, content: '' })

    if (!tempUrlState.content.length) return

    const isValid = isValidUrl(tempUrlState.content)
    documentContext.addDocument({
      label: tempUrlState.content,
      type: 'website',
      content: tempUrlState.content,
      id: uuidv4(),
      invalid: !isValid,
    })
  }

  if (!documentContext.isModalVisible) return

  return (
    <div
      className='fixed bottom-0 left-0 right-0 top-0 z-[99999] bg-white/80'
      onClick={handleCloseModal}
    >
      <div className='xs:border absolute left-1/2 top-1/2 flex h-[min(800px,95dvh)] w-[min(500px,95vw)] -translate-x-1/2 -translate-y-1/2 flex-col rounded-[4px] border-[#D9D9D9] bg-white shadow-[0_0_20px_#00000020]'>
        {/* Header */}
        <div className='bg-brand-violet space-y-1 rounded-t-[3px] pb-[50px] pl-[24px] pr-[16px] pt-[14px] text-white'>
          <div className='flex items-center justify-between'>
            <div className='text-[14px] font-medium'>
              {t('eleo-context-modal-title', 'Add context')}
            </div>
            <Microbutton
              variant='plain'
              icon={<Close fontSize='inherit' />}
              onClick={() => documentContext.closeModal({ saveChanges: true })}
              className='size-[28px] hover:bg-white/10'
            />
            {/* <button
              type='button'
              className='size-[28px] cursor-pointer text-[18px]'
              onClick={() => documentContext.closeModal({ saveChanges: true })}
            >
              <Close fontSize='inherit' />
            </button> */}
          </div>

          <div className='text-[12px] leading-[1.3em] text-white/70'>
            {t(
              'eleo-context-modal-info',
              'The number of context words will be added to each of your queries. The 5 most relevant parts of your documents and websites and all the text of Your Story will be included in the context.'
            )}
          </div>
        </div>

        {/* Content */}
        <div className='flex-1 space-y-[15px] overflow-y-auto pb-3 pt-5'>
          {/* Documents */}
          {docOptions.includes('documents') && (
            <>
              <div className='xs:px-[26px] space-y-[6px] px-4'>
                <div className='flex items-center justify-between '>
                  <div className='space-x-[6px] text-[14px]'>
                    <span className='text-brand-violet font-medium'>
                      {t('eleo-context-modal-documents-title', 'Documents')}
                    </span>
                    <span className='text-brand-violet/50'>PDF, DOC</span>
                  </div>

                  <label htmlFor='document-input'>
                    <Microbutton
                      iconPlacement='right'
                      text={t('eleo-context-modal-add-document', 'Add document')}
                      variant='solid'
                      icon={<Add fontSize='small' />}
                      className='h-[30px] w-[140px]'
                    />
                  </label>
                  <input
                    id='document-input'
                    type='file'
                    className='hidden'
                    multiple={true}
                    accept='application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                    onChange={handleFileUpload}
                  />
                </div>

                <div className='text-brand-violet text-[13px]'>
                  {documentContext.docContext
                    ?.filter((doc) => doc.type === 'document')
                    .map((doc) => (
                      <div
                        key={doc.id}
                        title={t(
                          'eleo-context-modal-label-remove-item',
                          'Click X to remove from context'
                        )}
                        className='hover:bg-brand-violet/10 group relative flex h-[40px] items-center gap-x-[10px] rounded-[4px] bg-white py-2 pl-3 pr-2 transition-all duration-300 sm:h-[32px]'
                      >
                        <span className='text-[14px]'>{iconMap.document}</span>
                        <div className='flex-1 truncate font-medium'>{doc.label}</div>

                        {/* Remove document button */}
                        <div
                          className='hover:bg-brand-violet/10 bg-brand-violet/10 flex size-[28px] cursor-pointer items-center justify-center rounded-[4px] text-[18px] transition-colors group-hover:opacity-100 sm:size-[26px] sm:bg-transparent sm:opacity-0'
                          onClick={() => documentContext.removeDocument(doc.id)}
                        >
                          <Close fontSize='inherit' />
                        </div>
                      </div>
                    ))}
                </div>
              </div>

              <div className='mt-[14px] border-b' />
            </>
          )}
          {/* Websites */}
          {docOptions.includes('websites') && (
            <>
              <div className='xs:px-[26px] space-y-[6px] px-4'>
                <div className='flex items-center justify-between'>
                  <div className='text-brand-violet space-x-[6px] text-[14px] font-medium'>
                    {t('eleo-context-modal-websites-title', 'Websites')}
                  </div>
                  <Microbutton
                    iconPlacement='right'
                    text={t('eleo-context-modal-add-webpage', 'Add webpage')}
                    variant='solid'
                    icon={<Add fontSize='small' />}
                    onClick={() => setNewUrlState({ active: true, content: '' })}
                    className='h-[30px] w-[140px]'
                    disabled={
                      documentContext.docContext?.filter((doc) => doc.type === 'website').length >=
                      5
                    }
                  />
                </div>

                <div className='text-brand-violet text-[13px]'>
                  {documentContext.docContext
                    ?.filter((doc) => doc.type === 'website')
                    .map((doc) => (
                      <div
                        key={doc.id}
                        title={t(
                          'eleo-context-modal-label-remove-item',
                          'Click X to remove from context'
                        )}
                        className='hover:bg-brand-violet/10 group relative flex h-[40px] items-center gap-x-[10px] rounded-[4px] bg-white py-2 pl-3 pr-2 transition-all duration-300 sm:h-[32px]'
                      >
                        <span className='text-[14px]'>{iconMap.website}</span>
                        {doc.edit ? (
                          <AutosizeInput
                            autoFocus
                            className='truncate'
                            inputClassName='bg-transparent'
                            value={doc.label}
                            onChange={(e) =>
                              documentContext.setDocContext((prev) =>
                                prev.map((item) =>
                                  item.id === doc.id
                                    ? {
                                        ...item,
                                        content: e.target.value,
                                        label: e.target.value,
                                        invalid: !isValidUrl(e.target.value),
                                      }
                                    : item
                                )
                              )
                            }
                            onKeyDown={(e) => {
                              if (e.key === 'Enter' || e.key === 'Escape') {
                                documentContext.toggleEditDocument(doc.id)
                                documentContext.removeEmptyDocuments()
                              }
                            }}
                            onBlur={() => {
                              documentContext.toggleEditDocument(doc.id)
                              documentContext.removeEmptyDocuments()
                            }}
                          />
                        ) : (
                          <div className='truncate font-medium'>{doc.label}</div>
                        )}

                        {doc.invalid && (
                          <div className='text-brand-pink flex items-center gap-1 text-nowrap'>
                            <Error className='text-[14px]' fontSize='inherit' />
                            {t('eleo-context-modal-invalid-url', 'Enter a valid url')}
                          </div>
                        )}

                        <div className='flex-1' />

                        <div className='-my-2 flex gap-1'>
                          {/* Remove document button */}
                          <div
                            className='hover:bg-brand-violet/10 bg-brand-violet/10 flex size-[28px] cursor-pointer items-center justify-center rounded-[4px] text-[18px] transition-colors group-hover:opacity-100 sm:size-[26px] sm:bg-transparent sm:opacity-0'
                            onClick={() => documentContext.removeDocument(doc.id)}
                          >
                            <Close fontSize='inherit' />
                          </div>

                          {/* Edit document button */}
                          <div
                            title={t(
                              'eleo-context-modal-label-edit-item',
                              'Click to edit this url'
                            )}
                            className={classNames(
                              'hover:bg-brand-violet/10 bg-brand-violet/10 flex size-[28px] cursor-pointer items-center justify-center rounded-[4px] text-[18px] transition-colors group-hover:opacity-100 sm:size-[26px] sm:bg-transparent sm:opacity-0',
                              doc.edit && 'bg-brand-violet/10 !opacity-100'
                            )}
                            onMouseDown={(e) => {
                              e.preventDefault()
                              documentContext.toggleEditDocument(doc.id)
                              documentContext.removeEmptyDocuments()
                            }}
                          >
                            <Edit fontSize='inherit' />
                          </div>
                        </div>
                      </div>
                    ))}

                  {/* url input */}
                  {newUrlState.active && (
                    <label
                      htmlFor='url-input'
                      className={classNames(
                        'hover:bg-brand-violet/10 group flex h-[40px] items-center gap-x-[10px] truncate rounded-[4px] bg-white py-2 pl-3 pr-2 transition-all duration-300 sm:h-[32px]'
                      )}
                    >
                      {iconMap.website}
                      <input
                        id='url-input'
                        autoFocus
                        type='url'
                        placeholder={t(
                          'eleo-context-modal-url-input-placeholder',
                          'Enter webpage address'
                        )}
                        value={newUrlState.content}
                        onChange={(e) => setNewUrlState({ active: true, content: e.target.value })}
                        className='caret-brand-violet w-full bg-transparent'
                        onBlur={submitNewUrl}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') submitNewUrl()
                          if (e.key === 'Escape') setNewUrlState({ active: false, content: '' })
                        }}
                      />

                      {/* Discard input button */}
                      <div
                        className='hover:bg-brand-violet/10 -my-2 flex h-[26px] w-[30px] cursor-pointer items-center justify-center rounded-[4px] text-[18px] opacity-0 transition-colors group-hover:opacity-100'
                        onMouseDown={() => setNewUrlState({ active: false, value: '' })}
                      >
                        <Close fontSize='inherit' />
                      </div>
                    </label>
                  )}
                </div>
              </div>

              <div className='mt-[14px] border-b' />
            </>
          )}
          {/* Your Story */}
          {docOptions.includes('stories') && (
            <>
              <div className='xs:px-[26px] space-y-[6px] px-4'>
                <div className='text-brand-violet flex h-[30px] items-center text-[14px] font-medium'>
                  Your Story
                </div>

                <div className='text-brand-violet text-[13px]'>
                  {/* Active stories */}
                  {documentContext.docContext
                    ?.filter((doc) => doc.type === 'story')
                    .map((doc) => (
                      <div
                        key={doc.id}
                        title={t(
                          'eleo-context-modal-label-remove-item',
                          'Click X to remove from context'
                        )}
                        className='hover:bg-brand-violet/10 group relative flex h-[40px] items-center gap-x-[10px] rounded-[4px] bg-white py-2 pl-3 pr-2 transition-all duration-300 sm:h-[32px]'
                      >
                        <span className='text-[14px]'>{iconMap.story}</span>
                        <div className='flex-1 truncate font-medium'>{doc.label}</div>

                        <div
                          className='hover:bg-brand-violet/10 bg-brand-violet/10 flex size-[28px] cursor-pointer items-center justify-center rounded-[4px] text-[18px] transition-colors group-hover:opacity-100 sm:bg-transparent sm:opacity-0'
                          onClick={() => documentContext.removeDocument(doc.id)}
                        >
                          <Close fontSize='inherit' />
                        </div>
                      </div>
                    ))}

                  {Boolean(
                    documentContext.docContext?.filter((doc) => doc.type === 'story').length
                  ) && <div className='mx-3 my-[6px] border-b' />}

                  {/* Available stories */}
                  {documentContext.stories.data
                    ?.filter(
                      (story) => !documentContext.docContext?.some((doc) => doc.id === story.id)
                    )
                    .map((story) => (
                      <div
                        key={story.id}
                        title={t('eleo-context-modal-label-add-story', 'Click to add to context')}
                        className='hover:bg-brand-violet/10 bg-brand-violet/10 group flex h-[40px] cursor-pointer items-center gap-x-[10px] rounded-[4px] bg-white py-2 pl-3 pr-2 font-medium transition-all duration-300 sm:h-[32px] sm:bg-transparent'
                        onClick={() =>
                          documentContext.addDocument({
                            label: story.label,
                            type: 'story',
                            content: story.content,
                            id: story.id,
                          })
                        }
                      >
                        <div className='text-[14px] opacity-60 group-hover:opacity-100'>
                          {iconMap.story}
                        </div>
                        <div className='flex-1 truncate font-medium opacity-60 group-hover:opacity-100'>
                          {story.label}
                        </div>
                        <div className='hover:bg-brand-violet/10 bg-brand-violet/10 -my-2 flex size-[28px] cursor-pointer items-center justify-center rounded-[4px] text-[18px] transition-colors group-hover:opacity-100 sm:size-[26px] sm:bg-transparent sm:opacity-0'>
                          <Add fontSize='inherit' />
                        </div>
                      </div>
                    ))}

                  {Boolean(
                    documentContext.stories.data?.length ===
                      documentContext.docContext.filter((item) => item.type === 'story').length
                  ) && (
                    <div className='flex h-[40px] items-center pl-[36px] text-[13px] opacity-60 sm:h-[32px]'>
                      {t('eleo-context-modal-no-stories-to-add', 'No stories to add')}
                    </div>
                  )}
                  <div className='mx-3 my-[6px] border-b' />

                  <Microbutton
                    iconPlacement='left'
                    variant='plain'
                    icon={<Add fontSize='inherit' />}
                    text={t('eleo-context-modal-create-story', 'Create new story')}
                    onClick={() => navigate('/account/your-story')}
                    className='hover:bg-brand-violet/10 h-[40px] !justify-start sm:h-[32px]'
                    iconSize={14}
                  />

                  {/* <div
                    className='hover:bg-brand-violet/10 group flex cursor-pointer items-center gap-x-[10px] rounded-[4px] bg-white py-2 pl-3 pr-2 font-medium transition-all duration-300'
                    onClick={() => navigate('/account/your-story')}
                  >
                    <Add fontSize='inherit' />
                    <div className='flex-1 truncate font-medium'>
                      {t('eleo-context-modal-create-story', 'Create new story')}
                    </div>
                  </div> */}
                </div>
              </div>
            </>
          )}
        </div>

        {/* Footer */}
        <div className='border-b ' />
        <div className='flex gap-[14px] px-[24px] py-[14px] text-[13px] capitalize'>
          <button
            type='button'
            className='bg-brand-violet/10 hover:bg-brand-violet/20 hover:text-brand-violet text-brand-violet-light h-[40px] w-full rounded-[4px] font-bold uppercase transition-colors'
            onClick={() => documentContext.closeModal({ saveChanges: false })}
          >
            {t('eleo-modal-cancel-button', 'Cancel')}
          </button>
          <button
            type='button'
            className='bg-brand-violet hover:bg-brand-violet-light h-[40px] w-full rounded-[4px] font-bold uppercase text-white transition-colors'
            onClick={() => documentContext.closeModal({ saveChanges: true })}
          >
            OK
          </button>
        </div>
      </div>
    </div>
  )
}
