import { T, useTranslate } from '@tolgee/react'
import classNames from 'classnames'
import { Loader, Message, useNavigate, ViewContext } from 'components/lib'
import { errorToast, successToast } from 'helpers'
import { useContext, useEffect, useState } from 'react'
import { ChatBotAPIService } from 'service/chat-bot-rest-api.service'
import { UserGroupsAPIService } from 'service/user-groups-api.service'
import { Button } from 'ui'
import DeleteActionAlert from '../deleteActionAlert'
import useGetUserPermission from '../hooks/useGetUserPermission'
import ActionGroup from './action-group'
import AddMembersModalView from './addMembersModalView'
import GroupListItem from './groupListItem'
import ManageAccessModalView from './manageAccessModalView'
import ChangeRoleModalView from './changeRoleModalView'

const UsersInGroupList = ({ isGroupInfoLoading, groupInfo, userGroup, handleGetGroupInfo }) => {
  const context = useContext(ViewContext)
  const { t } = useTranslate()
  const navigate = useNavigate()
  const { handleGetPermission, permission, permissionInfo } = useGetUserPermission(userGroup)
  const [chatBotOptions, setChatBotOptions] = useState([])

  const [loader, setLoader] = useState({
    resendLoader: false,
    deleteLoader: false,
    deleteGroupMemberLoader: false,
    joinGroupLoader: false,
    grantAccessLoader: false,
    changeAccessLoader: false,
  })

  const getChatBotList = async () => {
    const response = await ChatBotAPIService.getChatBotList()
    const data = await response.json()
    const chatBotMap = data.map((chatBot) => {
      return {
        value: chatBot.id,
        label: chatBot.name,
      }
    })
    setChatBotOptions(chatBotMap)
  }

  useEffect(() => {
    if (userGroup) {
      handleGetPermission()
      getChatBotList()
    }
  }, [userGroup])

  const handleDeleteGroup = async () => {
    try {
      setLoader((prev) => ({ ...prev, deleteLoader: true }))
      await UserGroupsAPIService.deleteGroup(userGroup)
      context.modal.hide()
      navigate('/account/user-groups')
      successToast(t('eleo-user-groups-delete-group', 'Group deleted successfully'))
    } catch (err) {
      errorToast(t('eleo-error-generic'))
    } finally {
      setLoader((prev) => ({ ...prev, deleteLoader: false }))
    }
  }

  const handleDeleteGroupMember = async (invitationId) => {
    try {
      setLoader((prev) => ({ ...prev, deleteGroupMemberLoader: true }))
      await UserGroupsAPIService.deleteUserFromGroup(invitationId, userGroup)
      context.modal.hide()
      handleGetGroupInfo()
      successToast(t('eleo-user-groups-delete-group-member', 'Group member deleted successfully'))
    } catch (err) {
      errorToast(t('eleo-error-generic'))
    } finally {
      setLoader((prev) => ({ ...prev, deleteGroupMemberLoader: false }))
    }
  }

  const handleAddResendInvitation = async (email) => {
    setLoader((prev) => ({ ...prev, resendLoader: true }))
    try {
      await UserGroupsAPIService.resendInvitation({ groupId: userGroup, email })
      successToast(t('eleo-user-groups-add-member-invitation-sent', 'Invitation successfully sent'))
    } catch (err) {
      errorToast(t('eleo-error-generic'))
    } finally {
      setLoader((prev) => ({ ...prev, resendLoader: false }))
    }
  }

  const handleJoinGroup = async () => {
    setLoader((prev) => ({ ...prev, joinGroupLoader: true }))
    try {
      await UserGroupsAPIService.acceptToJoin(permissionInfo?.invitationId)
      handleGetGroupInfo()
      handleGetPermission()
      successToast(t('eleo-user-groups-join-group-success', 'You successfully joined the group!'))
    } catch (err) {
      errorToast(t('eleo-error-generic'))
    } finally {
      setLoader((prev) => ({ ...prev, joinGroupLoader: false }))
    }
  }

  const handleRemoveChatbotFromGroup = async (chatBotId) => {
    try {
      await UserGroupsAPIService.removeChatBotFromGroup({ groupId: userGroup, chatBotId })
      context.modal.hide()
      handleGetGroupInfo()
      successToast(
        t('eleo-user-groups-chatbot-removed-successfully', 'Chatbot removed successfully!')
      )
    } catch (err) {
      errorToast(t('eleo-error-generic'))
    }
  }

  const handleChangeGrantStatus = async (grantStatus, invitationId, chatBotId) => {
    try {
      setLoader((prev) => ({ ...prev, changeAccessLoader: true }))
      await UserGroupsAPIService.grantAccessToUser({
        chatBotId: chatBotId,
        groupId: userGroup,
        grantStatus,
        invitationId,
      })
      successToast(
        t(
          'eleo-user-group-member-grant-status-changed',
          'Member  access status successfully updated'
        )
      )
      handleGetGroupInfo()
      context.modal.hide()
    } catch (err) {
      errorToast(t('eleo-error-generic'))
    } finally {
      setLoader((prev) => ({ ...prev, changeAccessLoader: false }))
    }
  }

  function showDeleteConfirmationModal() {
    context.modal.show({
      children: (
        <DeleteActionAlert
          title={
            <T keyName='eleo-user-groups-delete-group-alert'>
              Are you sure that you want to delete this group?
            </T>
          }
          handleDelete={() => handleDeleteGroup()}
          loading={loader.deleteLoader}
        />
      ),
    })
  }

  function showDeleteGroupMemberConfirmationModal(invitationId) {
    context.modal.show({
      children: (
        <DeleteActionAlert
          title={
            <T keyName='eleo-user-groups-delete-member-from-group-alert'>
              Are you sure that you want to delete this user from the group?
            </T>
          }
          handleDelete={() => handleDeleteGroupMember(invitationId)}
          loading={loader.deleteGroupMemberLoader}
        />
      ),
    })
  }

  function showManageChatbotAccessStatus(userChatBots, invitationId) {
    const names = groupInfo?.chatBots.map((chatbot) => chatbot.name)
    context.modal.show({
      title: t('eleo-user-groups-attached-chatbots', 'Attached Chatbots'),
      children: (
        <ManageAccessModalView
          userChatBots={userChatBots}
          chatbotInfo={names}
          changeAccessLoader={loader.changeAccessLoader}
          handleChangeGrantStatus={handleChangeGrantStatus}
          invitationId={invitationId}
        />
      ),
    })
  }

  function showChatBotList() {
    const chatbotInfo = groupInfo?.chatBots.map((chatbot) => ({
      name: chatbot.name,
      chatBotId: chatbot.chatBotId,
    }))
    context.modal.show({
      title: t('eleo-user-groups-attached-chatbots', 'Attached Chatbots'),
      children: (
        <ManageAccessModalView
          chatbotInfo={chatbotInfo}
          secondary
          chatBotOptions={chatBotOptions}
          isAdminOrOwner={permission.modifyToolAccessStatus}
          userGroup={userGroup}
          handleRemoveChatbotFromGroup={handleRemoveChatbotFromGroup}
          callBack={() => {
            context.modal.hide()
            handleGetGroupInfo()
          }}
        />
      ),
    })
  }

  function showAddMembersModal(isInviteAdmins) {
    context.modal.show({
      children: isInviteAdmins ? (
        <AddMembersModalView
          title={<T keyName='eleo-user-groups-add-admins'>Add admins</T>}
          label={<T keyName='eleo-user-groups-create-group-invite-admin-emails'>Invite Admins</T>}
          endPoint='/api/user-groups/add-admin'
          groupId={userGroup}
          callBack={() => {
            handleGetGroupInfo()
            context.modal.hide()
          }}
        />
      ) : (
        <AddMembersModalView
          title={<T keyName='eleo-user-groups-add-members'>Add members</T>}
          label={<T keyName='eleo-user-groups-create-group-invite-emails'>Invite members</T>}
          endPoint='/api/user-groups/add-member'
          groupId={userGroup}
          callBack={() => {
            handleGetGroupInfo()
            context.modal.hide()
          }}
        />
      ),
      classNames: 'px-[10px]',
    })
  }

  function showChangeUserRoleModal(invitationId, currentRole) {
    context.modal.show({
      title: <T keyName='eleo-user-groups-change-user-role'>Change user role</T>,
      children: (
        <ChangeRoleModalView
          invitationId={invitationId}
          currentRole={currentRole}
          groupId={userGroup}
          callBack={() => {
            handleGetGroupInfo()
            context.modal.hide()
            handleGetPermission()
          }}
        />
      ),
    })
  }

  return (
    <div className='flex flex-col gap-[10px]'>
      {permissionInfo?.status === 'pending' ? (
        <Message type='warning'>
          <div className='flex  justify-between'>
            <h3>
              <T keyName='eleo-user-groups-pending invitation'>
                You have pending invitation to join this group
              </T>
            </h3>
            <Button
              onClick={() => handleJoinGroup()}
              color='green'
              className={classNames('!float-none !h-[36px]', {
                'pointer-events-none opacity-70': loader.joinGroupLoader,
              })}
            >
              <T keyName='eleo-user-groups-accept-invitation'>Accept</T>
            </Button>
          </div>
        </Message>
      ) : null}

      <div className='rounded-[15px] border border-gray-100 bg-white px-4 py-[10px] sm:px-6 lg:px-8'>
        <div className='sm:flex sm:items-center'>
          <div className='flex flex-1 flex-col gap-[15px]'>
            <div className='sm:flex-auto'>
              <h1 className='text-base font-semibold leading-6 text-gray-900'>{groupInfo?.name}</h1>
              {permission.modifyToolAccessStatus ? (
                <p className='mt-2 text-sm text-gray-700'>
                  <T keyName='eleo-users-group-group-list-header'>
                    A list of all the users in your group including their name, status, email and
                    role.
                  </T>
                </p>
              ) : null}
            </div>
            <ActionGroup
              permission={permission}
              groupInfo={groupInfo}
              showAddMembersModal={showAddMembersModal}
              showDeleteConfirmationModal={showDeleteConfirmationModal}
              showChatBotList={showChatBotList}
            />
          </div>
        </div>
        {isGroupInfoLoading ? (
          <div className='relative py-[30px]'>
            <Loader />
          </div>
        ) : (
          <>
            <GroupListItem
              groupData={groupInfo.members}
              showDeleteUser={permission.removeMembers}
              showResend={permission.resendInvitation}
              showInvitationStatus={permission.showInvitationStatus}
              showLastInteraction={permission.showLastInteraction}
              handleResend={handleAddResendInvitation}
              resendLoading={loader.resendLoader}
              showAccessStatus={permission.modifyToolAccessStatus}
              handleChangeGrantStatus={handleChangeGrantStatus}
              showDeleteGroupMemberConfirmationModal={showDeleteGroupMemberConfirmationModal}
              assignedChatBotId={groupInfo?.chatBotInfo?.chatBotId}
              showUserInfo={permission.showUserInfo}
              showManageChatbotAccessStatus={showManageChatbotAccessStatus}
              showChangeUserRoleModal={showChangeUserRoleModal}
            />
          </>
        )}
      </div>
    </div>
  )
}

export default UsersInGroupList
