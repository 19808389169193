import { useTranslate } from '@tolgee/react'
import axios from 'axios'
import { ViewContext } from 'components/lib'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { ChatbotsCarousel } from '../ChatbotsCarousel'
import { Favorite, Search } from '@mui/icons-material'
import SectionSkeletonLoader from './SectionSkeletonLoader'
export const Liked = ({
  filter,
  signal,
  refetchData,
  customTitle,
  isSearchActive = false,
  clearSearch,
  searchHintsUI,
  disableSlider = false,
  returnHeader = false,
}) => {
  const context = useContext(ViewContext)
  const [items, setItems] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const { t } = useTranslate()
  const params = useSearchParams()[0]

  const fetchItems = useCallback(async () => {
    try {
      setIsLoading(true)
      const category = params.get('category')
      const res = await axios.get(
        `/api/chat-bot/library/liked?page=1&pageSize=200&filter=${filter}${category ? `&category=${category}` : ''}`
      )
      setItems(res.data)
    } catch (err) {
      context.handleError(err)
    } finally {
      setIsLoading(false)
    }
  }, [filter, params])

  useEffect(() => {
    fetchItems()
  }, [fetchItems, signal])

  if (isLoading && !items.length) return <SectionSkeletonLoader />

  const title = customTitle || t('liked-chatbots-title', 'YOUR FAVORITES')

  return (
    <div className='bg-[#f5f5fa]'>
      <ChatbotsCarousel
        items={items}
        title={title}
        clearSearch={clearSearch}
        isSearchActive={isSearchActive}
        searchHintsUI={searchHintsUI}
        icon={
          isSearchActive ? (
            <Search sx={{ height: '18px', width: '18px' }} />
          ) : (
            <Favorite sx={{ height: '18px', width: '18px' }} />
          )
        }
        refetchData={refetchData}
        disableSlider={disableSlider}
        seeMoreUrl='/chatbot/library/liked'
      />
    </div>
  )
}

export default Liked
