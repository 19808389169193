import { useTranslate } from '@tolgee/react'
import { errorToast } from 'helpers'
import { useState, useCallback } from 'react'
import { toast } from 'react-toastify'
import { UserGroupsAPIService } from 'service/user-groups-api.service'

const useGetGroupInfo = (groupId) => {
  const { t } = useTranslate()
  const [isGroupInfoLoading, setGroupInfoLoading] = useState(false)
  const [groupInfo, setGroupInfo] = useState([])

  const handleGetGroupInfo = useCallback(async () => {
    setGroupInfoLoading(true)
    try {
      const response = await UserGroupsAPIService.getGroupById(groupId)
      setGroupInfo(response.data)
    } catch (error) {
      errorToast(t('eleo-error-generic'))
    } finally {
      setGroupInfoLoading(false)
    }
  }, [groupId])

  return { isGroupInfoLoading, groupInfo, handleGetGroupInfo }
}

export default useGetGroupInfo
