import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import { T, useTranslate } from '@tolgee/react'
import classNames from 'classnames'
import { ContextField } from 'components/document-context/ContextField'
import { FieldDescription } from 'components/field-description'
import { useLanguages } from 'components/hooks/language'
import usePanelSize from 'components/hooks/panelSize'
import { transformReadyMadeTemplatesData, transformSelectOptionValue } from 'helpers'
import aiController from 'helpers/AIController'
import MoreOptions from 'toolComponents/more-options'
import { ButtonGroup, Input, ReactSelect, Toggle } from 'ui'
import WriterLengthContainer from '../WriterLengthContainer'
import {
  ActionsInputData,
  AuthorInputData,
  Creativity,
  ToneInputData,
  WritingStyleInputData,
} from '../writer-options-data'
import { modifyTextReadyMadeTemplates } from '../writer-options-data/ready-made-templates'

export default function ModifyTextOptions({
  visible,
  dispatch,
  actionTypes,
  setWordLength,
  setMyText,
  setWhatToDo,
  setKeyWords,
  wordLength,
  setWriterLengthOption,
  writerLengthOption,
  setCreativity,
  creativity,
  optionsData,
  templateOptions,
  setTemplateId,
  allowStandard,
  showUpgradePlanModal,
  templateId,
  handleDeleteTemplate,
  isShowAdvanced,
  setIsShowAdvanced,
  isOptimizedForSeoEnabled,
  setIsOptimizedForSeoEnabled,
  isGuruModeEnabled,
  setIsGuruModeEnabled,
  documentContext,
  model,
  setModel,
}) {
  const isPanelLarge = usePanelSize('modify-text')
  const { t } = useTranslate()

  const { defaultToolOutPutLanguage, languages } = useLanguages()

  const readyMadeTemplatesData = transformReadyMadeTemplatesData(modifyTextReadyMadeTemplates, t)

  const handleTopicChange = ({ value, name }) => {
    dispatch({ type: actionTypes[`SET_${name}`], payload: value })
  }

  const handleRadioButtonCheck = ({ radioBoxName, checked }) => {
    setWordLength({ id: radioBoxName, checked })
  }

  const handleSelectTemplate = (e) => {
    if (!allowStandard) return showUpgradePlanModal()

    setTemplateId(e)
  }

  return (
    visible && (
      <div
        className={classNames(
          'break-words',
          isPanelLarge
            ? 'grid-cols-1 gap-[14px] space-y-4 lg:grid lg:grid-cols-2 lg:gap-4 lg:space-y-0'
            : 'space-y-4'
        )}
      >
        <div className='col-span-2'>
          <ContextField documentContext={documentContext} />
        </div>

        {/* Template */}
        <div className='space-y-[6px]' data-tooltip-id='writer-modifyText-template'>
          <FieldDescription>
            <T keyName='eleo-write-text-template'>Template</T>
          </FieldDescription>
          <ReactSelect
            options={templateOptions}
            isClearable
            clearLabel={t('eleo-clear-button-label-none', 'None')}
            placeHolder={t('eleo-select-drop-down-placeholder', 'Select')}
            customOptions={readyMadeTemplatesData}
            // defaultValue={options[0]}
            setSelectedValue={handleSelectTemplate}
            value={templateId.value ? templateId : null}
            showDeleteIcon
            deleteAction={(templateId) => handleDeleteTemplate(templateId)}
            name='TEMPLATE'
          />
        </div>

        {/* Model */}
        <div className='space-y-[6px]'>
          <FieldDescription>
            <T keyName='eleo-chat-settings-model'>Model</T>
          </FieldDescription>

          <div className='h-[36px]'>
            <ReactSelect
              options={aiController.getSupportedTextModels()}
              setSelectedValue={({ value }) => {
                setModel({
                  ...aiController.getSupportedTextModels().find((model) => model.value === value),
                  description: undefined,
                })
              }}
              value={model}
              menuPlacement='bottom'
              menuAlignment='right'
            />
          </div>
        </div>

        {/* My text */}
        <div
          id='my-text'
          className='col-span-2 space-y-[6px]'
          data-tooltip-id='writer-modifyText-myText'
        >
          <FieldDescription>
            <T keyName='eleo-tool-modify-text-my-text'>My Text</T>
          </FieldDescription>
          <Input
            placeholder={t(
              'eleo-tool-modify-text-my-text-placeholder',
              'Provide more details about your text'
            )}
            className='placeholder-font-regular min-h-[190px] w-full rounded-[8px] text-[13px] placeholder-[#36363680]'
            onChange={setMyText}
            value={optionsData.myText}
            isTextArea
          />
        </div>

        {/* What to do */}
        <div
          id='what-to-do'
          className='col-span-2 space-y-[6px]'
          data-tooltip-id='writer-modifyText-whatToDo'
        >
          <FieldDescription>
            <T keyName='eleo-tool-modify-text-what-should-i-do'>What should I do?</T>
          </FieldDescription>
          <Input
            placeholder={t(
              'eleo-tool-modify-text-what-should-i-do-placeholder',
              'What should I do with this text?'
            )}
            className='placeholder-font-regular w-full rounded-[8px] text-[13px]  placeholder-[#36363680]'
            value={optionsData.whatToDo}
            onChange={setWhatToDo}
          />
        </div>

        {/* Show advanced button */}
        <div className='col-span-2 flex items-center justify-center text-[14px]'>
          <button
            className={classNames(
              'flex w-auto items-center justify-center rounded-[4px] text-white',
              isShowAdvanced ? 'bg-brand-secondary' : 'bg-brand-green'
            )}
            onClick={() => setIsShowAdvanced((prev) => !prev)}
          >
            <div className='ml-[5px]'>
              <MoreOptions isShowAdvanced={isShowAdvanced} />
            </div>
            {isShowAdvanced ? (
              <ChevronLeft className='rotate-90' />
            ) : (
              <ChevronRight className='rotate-90' />
            )}
          </button>
        </div>
        {/* Advanced section */}
        <div
          className={classNames(
            'writer-tour-options col-span-2 flex flex-col',
            !isShowAdvanced && 'hidden',
            isPanelLarge ? 'grid grid-cols-1 gap-[14px] lg:grid-cols-2 lg:gap-4' : 'space-y-4'
          )}
        >
          {/* SEO */}
          <div className='col-span-2 space-y-[6px]' data-tooltip-id='writer-writeText-keywords'>
            <FieldDescription>
              <T keyName='eleo-write-seo-keywords'>SEO Keywords</T>
            </FieldDescription>
            <div
              className={classNames('flex w-full items-center gap-x-2', isPanelLarge && 'flex-1')}
            >
              <Toggle
                isChecked={isOptimizedForSeoEnabled}
                setIsChecked={setIsOptimizedForSeoEnabled}
              />
              <Input
                placeholder={t('eleo-write-seo-keywords-placeholder', 'Your SEO keywords')}
                className={classNames(
                  'placeholder-font-regular w-full rounded-[8px] text-[13px]  placeholder-[#36363680]',
                  !isOptimizedForSeoEnabled && 'text-brand-secondary !bg-gray-100'
                )}
                disabled={!isOptimizedForSeoEnabled}
                value={optionsData.keyWords}
                onChange={setKeyWords}
              />
            </div>
          </div>

          {/* Style */}
          <div className='space-y-[6px]'>
            <FieldDescription>
              <T keyName='eleo-write-text-style'>Style</T>
            </FieldDescription>
            <ReactSelect
              options={WritingStyleInputData}
              isClearable
              clearLabel={t('eleo-clear-button-label-default', 'Default')}
              // defaultValue={options[0]}
              placeHolder={t('eleo-select-drop-down-placeholder', 'Select')}
              setSelectedValue={handleTopicChange}
              value={transformSelectOptionValue(
                WritingStyleInputData,
                optionsData.state.style,
                null
              )}
              name='STYLE'
              menuMinWidth={220}
            />
          </div>

          {/* Write as */}
          <div className='space-y-[6px]' data-tooltip-id='writer-modifyText-writeAs'>
            <FieldDescription>
              <T keyName='eleo-tool-modify-text-write-new-text-as'>Write new text as:</T>
            </FieldDescription>
            <ReactSelect
              options={AuthorInputData}
              isClearable
              clearLabel={t('eleo-clear-button-label-default', 'Default')}
              placeHolder={t('eleo-select-drop-down-placeholder', 'Select')}
              // defaultValue={options[0]}
              setSelectedValue={handleTopicChange}
              value={transformSelectOptionValue(AuthorInputData, optionsData.state.author, null)}
              name='AUTHOR'
              menuAlignment='right'
            />
          </div>

          {/* Length */}
          <div className='col-span-2 space-y-[6px]' data-tooltip-id='writer-modifyText-length'>
            <FieldDescription>
              <T keyName='eleo-write-text-length'>Length</T>
            </FieldDescription>
            <WriterLengthContainer
              handleRadioButtonCheck={handleRadioButtonCheck}
              selectedWordLength={wordLength}
              setWriterLengthOption={setWriterLengthOption}
              writerLengthOption={writerLengthOption}
              forceMobile={!isPanelLarge}
            />
          </div>

          {/* Actions */}
          <div id='action' className='space-y-[6px]' data-tooltip-id='writer-modifyText-actions'>
            <FieldDescription>
              <T keyName='eleo-tool-modify-text-actions'>Actions</T>
            </FieldDescription>
            <ReactSelect
              options={ActionsInputData}
              isClearable
              clearLabel={t('eleo-clear-button-label-default', 'Default')}
              // defaultValue={options[0]}
              placeHolder={t('eleo-select-drop-down-placeholder', 'Select')}
              value={transformSelectOptionValue(ActionsInputData, optionsData.state.action, null)}
              setSelectedValue={handleTopicChange}
              menuPlacement='top'
              name='ACTION'
            />
          </div>

          {/* Language */}
          <div className='space-y-[6px]' data-tooltip-id='writer-modifyText-language'>
            <FieldDescription>
              <T keyName='eleo-write-text-language'>Language</T>
            </FieldDescription>

            <ReactSelect
              options={languages}
              setSelectedValue={handleTopicChange}
              value={transformSelectOptionValue(
                languages,
                optionsData.state.language,
                defaultToolOutPutLanguage
              )}
              name='LANGUAGE'
              menuPlacement='top'
              menuAlignment='right'
            />
          </div>

          {/* Creativity */}
          <div className='space-y-[6px]' data-tooltip-id='writer-modifyText-creativity'>
            <FieldDescription>
              <T keyName='eleo-write-text-creativity'>Creativity</T>
            </FieldDescription>
            <div className='!w-full'>
              <ButtonGroup
                options={Creativity}
                onClick={(event) => setCreativity(event.target.value)}
                value={creativity}
                activeBgClass='bg-[#6363e51a]'
                className='flex-shrin !flex !h-[36px]'
              />
            </div>
          </div>

          {/* Tone */}
          <div className='space-y-[6px]' data-tooltip-id='writer-modifyText-tone'>
            <FieldDescription>
              <T keyName='eleo-write-text-tone'>Tone</T>
            </FieldDescription>
            <div className='!w-full'>
              <ReactSelect
                options={ToneInputData}
                isClearable
                clearLabel={t('eleo-clear-button-label-default', 'Default')}
                placeHolder={t('eleo-select-drop-down-placeholder', 'Select')}
                // defaultValue={options[0]}
                setSelectedValue={handleTopicChange}
                value={transformSelectOptionValue(ToneInputData, optionsData.state.tone, null)}
                name='TONE'
                menuPlacement='top'
              />
            </div>
          </div>
          <div className='mb-4' />
        </div>
      </div>
    )
  )
}
