import { useEffect, useRef, memo } from 'react'
import { Howl } from 'howler'

const AudioPlayer = ({ streamSource, setSource, callback }) => {
  var howlerRef = useRef(
    new Howl({
      src: [URL.createObjectURL(getMediaSource().mediaSource)],
      ext: ['mp3'],
      autoplay: true,
      html5: true,
      format: 'mp3',
      onunlock: () => howlerRef.current.play(),
    })
  )

  const getAudio = () => {
    const audio = document.createElement('audio')
    audio.disableRemotePlayback = true
    audio.controls = true
    audio.autoplay = true
    audio.style.display = 'none'
    audio.src = `${process.env.PUBLIC_URL}/assets/silent.mp3`

    const b = document.body
    const events = ['touchend', 'mousedown']
    events.forEach((e) => b.addEventListener(e, unlock, false))
    function unlock() {
      if (audio.currentTime > 0) return clean()
      audio.play().then(clean)
    }
    function clean() {
      events.forEach((e) => b.removeEventListener(e, unlock))
    }

    document.body.appendChild(audio)
    return audio
  }

  const audioRef = useRef(getAudio())

  useEffect(() => {
    if (!streamSource) return
    const { mediaSource, isIOS } = getMediaSource()
    let audio
    if (isIOS) {
      audio = audioRef.current
    } else {
      audio = howlerRef.current
      audio.unload()
      audio._src = [URL.createObjectURL(mediaSource)]
      audio.load()
    }

    const sourceOpenHandler = async () => {
      try {
        const sourceBuffer = mediaSource.addSourceBuffer('audio/mpeg')
        const reader = streamSource.getReader()

        const processStream = ({ done, value }) => {
          if (done) {
            if (sourceBuffer.updating) return setTimeout(() => processStream({ done, value }), 100) // Retry ending shortly
            if (mediaSource.readyState === 'ended') return
            return mediaSource.endOfStream()
          }

          if (!sourceBuffer.updating) {
            sourceBuffer.appendBuffer(value)
          } else {
            // Retry appending shortly
            return setTimeout(() => processStream({ done, value }), 100)
          }

          reader.read().then(processStream)
        }

        reader.read().then(processStream)
      } catch (error) {
        console.error('Error handling MediaSource', error)
      }
    }

    const handlePlaybackEnd = () => {
      setSource(null)
      callback?.()
    }

    mediaSource.addEventListener('sourceopen', sourceOpenHandler)

    if (isIOS) {
      audio.addEventListener('ended', handlePlaybackEnd)
      audio.src = URL.createObjectURL(mediaSource)
    } else {
      audio.once('end', handlePlaybackEnd)
      audio.play()
    }

    return () => {
      mediaSource.removeEventListener('sourceopen', sourceOpenHandler)
      if (isIOS) {
        audio.removeEventListener('ended', handlePlaybackEnd)
        audio.pause()
        URL.revokeObjectURL(audio.src)
      } else {
        audio.off()
        audio.stop()
        audio._queue = []
        URL.revokeObjectURL(audio._src)
      }
    }
  }, [streamSource, setSource, callback])
}

function getMediaSource() {
  if (window.ManagedMediaSource) {
    return { mediaSource: new window.ManagedMediaSource(), isIOS: true }
  }
  if (window.MediaSource) {
    return { mediaSource: new window.MediaSource(), isIOS: false }
  }

  throw new Error('No MediaSource API available')
}

export default memo(AudioPlayer)
