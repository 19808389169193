import { T, useTranslate } from '@tolgee/react'
import classNames from 'classnames'
import { useChatbotCategories } from 'components/hooks/chatbotCategories'
import { transformSelectOptionValue } from 'helpers'
import { compareChatbotConfigs } from 'helpers/chatbot'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  SelectChatBotForumData,
  SelectChatBotInputValues,
  SelectChatBotSettings,
  setChatBotForumData,
  setChatBotInputValues,
  setChatBotSettings,
} from 'store/account/chatBot'
import { DEFAULT_MODAL_DATA, Modal } from 'toolComponents/generic/modal'
import { Input, ReactSelect } from 'ui'
import useGetGroupListsInfo from '../userGroups/hooks/useGetGroupListsInfo'
import { PublishItem } from './publishItem'

export function ChatbotPublishing({
  isPanelLarge,
  isShowPublishing,
  publishState,
  setPublishState,
  chatbotServerState,
  chatbotDraftServerState,
}) {
  const { t } = useTranslate()
  const dispatch = useDispatch()
  const chatBotSettings = useSelector(SelectChatBotSettings)
  const chatBotForumData = useSelector(SelectChatBotForumData)
  const { chatbotCategories } = useChatbotCategories()
  const { handleGetGroupList, groupListOptions } = useGetGroupListsInfo()

  const [modalData, setModalData] = useState(DEFAULT_MODAL_DATA)
  const [savedForumData, setSavedForumData] = useState(chatBotForumData)
  const chatBotInputValues = useSelector(SelectChatBotInputValues)

  useEffect(() => {
    handleGetGroupList(true)
  }, [])

  const handleDispatchFieldValue = (params) => {
    dispatch(setChatBotInputValues(params))
  }

  const handleSetSelectedGroups = useCallback(() => {
    if (groupListOptions.length && chatBotInputValues.relatedGroups) {
      return groupListOptions.filter((option) =>
        chatBotInputValues.relatedGroups.includes(option.value)
      )
    }
    return []
  }, [groupListOptions, chatBotInputValues])

  const showForumSettingsModal = () => {
    setSavedForumData(chatBotForumData)
    if (!chatBotForumData?.displayName.length) {
      dispatch(setChatBotForumData({ field: 'displayName', value: chatBotInputValues.name }))
    }

    setModalData({
      isVisible: true,
      title: (
        <T
          keyName='eleo-chatbot-modal-forum-title'
          defaultValue='Publish chatbot {chatbotName} on Chat Forum'
          params={{ chatbotName: chatBotInputValues.name }}
        />
      ),
      subtitle: (
        <T
          keyName='eleo-chatbot-modal-forum-subtitle'
          defaultValue='This data will be displayed on the Chat Forum only.'
        />
      ),
      acceptLabel: <T keyName='eleo-save'></T>,
      type: 'forum',
      context: {},
    })
  }

  const setPublishStateOnActivateForType = (type) => {
    setPublishState((prev) => ({
      ...prev,
      [type]:
        (chatbotDraftServerState?.settings.publishing?.[type] ??
        chatbotServerState?.settings.publishing?.[type])
          ? compareChatbotConfigs(
              chatbotServerState?.inputValues,
              chatBotInputValues,
              type === 'forum' ? [] : ['forum', 'relatedGroups']
            )[0]
            ? 'active'
            : 'pending'
          : 'unpublished',
    }))
  }

  const modalContent = {
    forum: (
      <div className='max-h-[60dvh] w-full overflow-y-auto p-[24px]'>
        <div className='space-y-4'>
          {/* Row 1 */}
          <div className='grid grid-cols-2 gap-5'>
            <div className='space-y-[6px]'>
              <div className='body-small text-brand-gray-dark pl-[2px]'>
                <T keyName='eleo-chat-bot-display-name-label'>Display name</T>
              </div>
              <Input
                className='h-[36px] w-full text-[13px]'
                maxLength={30}
                value={chatBotForumData?.displayName}
                placeholder={t('eleo-chat-bot-name-placeholder', 'Chatbot name')}
                onChange={(name) =>
                  dispatch(setChatBotForumData({ field: 'displayName', value: name }))
                }
              />
            </div>

            <div className='space-y-[6px]'>
              <div className='body-small text-brand-gray-dark pl-[2px]'>
                <T keyName='eleo-chat-bot-category-label'>Category</T>
              </div>
              <ReactSelect
                options={chatbotCategories}
                portalToBody
                setSelectedValue={({ value }) =>
                  dispatch(setChatBotForumData({ field: 'category', value: value }))
                }
                placeHolder={t('eleo-select-drop-down-placeholder', 'Select')}
                value={transformSelectOptionValue(chatbotCategories, chatBotForumData.category)}
                menuPlacement='auto'
                menuAlignment='right'
              />
            </div>
          </div>

          {/* Row 2 */}
          <div className='w-full space-y-[6px]'>
            <div className='body-small text-brand-gray-dark pl-[2px]'>
              <T keyName='eleo-chat-bot-short-description-label'>Short description</T>
            </div>
            <Input
              className='placeholder-font-regular w-full rounded-[8px] text-[13px] placeholder-[#36363680]'
              maxLength={40}
              placeholder={t(
                'eleo-chat-bot-short-description-placeholder',
                'Place for the short description'
              )}
              value={chatBotForumData.shortDescription}
              onChange={(shortDescription) =>
                dispatch(
                  setChatBotForumData({ field: 'shortDescription', value: shortDescription })
                )
              }
            />
          </div>

          {/* Row 3 */}
          <div className='w-full space-y-[6px]'>
            <div className='body-small text-brand-gray-dark pl-[2px]'>
              <T keyName='eleo-chat-bot-description-label'>Description</T>
            </div>
            <Input
              className='placeholder-font-regular min-h-[60px] w-full rounded-[8px] text-[13px] placeholder-[#36363680]'
              placeholder={t('eleo-chat-bot-description-placeholder', 'Place for the description')}
              maxLength={400}
              isTextArea
              value={chatBotForumData.longDescription}
              onChange={(longDescription) =>
                dispatch(setChatBotForumData({ field: 'longDescription', value: longDescription }))
              }
            />
          </div>

          <div className='w-full space-y-[6px]'>
            <div className='body-small text-brand-gray-dark pl-[2px]'>
              <T keyName='eleo-chatbot-user-groups'>Assign user groups</T>
            </div>

            <div className='w-full'>
              <ReactSelect
                options={groupListOptions}
                setSelectedValue={(selected) => {
                  const selectedIds = selected.data.map((item) => item.value)
                  handleDispatchFieldValue({ field: 'relatedGroups', value: selectedIds })
                }}
                isMulti
                controlHeight='auto'
                controlMinHeight='36px'
                placeHolder={t('eleo-select-drop-down-placeholder', 'Select')}
                value={handleSetSelectedGroups()}
                menuPlacement='top'
                portalToBody
              />
            </div>
          </div>
        </div>
      </div>
    ),
  }

  return (
    <>
      <div className='-mx-3 overflow-clip'>
        <div
          id='publishing'
          className={classNames(
            'overflow-hidden px-3 transition-all duration-500',
            isShowPublishing
              ? // ? { 'mb-5 mt-2': true, ' h-[300px]': isPanelLarge, 'h-[510px]': !isPanelLarge } // Values for when embed and telegram are reenabled
                {
                  'mb-5 mt-2': true,
                  'h-[298px] lg:h-[194px]': isPanelLarge,
                  'h-[298px]': !isPanelLarge,
                }
              : 'h-0 -translate-y-[100px]',

            isPanelLarge
              ? 'grid grid-cols-1 gap-[14px] lg:grid-cols-2'
              : 'flex flex-col items-center space-y-[14px]'
          )}
        >
          <PublishItem
            text={<T keyName='eleo-publishing-forum'>Chat forum</T>}
            state={publishState.forum}
            isActive={chatBotSettings.publishing?.forum}
            handleSettings={() => showForumSettingsModal()}
            setIsActive={(val) => {
              dispatch(
                setChatBotSettings({
                  field: 'publishing',
                  value: { ...chatBotSettings.publishing, forum: val },
                })
              )
              if (val) {
                showForumSettingsModal()
                setPublishStateOnActivateForType('forum')
              }
            }}
            url={
              chatbotServerState?.settings.publishing?.forum &&
              `${window.location.origin}/chatbot/chat/${chatbotServerState.inputValues.name}`
            }
          />
          <PublishItem
            text={<T keyName='eleo-publishing-public'>Public link</T>}
            state={publishState.public}
            isActive={chatBotSettings.publishing?.public}
            setIsActive={(val) => {
              dispatch(
                setChatBotSettings({
                  field: 'activeOnEleo',
                  value: val,
                })
              )
              dispatch(
                setChatBotSettings({
                  field: 'publishing',
                  value: { ...chatBotSettings.publishing, public: val },
                })
              )
              if (val) setPublishStateOnActivateForType('public')
            }}
            url={
              chatbotServerState?.settings.publishing?.public &&
              `${window.location.origin}/chat/${chatbotServerState.inputValues.name}`
            }
          />
          <PublishItem
            text={<T keyName='eleo-publishing-private'>Private link</T>}
            state={publishState.private}
            isActive={chatBotSettings.publishing?.private}
            setIsActive={(val) => {
              dispatch(
                setChatBotSettings({
                  field: 'publishing',
                  value: { ...chatBotSettings.publishing, private: val },
                })
              )
              if (val) setPublishStateOnActivateForType('private')
            }}
            url={
              chatbotServerState?.settings.publishing?.private &&
              `${window.location.origin}/chatbot/chat/${chatbotServerState.inputValues.name}`
            }
          />
          {/* <PublishItem
        text={<T keyName='eleo-publishing-embed'>Webpage embed</T>}
        state={publishState.embed}
        isActive={chatBotSettings.publishing?.embed}
        setIsActive={(val) => {
          dispatch(
            setChatBotSettings({
              field: 'publishing',
              value: { ...chatBotSettings.publishing, embed: val },
            })
          )
          if (val) setPublishStateOnActivateForType('embed')
        }}
      /> */}
          {/* <PublishItem
        text={<T keyName='eleo-publishing-telegram'>Telegram bot</T>}
        state={publishState.telegram}
        isActive={chatBotSettings.publishing?.telegram}
        setIsActive={(val) => {
          dispatch(
            setChatBotSettings({
              field: 'publishing',
              value: { ...chatBotSettings.publishing, telegram: val },
            })
          )
          if (val) setPublishStateOnActivateForType('telegram')
        }}
      /> */}
        </div>
      </div>
      <Modal
        isVisible={modalData.isVisible}
        containerClasses='bg-black bg-opacity-[3%]'
        hideModal={(shouldSave) => {
          if (!shouldSave) handleDispatchFieldValue({ field: 'forum', value: savedForumData })
          setModalData(DEFAULT_MODAL_DATA)
        }}
        title={modalData.title}
        subtitle={modalData.subtitle}
        acceptLabel={modalData.acceptLabel}
        content={modalContent[modalData.type]}
        isValid
        isPrimary
        width={450}
      />
    </>
  )
}
