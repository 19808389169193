import { Loader } from 'components/lib'
import { useEffect } from 'react'
import CreateGroupForm from './createGroupForm'
import GroupList from './groupList'
import useGetGroupListsInfo from './hooks/useGetGroupListsInfo'

const UserGroupsView = ({}) => {
  const { isGroupListLoading, handleGetGroupList, groupList } = useGetGroupListsInfo()

  useEffect(() => {
    handleGetGroupList()
  }, [])

  return (
    <div>
      <div className='flex flex-col gap-[40px] px-5 py-5'>
        <CreateGroupForm handleGetGroupList={handleGetGroupList} />
        {isGroupListLoading ? (
          <div className='relative pt-[20px]'>
            <Loader />
          </div>
        ) : (
          <>{groupList.length ? <GroupList groupList={groupList} /> : null}</>
        )}
      </div>
    </div>
  )
}

export default UserGroupsView
