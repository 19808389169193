import { useTranslate } from '@tolgee/react'

export const useChatbotCategories = () => {
  const { t } = useTranslate()

  const chatbotCategories = [
    {
      value: 'eleo-chatbot-category-experts',
      label: t('eleo-chatbot-category-experts', 'Experts'),
    },
    {
      value: 'eleo-chatbot-category-education',
      label: t('eleo-chatbot-category-education', 'Education'),
    },
    {
      value: 'eleo-chatbot-category-language-teachers',
      label: t('eleo-chatbot-category-language-teachers', 'Language teachers'),
    },
    {
      value: 'eleo-chatbot-category-support-and-motivation',
      label: t('eleo-chatbot-category-support-and-motivation', 'Support and Motivation'),
    },
    {
      value: 'eleo-chatbot-category-entertainment',
      label: t('eleo-chatbot-category-entertainment', 'Entertainment'),
    },
    {
      value: 'eleo-chatbot-category-play-games',
      label: t('eleo-chatbot-category-play-games', 'Play the game'),
    },
    {
      value: 'eleo-chatbot-category-write-a-book',
      label: t('eleo-chatbot-category-write-a-book', 'Write a book'),
    },
    {
      value: 'eleo-chatbot-category-historical-figures',
      label: t('eleo-chatbot-category-historical-figures', 'Historical Figures'),
    },
    {
      value: 'eleo-chatbot-category-religion-philosophy',
      label: t('eleo-chatbot-category-religion-philosophy', 'Religion and Philosophy'),
    },
    {
      value: 'eleo-chatbot-category-other',
      label: t('eleo-chatbot-category-other', 'Other'),
    },
  ]

  return { chatbotCategories }
}
