import { useTranslate } from '@tolgee/react'
import { Loader, useAPI } from 'components/lib'
import { successToast } from 'helpers'
import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

const UnsubscribeEmail = () => {
  const { token } = useParams()
  const { data } = useAPI('/api/user/unsubscribe-email-notifications', 'GET', token)
  const { t } = useTranslate()
  const navigate = useNavigate()

  useEffect(() => {
    if (token && data && data.success) {
      successToast(
        t('eleo-email-unsubscribed-alert', 'Email notifications unsubscribed successfully!')
      )
      navigate('/dashboard')
    }
  }, [token, data])

  return (
    <div className='relative flex h-full items-center justify-center'>
      <Loader />
    </div>
  )
}

export default UnsubscribeEmail
