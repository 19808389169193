import { useTranslate } from '@tolgee/react'
import React from 'react'

const SearchHintsUI = ({ searchKey, category }) => {
  const { t } = useTranslate()
  return (
    <p className='text-brand-violet-tertiary truncate  text-[14px] font-medium leading-[1.1em]'>
      {t('eleo-chatbot-library-search-results-for', 'Search results for')}
      <span className='mx-[4px]'>"{searchKey}"</span>

      {category !== 'eleo-chat-library-all-categories' ? (
        <>
          {t('eleo-chatbot-library-search-result-in-prefix', 'in')}
          <span className='mx-[4px] lowercase'>{t(`${category}`)}</span>
        </>
      ) : null}
    </p>
  )
}

export default SearchHintsUI
