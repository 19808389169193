import { toast } from 'react-toastify'
import { T, useTranslate } from '@tolgee/react'
import axios from 'axios'

const Settings = require('../settings.json')

export const CONSENT_COOKIE_EXPIRATION = 365 //days

export const handleHttpError = async (response, errorText) => {
  if (!response.ok) {
    const status = response.status
    if (status === 500) {
      toast.error(errorText)
    }
  }

  return
}

export const clamp = (min, value, max) => {
  return Math.min(Math.max(min, value), max)
}

export const generateIntroDescription = (text, maxLength) => {
  if (text.length <= maxLength) {
    return text
  } else {
    // Truncate the text and add an ellipsis
    return text.substring(0, maxLength) + ' ...'
  }
}

export const transformSelectOptionValue = (optionsArr, selectedValue, defaultVal) => {
  return optionsArr?.find((option) => option.value === selectedValue) || defaultVal
}

export const convertCreativityToTemperature = (creativity) => {
  if (creativity === 'Low') {
    return 0.2 //default value by openai
  }

  if (creativity === 'Medium') {
    return 0.6
  }

  if (creativity === 'High') {
    return 0.9
  }
}

export const errorToast = (text, id, autoClose = 5000) => {
  toast(text, {
    position: 'bottom-right',
    type: 'error',
    theme: 'colored',
    icon: false,
    toastId: id ?? text,
    autoClose: autoClose,
  })
}

export const warningToast = (text, id, autoClose = 5000) => {
  toast(text, {
    position: 'bottom-right',
    type: 'warning',
    theme: 'colored',
    icon: false,
    toastId: id ?? text,
    autoClose: autoClose,
  })
}

export const successToast = (text, id, autoClose = 5000) => {
  toast(text, {
    position: 'bottom-right',
    type: 'success',
    theme: 'colored',
    icon: false,
    toastId: id ?? text,
    autoClose: autoClose,
  })
}

export const languages = [
  {
    label: <T keyName='eleo-language-auto'>Auto</T>,
    value: 'auto',
    rawValue: 'auto',
    lookup:
      'automatic,language: auto,Język: automatyczny,Idioma: Automático,Langue: Automatique,Lingua: automatica,Dil: Otomatik,Sprache: Automatisch',
  },
  {
    label: <T keyName='eleo-language-afrikaans'>Afrikaans</T>,
    value: 'Afrikaans',
    rawValue: 'Afrikaans',
    lookup: 'Afrikaans,afrikaans,afrikáans,afrikaans,Afrikaans,afrikaans,Afrikaans,africâner',
    code: 'af',
  },
  {
    label: <T keyName='eleo-language-arabic'>Arabic</T>,
    value: 'Arabic',
    rawValue: 'Arabic',
    lookup: 'Arabic,arabski,árabe,arabe,Arabisch,arabo,Arapça,árabe',
    code: 'ar',
  },
  {
    label: <T keyName='eleo-language-bosnian'>Bosnian</T>,
    value: 'Bosnian',
    rawValue: 'Bosnian',
    lookup: 'Bosnian,bośniacki,bosnio,bosniaque,Bosnisch,bosniaco,Boşnakça,bósnio',
    code: 'bs',
  },
  {
    label: <T keyName='eleo-language-bulgarian'>Bulgarian</T>,
    value: 'Bulgarian',
    rawValue: 'Bulgarian',
    lookup: 'Bulgarian,bułgarski,búlgaro,bulgare,Bulgarisch,bulgaro,Bulgarca,búlgaro',
    code: 'bg',
  },
  {
    label: <T keyName='eleo-language-burmese'>Burmese</T>,
    value: 'Burmese',
    rawValue: 'Burmese',
    lookup: 'Burmese,birmański,birmano,birman,Burmesisch,birmano,Burma dili,birmanês',
    code: 'my',
  },
  {
    label: <T keyName='eleo-language-chinese'>Chinese</T>,
    value: 'Chinese',
    rawValue: 'Chinese',
    lookup: 'Chinese,chiński,chino,chinois,Chinesisch,cinese,Çince,chinês',
    code: 'zh',
  },
  {
    label: <T keyName='eleo-language-croatian'>Croatian</T>,
    value: 'Croatian',
    rawValue: 'Croatian',
    lookup: 'Croatian,chorwacki,croata,croate,Kroatisch,croato,Hırvatça,croata',
    code: 'hr',
  },
  {
    label: <T keyName='eleo-language-czech'>Czech</T>,
    value: 'Czech',
    rawValue: 'Czech',
    lookup: 'Czech,czeski,checo,tchèque,Tschechisch,ceco,Çekçe,tcheco',
    code: 'cs',
  },
  {
    label: <T keyName='eleo-language-danish'>Danish</T>,
    value: 'Danish',
    rawValue: 'Danish',
    lookup: 'Danish,duński,danés,danois,Dänisch,danese,Danca,dinamarquês',
    code: 'da',
  },
  {
    label: <T keyName='eleo-language-dutch'>Dutch</T>,
    value: 'Dutch',
    rawValue: 'Dutch',
    lookup: 'Dutch,niderlandzki,neerlandés,néerlandais,Niederländisch,olandese,Hollandaca,holandês',
    code: 'nl',
  },
  {
    label: <T keyName='eleo-language-english'>English</T>,
    value: 'English',
    rawValue: 'English',
    lookup: 'English,angielski,inglés,anglais,Englisch,inglese,İngilizce,inglês',
    code: 'en',
  },
  {
    label: <T keyName='eleo-language-estonian'>Estonian</T>,
    value: 'Estonian',
    rawValue: 'Estonian',
    lookup: 'Estonian,estoński,estonio,estonien,Estnisch,estone,Estonyaca,estoniano',
    code: 'et',
  },
  {
    label: <T keyName='eleo-language-farsi'>Farsi</T>,
    value: 'Farsi',
    rawValue: 'Farsi',
    lookup: 'Farsi,perski,persa,persan,Persisch,persiano,Farsça,persa',
    code: 'fo',
  },
  {
    label: <T keyName='eleo-language-finnish'>Finnish</T>,
    value: 'Finnish',
    rawValue: 'Finnish',
    lookup: 'Finnish,fiński,finlandés,finnois,Finnisch,finlandese,Fince,finlandês',
    code: 'fi',
  },
  // { // A variant of dutch
  //   label: <T keyName="eleo-language-flemish">Flemish</T>,
  //   value: 'Flemish',
  //   rawValue: 'Flemish',
  //   lookup: '',
  // },
  {
    label: <T keyName='eleo-language-french'>French</T>,
    value: 'French',
    rawValue: 'French',
    lookup: 'French,francuski,francés,français,Französisch,francese,Fransızca,francês',
    code: 'fr',
  },
  {
    label: <T keyName='eleo-language-german'>German</T>,
    value: 'German',
    rawValue: 'German',
    lookup: 'German,niemiecki,alemán,allemand,Deutsch,tedesco,Almanca,alemão',
    code: 'de',
  },
  {
    label: <T keyName='eleo-language-greek'>Greek</T>,
    value: 'Greek',
    rawValue: 'Greek',
    lookup: 'Greek,grecki,griego,grec,Griechisch,greco,Yunanca,grego',
    code: 'el',
  },
  {
    label: <T keyName='eleo-language-hebrew'>Hebrew</T>,
    value: 'Hebrew',
    rawValue: 'Hebrew',
    lookup: 'Hebrew,hebrajski,hebreo,hébreu,Hebräisch,ebraico,İbranice,hebraico',
    code: 'he',
  },
  {
    label: <T keyName='eleo-language-hindi'>Hindi</T>,
    value: 'Hindi',
    rawValue: 'Hindi',
    lookup: 'Hindi,hindi,hindi,hindi,Hindi,hindi,Hintçe,hindi',
    code: 'hi',
  },
  {
    label: <T keyName='eleo-language-hungarian'>Hungarian</T>,
    value: 'Hungarian',
    rawValue: 'Hungarian',
    lookup: 'Hungarian,węgierski,húngaro,hongrois,Ungarisch,ungherese,Macarca,húngaro',
    code: 'hu',
  },
  {
    label: <T keyName='eleo-language-icelandic'>Icelandic</T>,
    value: 'Icelandic',
    rawValue: 'Icelandic',
    lookup: 'Icelandic,islandzki,islandés,islandais,Isländisch,islandese,İzlandaca,islandês',
    code: 'is',
  },
  {
    label: <T keyName='eleo-language-indonesian'>Indonesian</T>,
    value: 'Indonesian',
    rawValue: 'Indonesian',
    lookup:
      'Indonesian,indonezyjski,indonesio,indonésien,Indonesisch,indonesiano,Endonezce,indonésio',
    code: 'id',
  },
  {
    label: <T keyName='eleo-language-italian'>Italian</T>,
    value: 'Italian',
    rawValue: 'Italian',
    lookup: 'Italian,włoski,italiano,italien,Italienisch,italiano,İtalyanca,italiano',
    code: 'it',
  },
  {
    label: <T keyName='eleo-language-japanese'>Japanese</T>,
    value: 'Japanese',
    rawValue: 'Japanese',
    lookup: 'Japanese,japoński,japonés,japonais,Japanisch,giapponese,Japonca,japonês',
    code: 'ja',
  },
  {
    label: <T keyName='eleo-language-korean'>Korean</T>,
    value: 'Korean',
    rawValue: 'Korean',
    lookup: 'Korean,koreański,coreano,coréen,Koreanisch,coreano,Korece,coreano',
    code: 'ko',
  },
  {
    label: <T keyName='eleo-language-latvian'>Latvian</T>,
    value: 'Latvian',
    rawValue: 'Latvian',
    lookup: 'Latvian,łotewski,letón,letton,Lettisch,lettone,Letonca,letão',
    code: 'lv',
  },
  {
    label: <T keyName='eleo-language-lithuanian'>Lithuanian</T>,
    value: 'Lithuanian',
    rawValue: 'Lithuanian',
    lookup: 'Lithuanian,litewski,lituano,lituanien,Litauisch,lituano,Litvanca,lituano',
    code: 'lt',
  },
  {
    label: <T keyName='eleo-language-malay'>Malay</T>,
    value: 'Malay',
    rawValue: 'Malay',
    lookup: 'Malay,malajski,malayo,malais,Malaiisch,malese,Malayca,malaio',
    code: 'ms',
  },
  {
    label: <T keyName='eleo-language-norwegian'>Norwegian</T>,
    value: 'Norwegian',
    rawValue: 'Norwegian',
    lookup: 'Norwegian,norweski,noruego,norvégien,Norwegisch,norvegese,Norveççe,norueguês',
    code: 'no',
  },
  {
    label: <T keyName='eleo-language-polish'>Polish</T>,
    value: 'Polish',
    rawValue: 'Polish',
    lookup: 'polish,polski,polaco,polonais,polnisch,polacco,Lehçe,polonês',
    code: 'pl',
  },
  {
    label: <T keyName='eleo-language-portuguese'>Portuguese</T>,
    value: 'Portuguese',
    rawValue: 'Portuguese',
    lookup:
      'Portuguese,portugalski,portugués,portugais,Portugiesisch,portoghese,Portekizce,português',
    code: 'pt',
  },
  {
    label: <T keyName='eleo-language-punjabi'>Punjabi</T>,
    value: 'Punjabi',
    rawValue: 'Punjabi',
    lookup: 'Punjabi,pendżabski,punyabí,pendjabi,Punjabi,punjabi,Pencapça,punjabi',
    code: 'pa',
  },
  {
    label: <T keyName='eleo-language-romanian'>Romanian</T>,
    value: 'Romanian',
    rawValue: 'Romanian',
    lookup: 'Romanian,rumuński,rumano,roumain,Rumänisch,rumeno,Romence,romeno',
    code: 'ro',
  },
  {
    label: <T keyName='eleo-language-russian'>Russian</T>,
    value: 'Russian',
    rawValue: 'Russian',
    lookup: 'Russian,rosyjski,ruso,russe,Russisch,russo,Rusça,russo',
    code: 'ru',
  },
  {
    label: <T keyName='eleo-language-serbian'>Serbian</T>,
    value: 'Serbian',
    rawValue: 'Serbian',
    lookup: 'Serbian,serbski,serbio,serbe,serbisch,serbo,Sırpça,sérvio',
    code: 'sr',
  },
  {
    label: <T keyName='eleo-language-slovak'>Slovak</T>,
    value: 'Slovak',
    rawValue: 'Slovak',
    lookup: 'Slovak,słowacki,eslovaco,slovaque,slowakisch,slovacco,Slovakça,eslovaco',
    code: 'sk',
  },
  {
    label: <T keyName='eleo-language-slovenian'>Slovenian</T>,
    value: 'Slovenian',
    rawValue: 'Slovenian',
    lookup: 'Slovenian,słoweński,esloveno,slovène,slowenisch,sloveno,Slovence,esloveno',
    code: 'sl',
  },
  {
    label: <T keyName='eleo-language-spanish'>Spanish</T>,
    value: 'Spanish',
    rawValue: 'Spanish',
    lookup: 'Spanish,hiszpański,español,espagnol,Spanisch,spagnolo,İspanyolca,espanhol',
    code: 'es',
  },
  {
    label: <T keyName='eleo-language-swedish'>Swedish</T>,
    value: 'Swedish',
    rawValue: 'Swedish',
    lookup: 'Swedish,szwedzki,sueco,suédois,schwedisch,svedese,İsveççe,sueco',
    code: 'sv',
  },
  {
    label: <T keyName='eleo-language-tagalog'>Tagalog</T>,
    value: 'Tagalog',
    rawValue: 'Tagalog',
    lookup: 'Tagalog,tagalski,tagalo,tagalog,Tagalog,tagalog,Tagalogca,tagalo',
    code: 'tl',
  },
  {
    label: <T keyName='eleo-language-tamil'>Tamil</T>,
    value: 'Tamil',
    rawValue: 'Tamil',
    lookup: 'Tamil,tamilski,tamil,tamil,Tamilisch,Tamil,Tamilce,tâmil',
    code: 'ta',
  },
  {
    label: <T keyName='eleo-language-thai'>Thai</T>,
    value: 'Thai',
    rawValue: 'Thai',
    lookup: 'Thai,tajski,tailandés,thaï,Thailändisch,thailandese,Tayca,tailandês',
    code: 'th',
  },
  {
    label: <T keyName='eleo-language-tswana'>Tswana</T>,
    value: 'Tswana',
    rawValue: 'Tswana',
    lookup: 'Tswana,tswana,tswana,tswana,setsuana,tswana,Tswana,tswana',
    code: 'tn',
  },
  {
    label: <T keyName='eleo-language-turkish'>Turkish</T>,
    value: 'Turkish',
    rawValue: 'Turkish',
    lookup: 'Turkish,turecki,turco,turc,Türkisch,turco,Türkçe,turco',
    code: 'tr',
  },
  {
    label: <T keyName='eleo-language-ukrainian'>Ukrainian</T>,
    value: 'Ukrainian',
    rawValue: 'Ukrainian',
    lookup: 'Ukrainian,ukraiński,ucraniano,ukrainien,Ukrainisch,ucraino,Ukraynaca,ucraniano',
    code: 'uk',
  },
  {
    label: <T keyName='eleo-language-urdu'>Urdu</T>,
    value: 'Urdu',
    rawValue: 'Urdu',
    lookup: 'Urdu,urdu,urdu,ourdou,Urdu,urdu,Urduca,urdu',
    code: 'ur',
  },
  {
    label: <T keyName='eleo-language-vietnamese'>Vietnamese</T>,
    value: 'Vietnamese',
    rawValue: 'Vietnamese',
    lookup:
      'Vietnamese,wietnamski,vietnamita,vietnamien,Vietnamesisch,vietnamita,Vietnam Dili,vietnamita.',
    code: 'vi',
  },
  {
    label: <T keyName='eleo-language-welsh'>Welsh</T>,
    value: 'Welsh',
    rawValue: 'Welsh',
    lookup: 'Welsh,walijski,galés,gallois,Walisisch,gallese,Galce,galês',
    code: 'cy',
  },
]

export const detectRtlDirection = (selectedLanguage) => {
  return ['Farsi', 'Arabic', 'Hebrew'].includes(selectedLanguage)
}

export const appSupportedLanguages = [
  {
    label: 'English',
    value: 'en',
    iso: 'us',
    rawValue: 'English',
  },
  {
    label: 'Polski',
    value: 'pl',
    iso: 'pl',
    rawValue: 'Polish',
  },
  {
    label: 'Français',
    value: 'fr-FR',
    iso: 'fr',
    rawValue: 'French',
  },
  // {
  //   label: 'Deutsch',
  //   value: 'de',
  //   iso: 'de',
  //   rawValue: 'German',
  // },
  // {
  //   label: 'Português',
  //   value: 'pt-PT',
  //   iso: 'pt',
  //   rawValue: 'Portuguese',
  // },
  // {
  //   label: 'Türkçe',
  //   value: 'tr',
  //   iso: 'tr',
  //   rawValue: 'Turkish',
  // },
  // {
  //   label: 'Italiano',
  //   value: 'it',
  //   iso: 'it',
  //   rawValue: 'Italian',
  // },
  {
    label: 'Español',
    value: 'es',
    iso: 'es',
    rawValue: 'Spanish',
  },
]

export const periods = [
  {
    value: 'month',
    label: <T keyName='eleo-payment-frequency-Monthly'>Monthly</T>,
    priceSuffix: '/month',
  },
  {
    value: 'year',
    label: <T keyName='eleo-payment-frequency-Annually'>Yearly</T>,
    priceSuffix: '/month',
  },
]

export const transformBotName = (inputString) => {
  return inputString.replace(/[\s_]+/g, '-').toLowerCase()
}

export const transformDashToSpace = (inputString) => {
  return inputString.replace(/[-_]/g, ' ')
}

export const sortAlphabetically = (a, b) => {
  return a.value.toLowerCase().localeCompare(b.value.toLowerCase())
}

export const isValidUrl = (urlString) => {
  try {
    return Boolean(new URL(urlString))
  } catch (e) {
    return false
  }
}

export const transformReadyMadeTemplatesData = (data, translate) => {
  const readyMadeTemplatesAsOptions = data.map((item) => {
    return {
      label: translate ? (
        translate(`eleo-${item.id}-select-template-name`, item.label)
      ) : (
        <T keyName={`eleo-${item.id}-select-template-name`}>{item.label}</T>
      ),
      value: item.value,
      showDeleteOption: item?.showDeleteOption || false,
      isReadyMade: item?.isReadyMade || false,
    }
  })

  return readyMadeTemplatesAsOptions
}

export const queryToObj = (search) => {
  const queryParams = new URLSearchParams(search)

  const data = {}
  for (const entry of queryParams) {
    data[entry[0]] = entry[1]
  }

  return data
}

export const getLandingUrl = (path) => {
  const currentProcess = process.env.REACT_APP_MODE
    ? process.env.REACT_APP_MODE
    : process.env.NODE_ENV

  const url = Settings[currentProcess].landing_url

  if (!path) return url

  return url + path
}

export const getDocsUrl = (language) => {
  if (language !== 'pl') return 'https://docs.eleo.ai/'
  else return 'https://docs.eleo.ai/v/pl'
}

export const getParentDomain = () => {
  if (process.env.REACT_APP_MODE === 'develop' || process.env.NODE_ENV === 'production') {
    return '.eleo.ai'
  }

  if (process.env.NODE_ENV === 'development') {
    return 'localhost'
  }
}

export function getLocalStorage(key, defaultValue) {
  const stickyValue = localStorage.getItem(key)

  return stickyValue !== null && stickyValue !== 'undefined'
    ? JSON.parse(stickyValue)
    : defaultValue
}

export function setLocalStorage(key, value) {
  localStorage.setItem(key, JSON.stringify(value))
}

export const pageview = (GA_MEASUREMENT_ID, url) => {
  window.gtag('config', GA_MEASUREMENT_ID, {
    page_path: url,
  })
}

export const downloadAudioFromStream = async (text, isChatBot = false) => {
  if (!text?.length) return
  const data = {
    input: text,
  }
  const TTS_API_URL = isChatBot ? '/api/chat-bot/tts' : '/api/utility/tts'
  const res = await fetch(axios.defaults.baseURL + TTS_API_URL, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      Authorization: axios.defaults.headers.common['Authorization'],
    },
  })

  if (!res.ok) {
    const data = await res.json()
    if (data.code === 'TEXT_TOO_LONG')
      return errorToast(
        <T keyName='eleo-error-tts-tool-long'>This message is too long. Try a shorter message.</T>
      )
    return errorToast(
      <T keyName='eleo-error-tts'>Text to speech is currently unavailable. Try again later.</T>
    )
  }

  const reader = res.body.getReader()
  const chunks = []

  while (true) {
    const { done, value } = await reader.read()
    if (done) break
    chunks.push(value)
  }

  // Concatenate chunks into a single Uint8Array
  const allChunks = new Uint8Array(chunks.reduce((acc, chunk) => acc + chunk.length, 0))
  let position = 0
  for (const chunk of chunks) {
    allChunks.set(chunk, position)
    position += chunk.length
  }

  const blob = new Blob([allChunks], { type: 'audio/mpeg' })
  const url = URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = url
  a.download = 'message.mp3'
  document.body.appendChild(a)
  a.click()

  document.body.removeChild(a)
  URL.revokeObjectURL(url)
}

// replace \[ with $$ and \] with $$ to ensure compatibility with LaTeX
//!! working solution found here [https://community.openai.com/t/api-gpt-4-turbo-preview-has-output-in-latex-format-in-math/534293/10]

export const processLatexDelimiters = (text) => {
  return text
    .replace(/\\\[/g, '$$$') // Replace all occurrences of \[ with $$
    .replace(/\\\]/g, '$$$') // Replace all occurrences of \] with $$
    .replace(/\\\(/g, '$$$') // Replace all occurrences of \( with $$
    .replace(/\\\)/g, '$$$') // Replace all occurrences of \) with $$
}
