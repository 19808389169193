import { Add, Article, KeyboardArrowDown, VolumeUp } from '@mui/icons-material'
import { T, useTranslate } from '@tolgee/react'
import axios from 'axios'
import classNames from 'classnames'
import { ContextField } from 'components/document-context/ContextField'
import { useLanguages } from 'components/hooks/language'
import usePanelSize from 'components/hooks/panelSize'
import { useVoices } from 'components/hooks/voices'
import { errorToast, transformBotName, transformSelectOptionValue } from 'helpers'
import aiController from 'helpers/AIController'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SelectChatBotInputValues, setChatBotInputValues } from 'store/account/chatBot'
import AudioPlayer from 'toolComponents/generic/audio/AudioPlayer'
import ImageDropZone from 'toolComponents/image/ImageDropZone'
import { ToneInputData } from 'toolComponents/writer/writer-options-data'
import { CheckBox, Input, Microbutton, ReactSelect } from 'ui'
import { ChatbotPublishing } from './chatBotPublishing'
import { useDebounce } from 'use-debounce'
import { ViewContext } from 'components/lib'

export default function ChatOptions({
  isShowAdvanced,
  setIsShowAdvanced,
  isShowPublishing,
  setIsShowPublishing,
  storyTemplateOptions,
  handleDeleteChatBot,
  handleFileUpload,
  documentContext,
  publishState,
  setPublishState,
  chatbotServerState,
  chatbotDraftServerState,
}) {
  const { t } = useTranslate()
  const context = useContext(ViewContext)
  const dispatch = useDispatch()
  const voices = useVoices()
  const isPanelLarge = usePanelSize('chatbot')
  const chatBotInputValues = useSelector(SelectChatBotInputValues)
  const { defaultToolOutPutLanguage, languages } = useLanguages()

  const [audioStream, setAudioStream] = useState()
  const [baseImage, setBaseImage] = useState(chatBotInputValues.avatar)

  const [debouncedName] = useDebounce(chatBotInputValues.name, 500)
  const [isNameUnique, setIsNameUnique] = useState(true)

  useEffect(() => {
    const checkNameUniqueness = async () => {
      try {
        const res = await axios.post('/api/chat-bot/checkName', {
          name: debouncedName,
          id: chatBotInputValues.selectedChatBot,
        })
        setIsNameUnique(res.data.isUnique)
      } catch (err) {
        context.handleError(err)
      }
    }

    if (debouncedName.length) checkNameUniqueness()
  }, [debouncedName, chatBotInputValues.selectedChatBot])

  const handleTTS = async () => {
    const data = {
      input: chatBotInputValues.intro,
      voiceId: chatBotInputValues.voice,
    }

    const res = await fetch(axios.defaults.baseURL + '/api/utility/tts', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: axios.defaults.headers.common['Authorization'],
      },
    })

    if (!res.ok) {
      const data = await res.json()
      if (data.code === 'TEXT_TOO_LONG')
        return errorToast(
          <T keyName='eleo-error-tts-tool-long'>This message is too long. Try a shorter message.</T>
        )
      return errorToast(
        <T keyName='eleo-error-tts'>Text to speech is currently unavailable. Try again later.</T>
      )
    }

    setAudioStream(res.body)
  }

  const handleDispatchFieldValue = (params) => {
    dispatch(setChatBotInputValues(params))
  }

  const handleSetAvatar = (image) => {
    handleDispatchFieldValue({ field: 'avatar', value: image })
  }

  useEffect(() => {
    setBaseImage(chatBotInputValues.avatar)
  }, [chatBotInputValues.avatar])

  // useEffect(() => {
  //   if (!chatBotSettings.intro.length) {
  //     handleDispatchFieldValue({
  //       field: 'intro',
  //       value: introValueByLang[pendingLang],
  //     })
  //   }
  // }, [pendingLang])

  const renderMonthlyLimitAlert = useCallback(() => {
    const calculatedValue =
      Number(chatBotInputValues.monthlyLimit) - chatBotInputValues.wordsConsumed

    if (chatBotInputValues.wordsConsumed > 0 && calculatedValue > 0) {
      return (
        <p className='bg-brand-green rounded-[10px] px-[10px] py-[5px] text-[14px] leading-[1.2em] text-[#ffffff]'>
          {calculatedValue} -{' '}
          <T keyName='eleo-chat-bot-monthly-limit-exceeded-error'>
            words left until the end of the limit
          </T>
        </p>
      )
    }

    if (chatBotInputValues.wordsConsumed > 0 && calculatedValue < 0) {
      return (
        <p className='bg-brand-pink rounded-[10px] px-[10px] py-[5px] text-[14px] leading-[1.2em] text-[#ffffff]'>
          <T
            keyName='eleo-chat-bot-monthly-limit-exceeded-error-secondary'
            params={{ calculatedValue: Math.abs(calculatedValue) }}
            defaultValue='monthly word limit exceeded by - {calculatedValue} words'
          ></T>
        </p>
      )
    }
  }, [chatBotInputValues])

  return (
    <div
      className={classNames(
        'relative flex flex-col break-words p-1 pb-6'
        // isPanelLarge ? 'resizable-sidebar' : 'resizable-sidebar-sm'
      )}
    >
      {/* Row 1 */}
      <div className='items-start gap-5 space-y-[14px] pb-5 lg:flex lg:space-y-0'>
        <ImageDropZone
          baseImage={baseImage}
          setBaseImage={setBaseImage}
          handleFileUpload={(e) => handleFileUpload(e, handleSetAvatar)}
          classes='!size-[130px] !min-w-[130px] !m-0'
          ctaText={
            <T
              keyName='eleo-upload-avatar'
              defaultValue='Upload<br></br>avatar'
              params={{ br: <br></br> }}
            />
          }
        />

        <div className='space-y-[14px] lg:space-y-5'>
          <div
            className={classNames(
              'col-span-2',
              isPanelLarge ? 'grid grid-cols-1 gap-[14px] lg:grid-cols-2 lg:gap-5' : 'space-y-4'
            )}
          >
            <div className='space-y-[6px]' data-tooltip-id='chatBotPanel-yourChatBotName'>
              <div className='body-small text-brand-gray-dark flex justify-between pl-[2px]'>
                <T keyName='eleo-chat-bot-name-label'>Name</T>
                {!isNameUnique && (
                  <span className='text-brand-pink'>
                    <T keyName='eleo-must-be-unique'>Must be unique</T>
                  </span>
                )}
              </div>
              <Input
                className={classNames(
                  'h-[36px] w-full text-[13px]',
                  !isNameUnique && '!border-brand-pink'
                )}
                value={chatBotInputValues.name}
                placeholder={t('eleo-chat-bot-name-placeholder', 'Chatbot name')}
                onChange={async (name) =>
                  handleDispatchFieldValue({ field: 'name', value: transformBotName(name) })
                }
              />
            </div>

            <div className='space-y-[6px]' data-tooltip-id='chatBotPanel-language'>
              <div className='body-small text-brand-gray-dark pl-[2px]'>
                {t('eleo-write-text-language', 'language')}
              </div>
              <div className='h-[36px]'>
                <ReactSelect
                  menuPlacement='bottom'
                  options={languages}
                  setSelectedValue={({ value }) => {
                    handleDispatchFieldValue({ field: 'language', value })
                  }}
                  placeHolder={t('eleo-select-drop-down-placeholder', 'Select')}
                  value={transformSelectOptionValue(
                    languages,
                    chatBotInputValues.language,
                    defaultToolOutPutLanguage
                  )}
                />
              </div>
            </div>
          </div>
          <div className='col-span-2 space-y-[6px]'>
            <div className='body-small text-brand-gray-dark pl-[2px]'>
              <T keyName='eleo-chat-bot-intro-label'>Intro</T>
            </div>
            <Input
              className='h-[36px] w-full text-[13px]'
              value={chatBotInputValues.intro}
              placeholder={t(
                'eleo-chatbot-intro-placeholder',
                'What the chatbot will say to open the conversation'
              )}
              onChange={(intro) =>
                handleDispatchFieldValue({
                  field: 'intro',
                  value: intro,
                })
              }
            />
          </div>
        </div>
      </div>

      {/* Row 2 */}
      <div className='space-y-[5px] pb-5'>
        <div className='body-small text-brand-gray-dark pl-[2px]'>
          {t('eleo-chatbot-instructions-and-context', 'Instructions and context')}
        </div>
        <div className='border-brand-gray-light rounded-md border bg-white'>
          <div className='relative' data-tooltip-id='chatBotPanel-instructions'>
            <textarea
              spellCheck={false}
              className='placeholder:text-brand-gray-dark/70 max-h-72 min-h-[68px] w-full resize-none rounded-t-md px-[14px] py-[10px] indent-[22px] text-[13px] placeholder:opacity-70'
              placeholder={t(
                'eleo-chatbot-instructions-placeholder',
                'Add instructions for your chatbot'
              )}
              style={{ fieldSizing: 'content' }}
              value={chatBotInputValues.instructions}
              onChange={(e) =>
                handleDispatchFieldValue({
                  field: 'instructions',
                  value: e.target.value,
                })
              }
            />

            <div
              className='text-brand-form-accent absolute left-1 top-1 flex cursor-pointer p-[6px] text-[20px]'
              data-tooltip-id='image-description-tooltip'
            >
              <Article fontSize='inherit' />
            </div>
          </div>

          <div className='relative'>
            <ContextField
              documentContext={documentContext}
              noLabel
              classes='rounded-t-none border-b-0 border-x-0 border-brand-gray-light pl-[36px]'
              onlyStory
              noIcon
            />
            <div
              className='text-brand-form-accent pointer-events-none absolute left-1 top-1/2 flex -translate-y-1/2 p-[6px] text-[20px]'
              data-tooltip-id='image-description-tooltip'
            >
              <Add fontSize='inherit' />
            </div>
          </div>
        </div>
      </div>

      {/* Show advanced button */}
      <div
        onClick={() => setIsShowAdvanced((prev) => !prev)}
        className='text-brand-violet border-brand-form-accent -mx-7 flex cursor-pointer select-none items-center justify-between border-t py-[10px] pl-[22px] pr-5 text-[20px]'
      >
        <span className='body-secondary text-brand-violet font-medium'>
          <T keyName='eleo-chatbot-advanced-settings'>Advanced settings</T>
        </span>
        <KeyboardArrowDown
          fontSize='inherit'
          className={classNames('!transition-all', isShowAdvanced && 'rotate-180')}
        />
      </div>
      {/* Advanced section */}
      <div className='overflow-clip'>
        <div
          id='advanced'
          className={classNames(
            'overflow-hidden transition-all duration-500',
            isShowAdvanced
              ? {
                  'mb-5 mt-2': true,
                  'h-[353px] lg:h-[252px]': isPanelLarge,
                  'lg:h-[353px]': !isPanelLarge,
                }
              : 'h-0 -translate-y-[100px]',
            isPanelLarge ? 'grid grid-cols-1 gap-[14px] lg:grid-cols-2 lg:gap-4' : 'space-y-4'
          )}
        >
          <div className='space-y-[6px]'>
            <div className='body-small text-brand-gray-dark pl-[2px]'>
              <T keyName='eleo-chat-settings-model'>Model</T>
            </div>

            <div className='h-[36px]'>
              <ReactSelect
                options={aiController.getSupportedTextModels()}
                setSelectedValue={({ value }) => {
                  handleDispatchFieldValue({ field: 'modelId', value: value })
                }}
                value={{
                  ...(aiController.getTextModel(chatBotInputValues.modelId) ||
                    aiController.getDefaultTextModel()),
                  description: undefined,
                }}
                menuPlacement='bottom'
              />
            </div>
          </div>
          <div className='space-y-[6px]' data-tooltip-id='chatBotPanel-tone'>
            <div className='body-small text-brand-gray-dark pl-[2px]'>
              <T keyName='eleo-write-text-tone'>Tone</T>
            </div>

            <div className='h-[36px]'>
              <ReactSelect
                options={ToneInputData}
                isClearable
                placeHolder={t('eleo-select-drop-down-placeholder', 'Select')}
                setSelectedValue={({ value }) => handleDispatchFieldValue({ field: 'tone', value })}
                value={transformSelectOptionValue(ToneInputData, chatBotInputValues.tone, null)}
                menuPlacement='bottom'
                portalToBody
              />
            </div>
          </div>

          <div className='space-y-[6px] lg:col-span-2' data-tooltip-id='chatBotPanel-voice'>
            <div className='body-small text-brand-gray-dark pl-[2px]'>
              <T keyName='eleo-chatbot-voice'>Voice</T>
            </div>
            <div className='flex gap-1'>
              <ReactSelect
                options={voices.data?.voices}
                setSelectedValue={({ value }) =>
                  handleDispatchFieldValue({ field: 'voice', value })
                }
                placeHolder={t('eleo-chat-settings-model-default', 'Default')}
                value={transformSelectOptionValue(
                  voices.data?.voices,
                  chatBotInputValues.voice,
                  undefined
                )}
                menuPlacement='top'
                isClearable
                portalToBody
              />
              <Microbutton
                className='aspect-1 min-w-[36px] max-w-[36px]'
                iconPlacement='left'
                variant={'solid'}
                icon={<VolumeUp fontSize='inherit' />}
                disabled={false}
                onClick={handleTTS}
              />
            </div>
            <AudioPlayer streamSource={audioStream} setSource={setAudioStream} />
          </div>

          <div className='space-y-[6px]'>
            <label htmlFor='www-default-checkbox' className='flex items-center gap-[4px]'>
              <CheckBox
                id='www-default-checkbox'
                checkBoxValue={chatBotInputValues.isStartWithWebSearch}
                setChecked={({ checked }) =>
                  handleDispatchFieldValue({ field: 'isStartWithWebSearch', value: checked })
                }
              />
              <span className='body-secondary text-brand-gray-dark select-none'>
                <T keyName='eleo-chatbot-options-www-default'>Enable web browsing by default</T>
              </span>
            </label>
            <label htmlFor='voice-checkbox' className='flex items-center gap-[4px]'>
              <CheckBox
                id='voice-checkbox'
                checkBoxValue={chatBotInputValues.isStartWithAudio}
                setChecked={({ checked }) =>
                  handleDispatchFieldValue({ field: 'isStartWithAudio', value: checked })
                }
              />
              <span className='body-secondary text-brand-gray-dark select-none'>
                <T keyName='eleo-chatbot-options-voice-default'>
                  Enable voice conversation by default
                </T>
              </span>
            </label>
          </div>

          <div
            className={classNames('-mt-[14px] space-y-[6px] lg:mt-0', !isPanelLarge && '!mt-[6px]')}
          >
            <label htmlFor='www-allow-checkbox' className='flex items-center gap-[4px]'>
              <CheckBox
                id='www-allow-checkbox'
                checkBoxValue={chatBotInputValues.allowWebSearch}
                setChecked={({ checked }) =>
                  handleDispatchFieldValue({ field: 'allowWebSearch', value: checked })
                }
              />
              <span className='body-secondary text-brand-gray-dark select-none'>
                <T keyName='eleo-chatbot-options-www-allow'>
                  Allow the user to enable web browsing
                </T>
              </span>
            </label>
            <label htmlFor='language-checkbox' className='flex items-center gap-[4px]'>
              <CheckBox
                id='language-checkbox'
                checkBoxValue={chatBotInputValues.allowLanguageChange}
                setChecked={({ checked }) =>
                  handleDispatchFieldValue({ field: 'allowLanguageChange', value: checked })
                }
              />
              <span className='body-secondary text-brand-gray-dark select-none'>
                <T keyName='eleo-chatbot-options-language-allow'>Allow the user to set language</T>
              </span>
            </label>
            <label htmlFor='context-checkbox' className='flex items-center gap-[4px]'>
              <CheckBox
                id='context-checkbox'
                checkBoxValue={chatBotInputValues.allowContext}
                setChecked={({ checked }) =>
                  handleDispatchFieldValue({ field: 'allowContext', value: checked })
                }
              />
              <span className='body-secondary text-brand-gray-dark select-none'>
                <T keyName='eleo-chatbot-options-context-allow'>
                  Allow the user to add custom context
                </T>
              </span>
            </label>
          </div>

          {/* <div className='flex !w-full !flex-wrap items-center gap-[12px]'>

          {/* {authContext.permission?.master && authors.data && (
            <div id='author' className='flex w-full items-start gap-[12px]'>
              <FieldDescription>
                <T keyName='eleo-chat-bot-author-label'>Author name</T>
              </FieldDescription>

              <ReactSelect
                value={{ value: chatBotSettings.author, label: chatBotSettings.author }}
                options={authors.data?.map((author) => ({
                  value: author.name,
                  label: author.name,
                }))}
                setSelectedValue={(e) =>
                  handleDispatchFieldValue({ field: 'author', value: e.value })
                }
              />
            </div>
          )} */}
        </div>
      </div>

      <div className='hidden lg:block'>
        {/* Show publishing button */}
        <div
          onClick={() => setIsShowPublishing((prev) => !prev)}
          className={classNames(
            'text-brand-violet border-brand-form-accent -mx-7 flex cursor-pointer select-none items-center justify-between border-t py-[10px] pl-[22px] pr-5 text-[20px]'
          )}
        >
          <span className='body-secondary text-brand-violet font-medium'>
            <T keyName='eleo-chatbot-publishing'>Publishing</T>
          </span>
          <KeyboardArrowDown
            fontSize='inherit'
            className={classNames('!transition-all', isShowPublishing && 'rotate-180')}
          />
        </div>
        {/* Publishing section */}
        <ChatbotPublishing
          isPanelLarge={isPanelLarge}
          isShowPublishing={isShowPublishing}
          publishState={publishState}
          setPublishState={setPublishState}
          chatbotServerState={chatbotServerState}
          chatbotDraftServerState={chatbotDraftServerState}
        />
      </div>

      <div className='border-brand-form-accent -mx-7 border-b'></div>
    </div>
  )
}
