import { T, useTranslate } from '@tolgee/react'
import { Card, Form } from 'components/lib'
import { errorToast } from 'helpers'
import React, { useState } from 'react'

const CreateGroupForm = ({ handleGetGroupList }) => {
  const { t } = useTranslate()
  return (
    <div>
      <Card title={<T keyName='eleo-user-groups-create-group'>Create Group</T>} restrictWidth>
        <Form
          buttonText={<T keyName='eleo-profile-edit-profile-save-button'>Save</T>}
          url='/api/user-groups/create'
          method='POST'
          inputs={{
            groupName: {
              label: <T keyName='eleo-user-groups-create-group-name'>Group Name</T>,
              type: 'text',
              required: true,
              errorMessage: (
                <T keyName='eleo-user-groups-create-group-name-alert'>
                  Please enter your group name
                </T>
              ),
            },
            invitedEmails: {
              label: <T keyName='eleo-user-groups-create-group-invite-emails'>Invite members</T>,
              placeholder: t(
                'eleo-user-groups-create-group-invite-emails-placeholder',
                'Enter comma-separated email addresses (Max 10)'
              ),
              type: 'textarea',
              required: true,
              errorMessage: (
                <T keyName='eleo-user-groups-create-group-invite-emails-alert'>
                  Please enter at least one valid email address
                </T>
              ),
            },
          }}
          callback={(res) => {
            if (res.data.success) {
              handleGetGroupList()
            } else {
              errorToast('eleo-error-generic')
            }
          }}
        />
      </Card>
    </div>
  )
}

export default CreateGroupForm
