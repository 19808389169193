import { Close } from '@mui/icons-material'
import { useTranslate } from '@tolgee/react'
import classNames from 'classnames'
import { errorToast, languages } from 'helpers'
import MicRecorder from 'mic-recorder-to-mp3'
import { useCallback, useEffect, useRef, useState } from 'react'
import ChatMessage from 'toolComponents/chat/ChatMessage/ChatMessage'
import { useGetTranscription } from 'toolComponents/generic/audio/transcription'

import ChatBotForm from './chatBotForm'
import ChatNotActive from './chatNotActive'
import { ContextModal } from 'components/document-context/ContextModal'

const recorder = new MicRecorder({
  bitRate: 128,
})

const ChatBotUi = ({
  chatBotSettings,
  setMessage,
  handleKeyDown,
  message,
  setMessages,
  messages,
  handleChat,
  setChatId,
  setLanguage,
  language,
  userCanAccessHistory,
  setHistoryIsVisible,
  setChatIsActive,
  widget = false,
  handleTTS,
  isAutoAudio,
  setIsAutoAudio,
  setAudioStream,
  avatar,
  isGenerating,
  handleStopRequest,
  audioStream,
  setTtsIndex,
  ttsIndex,
  isSearchWeb,
  setSearchWeb,
  documentContext,
  isExternal = false,
}) => {
  const scrollHandle = useRef()
  const { t } = useTranslate()
  const [isTranscriptionLoading, setIsTranscriptionLoading] = useState(false)
  const [isRecording, setIsRecording] = useState(false)

  const getTranscription = useGetTranscription(true)

  function handleChangeRecording(sendWhenReady = false) {
    if (isRecording) {
      recorder
        .stop()
        .getMp3()
        .then(([buffer, blob]) => {
          setIsRecording(false)
          if (buffer.length < 25) return

          const lang = languages.find((l) => l.value === language)
          getTranscription({
            recordingBlob: blob,
            setIsLoading: setIsTranscriptionLoading,
            callback: (text) => {
              sendWhenReady
                ? handleChat(null, message + (message.length ? ' ' : '') + text)
                : setMessage(text)
            },
            ...(lang.value !== 'auto' && { language: lang.code }),
          })
        })
    } else
      recorder
        .start()
        .then(() => setIsRecording(true))
        .catch((err) => {
          if (err.name === 'NotAllowedError')
            errorToast(
              t('eleo-error-microphone-permission', 'You have to enable the microphone permission')
            )
          else errorToast(t('eleo-error-generic'))
        })
  }

  const handleClearChat = () => {
    setupChat(true)
    setMessage('')
    setChatId('')
    setAudioStream()
  }

  const userAvatar =
    avatar ?? `${process.env.REACT_APP_AWS_CLOUDFRONT_URL}/chatBot/chatIcon/default.jpeg`

  // Resets the chat log inserting the intro message if it exists
  const setupChat = useCallback(
    (force = false) => {
      if (!force && messages?.length) return
      if (chatBotSettings?.intro)
        setMessages([{ role: 'assistant', content: chatBotSettings?.intro }])
      else setMessages([])
    },
    [chatBotSettings?.intro, setMessages]
  )

  useEffect(() => {
    setupChat()
  }, [setupChat])

  useEffect(() => {
    scrollHandle?.current?.scrollIntoView()
  }, [messages, scrollHandle])

  return (
    <div className='h-full min-w-0 flex-1'>
      <div
        className={classNames('relative h-full overflow-y-auto  overflow-x-hidden bg-[#ffffff]', {
          'pt-[60px]': widget,
          'pt-[140px]': !widget,
        })}
      >
        {widget && (
          <div className='fixed top-0 z-[99] h-[45px] w-full bg-[#ffffff] shadow-md'>
            <Close
              style={{
                color: '#363636',
                fontSize: 35,
                cursor: 'pointer',
                position: 'fixed',
                right: '10px',
                top: '5px',
              }}
              onClick={() => setChatIsActive(false)}
            />
          </div>
        )}
        {widget && !chatBotSettings?.settings?.activeOnRemoteWebsite ? (
          <div className='py-[140px]'>
            <ChatNotActive showButton={false} />
          </div>
        ) : (
          <>
            <div className='mx-auto flex h-full  flex-1 flex-col'>
              <div
                className={classNames(
                  'mx-auto flex w-full max-w-[1100px] flex-grow flex-col space-y-4 px-2 pb-8 pt-4 sm:px-[70px]',
                  {
                    'pb-8': !widget,
                    'pb-[150px]': widget,
                  }
                )}
              >
                {messages?.map((botMessage, i) => (
                  <ChatMessage
                    message={botMessage}
                    index={i}
                    key={i}
                    customAssistantLogo={chatBotSettings.avatar}
                    language={language}
                    showRegenerate={false}
                    showShareIcon={!widget}
                    avatar={userAvatar}
                    widget
                    handleTTS={handleTTS}
                    isLoad
                    ttsIndex={ttsIndex}
                  />
                ))}
                <div ref={scrollHandle}></div>
              </div>
              <div
                className={classNames('bottom-0 z-10 bg-[#ffffff]', {
                  'fixed w-full': widget,
                  sticky: !widget,
                })}
              >
                <div>
                  <ChatBotForm
                    isGenerating={isGenerating}
                    isRecording={isRecording}
                    isTranscriptionLoading={isTranscriptionLoading}
                    handleAbort={handleStopRequest}
                    handleStopRequest={handleStopRequest}
                    handleChangeRecording={handleChangeRecording}
                    handleSubmit={handleChat}
                    setMessage={setMessage}
                    message={message}
                    language={language}
                    handleClearChat={handleClearChat}
                    userCanAccessHistory={userCanAccessHistory}
                    setHistoryIsVisible={setHistoryIsVisible}
                    isAutoAudio={isAutoAudio}
                    setIsAutoAudio={setIsAutoAudio}
                    setAudioStream={setAudioStream}
                    audioStream={audioStream}
                    setTtsIndex={setTtsIndex}
                    setLanguage={setLanguage}
                    widget={widget}
                    isSearchWebAllowed={chatBotSettings.allowWebSearch}
                    isLanguageChangeAllowed={chatBotSettings.allowLanguageChange}
                    isSearchWeb={isSearchWeb}
                    setSearchWeb={setSearchWeb}
                    isContextAllowed={chatBotSettings.allowContext}
                    documentContext={documentContext}
                    isExternal={isExternal}
                  />
                </div>
              </div>
            </div>
            {documentContext?.isModalVisible && <ContextModal documentContext={documentContext} />}
          </>
        )}
      </div>
    </div>
  )
}

export default ChatBotUi
