import classNames from 'classnames'
import React, { useState } from 'react'
import Popover from '@mui/material/Popover'
import { Favorite, MoreVert, ThumbUp, InsertLink, Delete } from '@mui/icons-material'

import { useTranslate } from '@tolgee/react'
import { CopyToClipBoard } from 'helpers/clipboard'
import { useNavigate } from 'react-router-dom'

const ActionButton = ({ onClick, isActive, children }) => (
  <div
    className={classNames(
      'group/action flex h-[28px] cursor-pointer items-center justify-center rounded-[4px] px-[5px] py-[3px]',
      {
        'bg-brand-violet-secondary hover:bg-brand-violet-tertiary': isActive,
        'hover:bg-white lg:hover:bg-[#5F5FBA]/20': !isActive,
      }
    )}
    onClick={onClick}
  >
    {children}
  </div>
)

const LibraryItemActions = ({
  item,
  handleRecommendChatbot,
  handleLikeChatbot,
  onPopoverOpen,
  onPopoverClose,
  isPopoverOpen,
  showChangeUserRoleModal,
  anchorEl,
  isDraft,
  secondary,
  setCreateChatbotModalData,
  handleDeleteChatBot,
}) => {
  const { t } = useTranslate()
  const navigate = useNavigate()

  const getIconClassName = (isActive, isPopoverOpen) =>
    classNames('cursor-pointer', {
      'text-white': isActive,
      'group-hover/action:text-brand-body text-brand-violet-secondary lg:text-white/60 lg:group-hover:text-brand-violet-secondary':
        !isActive,
      '!text-brand-violet-secondary': isPopoverOpen && !isActive,
    })

  const iconProps = {
    fontSize: 'inherit',
    sx: { height: '16px', width: '18px' },
  }

  const handleClick = (event) => {
    onPopoverOpen(event.currentTarget, item.id)
  }

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className={classNames(
        'flex items-center gap-[4px] rounded-[4px] bg-white/90 p-[4px] transition-all duration-300 lg:bg-transparent lg:group-hover:bg-white/90',
        {
          '!bg-white/90': isPopoverOpen,
        }
      )}
    >
      {!secondary && (
        <ActionButton onClick={() => handleLikeChatbot(item.id)} isActive={item.isLiked}>
          <Favorite
            {...iconProps}
            titleAccess={t('eleo-chatbot-library-like', 'Like it')}
            className={getIconClassName(item.isLiked, isPopoverOpen)}
          />
        </ActionButton>
      )}

      {!secondary && item.isRecommended && (
        <ActionButton onClick={() => handleRecommendChatbot(item.id)} isActive={item.isRecommended}>
          <ThumbUp
            {...iconProps}
            titleAccess={t('eleo-chatbot-library-recommend', 'Recommend')}
            className={getIconClassName(item.isRecommended, isPopoverOpen)}
          />
        </ActionButton>
      )}

      {secondary && (
        <ActionButton
          onClick={() => setCreateChatbotModalData({ isVisible: true, chatbotId: item.id })}
        >
          <p
            className={classNames(
              getIconClassName(item.isLiked, isPopoverOpen),
              'text-[12px] font-medium capitalize'
            )}
          >
            {t('eleo-chatbot-library-edit-chatbot', 'Edit')}
          </p>
        </ActionButton>
      )}

      <ActionButton isActive={false} onClick={handleClick}>
        <MoreVert
          {...iconProps}
          sx={{ height: '18px', width: '18px' }}
          className={classNames(
            'group-hover/action:text-brand-body text-brand-violet-secondary lg:group-hover:text-brand-violet-secondary lg:text-white/60',
            {
              '!text-brand-violet-secondary': isPopoverOpen,
            }
          )}
        />
      </ActionButton>

      <Popover
        open={isPopoverOpen}
        anchorEl={anchorEl}
        onClose={onPopoverClose}
        PaperProps={{
          className: 'w-[127px] rounded-[40px] pt-[7px] px-[8px] pb-[10px]',
          style: { boxShadow: 'rgba(0, 0, 0, 0.45) 1px 1px 5px 0px' },
        }}
        style={{ boxShadow: 'rgba(0, 0, 0, 0.45) 1px 1px 5px 0px' }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div>
          {!isDraft && (
            <div
              onClick={() => CopyToClipBoard(`${window.location.origin}/chatbot/chat/${item.name}`)}
              className=' text-brand-violet-secondary hover:bg-brand-violet-semi_transparent flex cursor-pointer items-center gap-[6px] rounded-[4px] p-[6px] text-[12px] font-medium transition-all duration-300'
            >
              <InsertLink sx={{ height: '14px', width: '14px' }} />
              {t('eleo-chatbot-library-copy-link', 'Copy link')}
            </div>
          )}

          {secondary && (
            <div
              onClick={() => {
                handleDeleteChatBot(item.id, isDraft ? 'draft' : 'published')
                onPopoverClose()
              }}
              className=' text-brand-pink hover:bg-brand-violet-semi_transparent flex cursor-pointer items-center gap-[6px] rounded-[4px] p-[6px] text-[12px] font-medium transition-all duration-300'
            >
              <Delete sx={{ height: '14px', width: '14px' }} />
              {t('eleo-templates-delete', 'Delete')}
            </div>
          )}

          {!secondary && !item.isRecommended && (
            <ActionButton
              onClick={() => {
                handleRecommendChatbot(item.id)
                onPopoverClose()
              }}
              isActive={item.isRecommended}
            >
              <div className='flex items-center'>
                <ThumbUp
                  {...iconProps}
                  sx={{ height: '14px', width: '14px' }}
                  titleAccess={t('eleo-chatbot-library-recommend', 'Recommend')}
                  className={getIconClassName(item.isRecommended, isPopoverOpen)}
                />
                <p className=' text-brand-violet-secondary  flex cursor-pointer items-center gap-[6px] rounded-[4px] p-[6px] text-[12px] font-medium transition-all duration-300'>
                  {t('eleo-chatbot-library-recommend', 'Recommend')}
                </p>
              </div>
            </ActionButton>
          )}
          {secondary && !isDraft && (
            <div
              onClick={() => {
                showChangeUserRoleModal(item.id)
                onPopoverClose()
              }}
              className='text-brand-violet-secondary hover:bg-brand-violet-semi_transparent flex cursor-pointer items-center justify-center gap-[6px] rounded-[4px] p-[6px] text-[12px] font-medium transition-all duration-300'
            >
              {t('eleo-chatbot-library-duplicate', 'Duplicate')}
            </div>
          )}
        </div>
      </Popover>
    </div>
  )
}

export default LibraryItemActions
