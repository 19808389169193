import { useTranslate } from '@tolgee/react'
import { errorToast } from 'helpers'
import { useState, useCallback } from 'react'
import { toast } from 'react-toastify'
import { UserGroupsAPIService } from 'service/user-groups-api.service'

const defaultPermissions = {
  addAdmins: false,
  removeAdmins: false,
  addMembers: false,
  removeMembers: false,
  resendInvitation: false,
  showInvitationStatus: true,
  showLastInteraction: false,
  deleteGroup: false,
  modifyToolAccessStatus: false,
  showUserInfo: false,
}

const useGetUserPermission = (groupId) => {
  const { t } = useTranslate()
  const [permissionIsLoading, setPermissionIsLoading] = useState(false)
  const [permission, setPermission] = useState(defaultPermissions)
  const [permissionInfo, setPermissionInfo] = useState({})

  const generatePermissions = (permission) => {
    const permissionsMap = {
      owner: {
        addAdmins: true,
        removeAdmins: true,
        addMembers: true,
        removeMembers: true,
        resendInvitation: true,
        showInvitationStatus: true,
        showLastInteraction: true,
        deleteGroup: true,
        modifyToolAccessStatus: true,
        showUserInfo: true,
      },
      admin: {
        accepted: {
          addAdmins: false,
          removeAdmins: false,
          addMembers: true,
          removeMembers: true,
          resendInvitation: true,
          showInvitationStatus: true,
          showLastInteraction: true,
          modifyToolAccessStatus: true,
          showUserInfo: true,
        },
        pending: { ...defaultPermissions, showUserInfo: true },
      },
      member: { ...defaultPermissions },
    }

    const { role, status } = permission

    if (role === 'admin') {
      return permissionsMap.admin[status] || defaultPermissions
    }

    return permissionsMap[role] || defaultPermissions
  }

  const handleGetPermission = useCallback(async () => {
    setPermissionIsLoading(true)
    try {
      const response = await UserGroupsAPIService.checkPermission({ groupId })
      setPermission(generatePermissions(response.data))
      setPermissionInfo(response.data)
    } catch (error) {
      errorToast(t('eleo-error-generic'))
    } finally {
      setPermissionIsLoading(false)
    }
  }, [groupId])

  return { permissionIsLoading, permission, permissionInfo, handleGetPermission }
}

export default useGetUserPermission
