import React, { useContext, useMemo } from 'react'
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'

import { T } from '@tolgee/react'
import classNames from 'classnames'
import { AuthContext } from 'app/auth'
import { Link } from 'react-router-dom'

export const getGroupInitials = (name) => {
  if (!name) return ''
  const words = name.trim().split(/\s+/)
  if (words.length === 1) {
    return name.slice(0, 2).toUpperCase()
  }
  return (words[0][0] + words[words.length - 1][0]).toUpperCase()
}

const GroupList = ({ groupList }) => {
  const authContext = useContext(AuthContext)

  const checkIfActionIsRequired = (members) => {
    if (members.length) {
      const groupMember = members.find(
        (user) => user.email === authContext?.user?.email && user.status === 'pending'
      )
      return !!groupMember
    }
  }

  return (
    <div>
      <h2 className='text-sm font-medium text-gray-500'>
        <T keyName='eleo-user-groups-my-groups'>My Groups</T>
      </h2>
      <ul className='mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4'>
        {groupList.map((group) => (
          <Link
            to={`/account/user-groups/${group._id}`}
            key={group._id}
            className='font-medium text-gray-900 hover:text-gray-600'
          >
            <li className='col-span-1 flex rounded-md shadow-sm'>
              <div
                className={classNames(
                  'flex w-20 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white'
                )}
                style={{ backgroundColor: `${group.groupInitialColor}` }}
              >
                {getGroupInitials(group.name)}
              </div>
              <div className='flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-200 bg-white'>
                <div className='min-h-[58px] flex-1 truncate px-4 py-2 text-sm'>
                  {group.name}
                  {group.requesterRole === 'admin' || group.requesterRole === 'owner' ? (
                    <p className='text-gray-500'>
                      {group.members?.length} {''}
                      <T keyName='eleo-user-groups-pending-member'>Member</T>
                    </p>
                  ) : null}

                  {/* <p className='text-gray-500'>
                    {group.members?.length}
                    <T keyName='eleo-user-groups-pending-member'>Member</T>
                  </p> */}

                  {checkIfActionIsRequired(group.members) ? (
                    <p className=' bg-brand-pink rounded-lg px-[5px] py-[2px] text-[12px] text-white'>
                      <T keyName='eleo-user-groups-pending-invitation'>Pending invitation</T>
                    </p>
                  ) : null}
                </div>

                <div className='flex-shrink-0 pr-2'>
                  <button
                    type='button'
                    className='inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                  >
                    <span className='sr-only'>Open options</span>
                    <EllipsisVerticalIcon aria-hidden='true' className='h-5 w-5' />
                  </button>
                </div>
              </div>
            </li>
          </Link>
        ))}
      </ul>
    </div>
  )
}

export default GroupList
