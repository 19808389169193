import { T } from '@tolgee/react'
import axios from 'axios'
import { useDocumentContext } from 'components/hooks/context'
import { errorToast, languages } from 'helpers'
import { useEffect, useState } from 'react'
import ChatBotUi from 'toolComponents/chat-bot/chatBotUi'
import AudioPlayer from 'toolComponents/generic/audio/AudioPlayer'

export function ChatbotPreviewWrapper({ chatBotSettings, chatBotInputValues }) {
  const [messages, setMessages] = useState([])
  const [message, setMessage] = useState('')
  const [audioStream, setAudioStream] = useState()
  const [language, setLanguage] = useState('auto')
  const [isSearchWeb, setSearchWeb] = useState(false)
  const [isAutoAudio, setIsAutoAudio] = useState(false)
  const [ttsIndex, setTtsIndex] = useState()

  const documentContext = useDocumentContext('chatbot_preview')

  useEffect(() => {
    documentContext.clearContext()
  }, [])

  useEffect(() => {
    setSearchWeb(chatBotInputValues.isStartWithWebSearch)
  }, [chatBotInputValues.isStartWithWebSearch])

  // Preview intro
  useEffect(() => {
    if (chatBotInputValues?.intro)
      setMessages([{ role: 'assistant', content: chatBotInputValues?.intro }])
    else setMessages([])
  }, [chatBotInputValues?.intro, setMessages])

  function mockSendMessage() {
    if (!message.length) return

    setMessages((prev) => [
      ...prev,
      { role: 'user', content: message },
      { role: 'assistant', content: 'This is an example response from your chatbot' },
    ])
    setMessage('')

    if (isAutoAudio) handleTTS('This is an example response from your chatbot', messages.length + 1)
  }

  const handleTTS = async (text, index) => {
    setAudioStream()
    if (index === ttsIndex) {
      setTtsIndex()
      return
    }

    setTtsIndex(index)
    const data = {
      input: text,
      language: languages.find((lang) => lang.value === language)?.code,
    }
    if (chatBotSettings.voice) data.voiceId = chatBotSettings.voice

    const res = await fetch(axios.defaults.baseURL + '/api/chat-bot/tts', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: axios.defaults.headers.common['Authorization'],
      },
    })

    if (!res.ok) {
      const data = await res.json()
      if (data.code === 'TEXT_TOO_LONG')
        return errorToast(
          <T keyName='eleo-error-tts-tool-long'>This message is too long. Try a shorter message.</T>
        )
      return errorToast(
        <T keyName='eleo-error-tts'>Text to speech is currently unavailable. Try again later.</T>
      )
    }

    setAudioStream(res.body)
  }

  return (
    <>
      <div className='-ml-[5px] h-full'>
        <ChatBotUi
          chatBotSettings={{ ...chatBotSettings, ...chatBotInputValues }}
          messages={messages}
          setMessages={setMessages}
          message={message}
          setMessage={setMessage}
          handleChat={mockSendMessage}
          audioStream={audioStream}
          setAudioStream={setAudioStream}
          documentContext={documentContext}
          language={language}
          setLanguage={setLanguage}
          isSearchWeb={isSearchWeb}
          setSearchWeb={setSearchWeb}
          isAutoAudio={isAutoAudio}
          setIsAutoAudio={setIsAutoAudio}
          ttsIndex={ttsIndex}
          setTtsIndex={setTtsIndex}
          setChatId={() => null}
          handleTTS={handleTTS}
        />
      </div>
      <AudioPlayer streamSource={audioStream} setSource={setAudioStream} callback={setTtsIndex} />
    </>
  )
}

export default ChatbotPreviewWrapper
