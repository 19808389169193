import { T, useTranslate } from '@tolgee/react'
import { ContextModal } from 'components/document-context/ContextModal'
import { addContextToFormData } from 'components/document-context/helper'
import { HelpTooltips } from 'components/help/helpTooltips'
import { useAccess } from 'components/hooks/access'
import { useDocumentContext } from 'components/hooks/context'
import { useLanguages } from 'components/hooks/language'
import { useStream } from 'components/hooks/stream'
import { AuthContext, ToolLayoutPrimary, ViewContext } from 'components/lib'
import Tour from 'components/tour'
import { convertCreativityToTemperature, errorToast, languages, queryToObj } from 'helpers'
import eleoLogo from 'images/eleo_raw.svg'
import { useContext, useEffect, useReducer, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { WriterAPIService } from 'service/writer-rest-api.service'
import {
  modifyTextGeneratedResultHistory,
  modifyTextInitialState,
  modifyTextInputHistory,
  setModifyTextGeneratedResultHistory,
  setModifyTextInputHistory,
} from 'store/toolInputHistoriesHolder/modifyText'
import { History } from 'ui'
import ActionModal from '../ActionModal'
import UpgradePlan from '../UpgradePlan'
import WriterResult from '../WriterResult'
import { useGetTemplateList } from '../hooks/useGetTemplateList'
import {
  ActionsInputData,
  AuthorInputData,
  Creativity,
  ToneInputData,
  WritingStyleInputData,
} from '../writer-options-data'
import { modifyTextReadyMadeTemplates } from '../writer-options-data/ready-made-templates'
import ModifyTextGenerator from './ModifyTextGenerator'

const actionTypes = {
  SET_LANGUAGE: 'SET_LANGUAGE',
  SET_ACTION: 'SET_ACTION',
  SET_STYLE: 'SET_STYLE',
  SET_TONE: 'SET_TONE',
  SET_AUTHOR: 'SET_AUTHOR',
  LOAD_FROM_QUERY: 'LOAD_FROM_QUERY',
  RESET: 'RESET',
}

// Tour steps
const steps = [
  {
    target: '#context-field',
    content: <T keyName='eleo-tour-context'></T>,
  },

  {
    target: '#my-text',
    content: (
      <T keyName='eleo-tour-modify-2'>
        Enter the text for modification. Paste or type the text you want to modify.
      </T>
    ),
  },
  {
    target: '#what-to-do',
    content: (
      <T keyName='eleo-tour-modify-3'>
        Describe how you want to modify the text. Explain what Eleo should do with this text.
      </T>
    ),
  },
  {
    target: '.writer-tour-options',
    content: (
      <T keyName='eleo-tour-modify-4'>
        Select additional options. Choose the style, author, tone, or length of the new document.
      </T>
    ),
  },
  {
    target: '#action',
    content: (
      <T keyName='eleo-tour-modify-5'>
        Choose additional actions. Indicate whether the new document should include a summary,
        bullet points, or an explanation.
      </T>
    ),
  },
]

function reducer(state, action) {
  switch (action.type) {
    case actionTypes.SET_LANGUAGE:
      return { ...state, language: action.payload }
    case actionTypes.SET_ACTION:
      return { ...state, action: action.payload }
    case actionTypes.SET_STYLE:
      return { ...state, style: action.payload }
    case actionTypes.SET_TONE:
      return { ...state, tone: action.payload }
    case actionTypes.SET_AUTHOR:
      return { ...state, author: action.payload }
    case actionTypes.LOAD_FROM_QUERY:
      const values = action.payload
      let data = {
        language: languages.find((l) => values.language?.toLowerCase() === l.value.toLowerCase())
          ?.value,
        action: ActionsInputData.find((a) => values.action?.toLowerCase() === a.value.toLowerCase())
          ?.value,
        style: WritingStyleInputData.find(
          (s) => values.style?.toLowerCase() === s.value.toLowerCase()
        )?.value,
        tone: ToneInputData.find((t) => values.tone?.toLowerCase() === t.value.toLowerCase())
          ?.value,
        author: AuthorInputData.find((a) => values.author?.toLowerCase() === a.value.toLowerCase())
          ?.value,
      }

      data = Object.fromEntries(Object.entries(data).filter(([key, value]) => Boolean(value)))

      return { ...state, ...data }
    case actionTypes.RESET:
      return modifyTextInitialState.history.selectOptionValues
    default:
      return state
  }
}

const ModifyText = () => {
  const { state: completionState, abortController, fetch: fetchCompletion } = useStream()
  const modifyTextHistoryValues = useSelector(modifyTextInputHistory)
  const generatedTextHistoryValue = useSelector(modifyTextGeneratedResultHistory)
  const dispatchAction = useDispatch()
  const location = useLocation()
  const authContext = useContext(AuthContext)
  const documentContext = useDocumentContext('writer-modify')
  const [folded, setFolded] = useState(false)
  const [isShowAdvanced, setIsShowAdvanced] = useState(modifyTextHistoryValues.showAdvanced)
  const context = useContext(ViewContext)
  const [generatedText, setGeneratedText] = useState(generatedTextHistoryValue)
  const [myText, setMyText] = useState(modifyTextHistoryValues.myText)
  const [whatToDo, setWhatToDo] = useState(modifyTextHistoryValues.whatToDo)
  const [keyWords, setKeyWords] = useState(modifyTextHistoryValues.keyWords)
  const [wordLength, setWordLength] = useState(modifyTextHistoryValues.wordLength)
  const [templateId, setTemplateId] = useState(modifyTextHistoryValues.templateId)
  const [templateName, setTemplateName] = useState('')
  const [templateToDelete, setTemplateToDelete] = useState('')
  const [loading, setLoading] = useState({ name: '', status: false })
  const [modalIsVisible, setModalIsVisible] = useState({
    content: '',
    visible: false,
  })
  const [writerLengthOption, setWriterLengthOption] = useState(
    modifyTextHistoryValues.writerLengthOption
  )
  const [creativity, setCreativity] = useState(modifyTextHistoryValues.creativity)
  const [state, dispatch] = useReducer(reducer, modifyTextHistoryValues.selectOptionValues)
  const [history, setHistory] = useState([])
  const [isHistoryVisible, setIsHistoryVisible] = useState(false)
  const [checkedHistory, setCheckedHistory] = useState({
    radioBoxName: '',
    checked: false,
  })
  const [model, setModel] = useState(modifyTextHistoryValues.model)

  const hasAccess = useAccess()

  const [isOptimizedForSeoEnabled, setIsOptimizedForSeoEnabled] = useState(
    modifyTextHistoryValues.isOptimizedForSeoEnabled
  )
  const [isGuruModeEnabled, setIsGuruModeEnabled] = useState(
    modifyTextHistoryValues.isGuruModeEnabled
  )
  const [reloadHistoryData, setReloadHistoryData] = useState(0)
  const [timeStamp, setTimeStamp] = useState(Date.now())

  const { templateList, templateOptions, reload, templatesRecordId } = useGetTemplateList(
    'modify_text_templates',
    '/writer/modify-text/templates'
  )

  const { t } = useTranslate()
  const { defaultToolOutPutLanguage } = useLanguages()
  const optionsData = {
    myText,
    whatToDo,
    wordLength,
    keyWords,
    writerLengthOption,
    isOptimizedForSeoEnabled,
    isGuruModeEnabled,
    creativity,
    state,
  }

  // Load form data from query params
  useEffect(() => {
    const data = queryToObj(location.search)

    dispatch({
      type: 'LOAD_FROM_QUERY',
      payload: data,
    })

    if (data.mytext) setMyText(data.mytext)
    if (data.whattodo) setWhatToDo(data.whattodo)
    if (data.creativity)
      setCreativity(
        Creativity.find((c) => data.creativity?.toLowerCase() === c.value.toLowerCase())?.value
      )
    if (data.guru) setIsGuruModeEnabled(true)
    if (data.seo) setIsOptimizedForSeoEnabled(true)
  }, [location])

  const clearInputs = () => {
    documentContext.clearContext()
    setMyText('')
    setWhatToDo('')
    setCreativity('Medium')
    setIsGuruModeEnabled(false)
    setWordLength({ id: '500', checked: true })
    setKeyWords('')
    dispatch({ type: actionTypes.RESET })
    setTemplateId({ label: '', value: '' })
    setTemplateName('')
    dispatch({
      type: actionTypes[`SET_LANGUAGE`],
      payload: defaultToolOutPutLanguage.value,
    })
    setIsOptimizedForSeoEnabled(false)
  }

  const handleDeleteTemplate = async (templateId) => {
    setTemplateToDelete(templateId)
    setModalIsVisible({ content: 'DELETE_TEMPLATE', visible: true })
  }

  useEffect(() => {
    if (!completionState.message) return
    setGeneratedText(completionState.message)
  }, [completionState.message])

  useEffect(() => {
    if (!completionState.command) return
    const cmd = completionState.command
    if (cmd.wordsUsed) {
      context.setWordsLeft((prev) => prev - cmd.wordsUsed)
    }
    if (cmd.error) errorToast(t('eleo-error-generic'))
  }, [completionState.command])

  const handleSubmitWriterOptions = async (loaderName) => {
    setTimeStamp(Date.now())
    setGeneratedText('')
    setLoading({ name: loaderName || 'generate', status: true })

    const kwargs = {
      stream: true,
      temperature: convertCreativityToTemperature(optionsData.creativity),
    }
    let formData = new FormData()
    formData.append('model', model?.value)
    formData.append('tool', 'modifyText')
    formData.append('writerOptions', JSON.stringify(optionsData))
    formData.append('kwargs', JSON.stringify(kwargs))
    formData = await addContextToFormData(formData, documentContext.docContext)

    try {
      await fetchCompletion('/api/ai/writer', formData)
      setTimeout(() => setReloadHistoryData(new Date()), 500)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading({ name: loaderName || 'generate', status: false })
    }
  }

  const handleStopRequest = () => {
    if (abortController) {
      // Abort the request by aborting the controller
      abortController.abort()
    }
  }

  const handleTemplate = async () => {
    const data = {
      templatesRecordId,
      accountId: authContext.user.account_id,
      templateId: templateId.value || null,
      modify_text_templates: {
        templateName: templateName,
        myText,
        whatToDo,
        wordLength: wordLength.id,
        writerLengthOption,
        isOptimizedForSeoEnabled,
        isGuruModeEnabled,
        creativity,
        author: state.author,
        language: state.language,
        style: state.style,
        tone: state.tone,
        action: state.action,
      },
    }

    if (!templateName.length) {
      toast.error(
        t('eleo-save-template-error-please-point-template-name', 'Please point template name')
      )
      return
    }

    // if (templateName && templateName.length < 5) {
    //   toast.error(
    //     t('eleo-save-template-error-name-length', 'Template name should be more than 5 characters')
    //   )
    //   return
    // }

    try {
      setLoading({ name: 'template', status: true })
      const response = templateId.value
        ? await WriterAPIService.updateModifyTemplate(data)
        : await WriterAPIService.saveModifyTextTemplate(data)
      const responseJSON = await response.json()
      if (responseJSON.success) {
        toast.success(t(`eleo-success-${responseJSON.message}`, responseJSON.message))
        setModalIsVisible({ content: '', visible: false })
        setTemplateName('')
        reload()
      } else {
        toast.error(t(`eleo-error-${responseJSON.message}`, responseJSON.message))
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      setLoading({ name: 'template', status: false })
    }
  }

  const handleSetValuesToInputs = (data, itemToSet, showGeneratedTextValue) => {
    const template = itemToSet.data?.isReadyMade
      ? modifyTextReadyMadeTemplates.find((item) => item.id === itemToSet.value)
      : data.find((item) => item.id === itemToSet.value)

    if (!template) return

    if (itemToSet.data?.isReadyMade) {
      documentContext.addDocument({
        label: itemToSet.label.toString(),
        type: 'template',
        content: template.value,
        id: template.id,
      })
    }

    if (template.context) {
      const story = documentContext.stories.data.find((story) => story.id === template.context.id)
      documentContext.addDocument({
        label: story.label,
        content: story.content,
        id: story.id,
        type: 'story',
      })
    }

    setTemplateName(template?.templateName)

    if (template?.isOptimizedForSeoEnabled)
      setIsOptimizedForSeoEnabled(template?.isOptimizedForSeoEnabled)
    setIsGuruModeEnabled(template?.isGuruModeEnabled)

    if (template.writerLengthOption) {
      setWriterLengthOption(template.writerLengthOption)
    }

    if (template.wordLength) {
      setWordLength({ id: template.wordLength, checked: true })
    }

    if (template.myText) {
      setMyText(template.myText)
    }
    if (template.whatToDo) {
      setWhatToDo(template.whatToDo)
    }

    if (template.creativity) {
      setCreativity(template.creativity)
    }
    if (showGeneratedTextValue) {
      setTimeStamp(Date.now())
      setGeneratedText(template.generatedText)
    }

    Object.keys(state).forEach((key) => {
      if (template[key]) {
        dispatch({
          type: actionTypes[`SET_${key.toUpperCase()}`],
          payload: template[key],
        })
      }
    })
  }

  useEffect(() => {
    if (templateId.value) {
      documentContext.removeDocumentsOfType('template')
      handleSetValuesToInputs(templateList, templateId, false)
    }
  }, [templateId, templateList])

  //save input values into history
  useEffect(() => {
    dispatchAction(
      setModifyTextInputHistory({
        myText,
        whatToDo,
        writerLengthOption,
        wordLength,
        creativity,
        selectOptionValues: state,
        templateId,
        showAdvanced: isShowAdvanced,
        isOptimizedForSeoEnabled,
        isGuruModeEnabled,
        model,
      })
    )
    dispatchAction(setModifyTextGeneratedResultHistory(generatedText))
  }, [
    myText,
    whatToDo,
    writerLengthOption,
    wordLength,
    creativity,
    loading,
    state,
    templateId,
    isShowAdvanced,
    isOptimizedForSeoEnabled,
    isGuruModeEnabled,
    model,
  ])

  // Fetch history
  useEffect(() => {
    const getHistory = async () => {
      const response = await WriterAPIService.getModifyHistories()
      const { data } = await response.json()

      const historyData = data?.history.map((item) => ({
        id: item.id,
        question: item.writer_template?.myText,
        answer: item.generated_response,
        date_created: item.date_created,
        context: item.writer_template?.context,
        words: item.writer_template?.wordsUsed,
        data: item,
      }))
      setHistory(historyData ?? [])
    }

    getHistory()
  }, [reloadHistoryData])

  async function handleLoadItem(id) {
    if (!hasAccess('Standard')) {
      context.modal.show({
        children: <UpgradePlan availableFrom='Standard' />,
        modalCardClassNames: '!bg-transparent !px-[0px] !py-[0px]',
      })
      return
    }
    const historyItem = history.find((item) => item.id === id)?.data

    const template = {
      myText: historyItem.writer_template.myText,
      whatToDo: historyItem.writer_template.whatToDo,
      action: historyItem.writer_template.action,
      checkBoxValues: historyItem.writer_template.checkBoxValues,
      author: historyItem.writer_template.author,
      creativity: historyItem.writer_template.creativity,
      format: historyItem.writer_template.format,
      isOptimizedForSeoEnabled: historyItem.writer_template.isOptimizedForSeoEnabled,
      keyWords: historyItem.writer_template.keyWords,
      isGuruModeEnabled: historyItem.writer_template.isGuruModeEnabled,
      language: historyItem.writer_template.language,
      style: historyItem.writer_template.style,
      templateName: '',
      tone: historyItem.writer_template.tone,
      topic: historyItem.writer_template.topic,
      wordLength: historyItem.writer_template.wordLength,
      writerContext: historyItem.writer_template.writerContext,
      writerLengthOption: historyItem.writer_template.writerLengthOption,
      generatedText: historyItem.generated_response,
      context: historyItem.writer_template?.context?.isStory
        ? historyItem.writer_template.context
        : null,
    }

    handleSetValuesToInputs([template], checkedHistory.radioBoxName, true)
    setIsHistoryVisible(false)
  }

  async function handleDeleteHistoryItems(items) {
    setModalIsVisible({ content: 'DELETE_HISTORY', visible: true, items: items })
  }
  async function handleClearHistory() {
    setModalIsVisible({ content: 'CLEAR_HISTORY', visible: true })
  }

  function handleTourProgress(e) {
    if (e.action === 'reset') setIsShowAdvanced(false)
    if (!e.action === 'update') return

    const step = e.index

    if (step === 2) setIsShowAdvanced(true)
  }
  return (
    <>
      <ToolLayoutPrimary
        folded={folded}
        setFolded={setFolded}
        storageKey='modify-text'
        leftSideContent={
          isHistoryVisible ? (
            <History
              headerText={t('eleo-history-header-modify', 'modify text / history')}
              dateText={t('eleo-history-item-date', 'Started:')}
              storyText={t('eleo-history-item-story', 'Your story:')}
              wordCounterText={t('eleo-history-item-words', 'words:')}
              historyEmptyText={t('eleo-tool-history-is-empty', 'history is empty')}
              clearHistoryText={t('eleo-history-header-clear', 'Clear History')}
              storyMissingText={t('eleo-history-item-story-missing', 'None')}
              logo={eleoLogo}
              storageKey='modify-text'
              items={history}
              closeHistory={() => setIsHistoryVisible(false)}
              loadItem={handleLoadItem}
              removeItems={handleDeleteHistoryItems}
              clearHistory={handleClearHistory}
            />
          ) : (
            <ModifyTextGenerator
              dispatch={dispatch}
              actionTypes={actionTypes}
              setWordLength={setWordLength}
              setMyText={setMyText}
              setWhatToDo={setWhatToDo}
              setKeyWords={setKeyWords}
              wordLength={wordLength}
              setWriterLengthOption={setWriterLengthOption}
              writerLengthOption={writerLengthOption}
              setCreativity={setCreativity}
              creativity={creativity}
              handleSubmitWriterOptions={handleSubmitWriterOptions}
              setModalIsVisible={setModalIsVisible}
              clearInputs={clearInputs}
              optionsData={optionsData}
              templateOptions={templateOptions}
              setTemplateId={setTemplateId}
              loading={loading}
              history={history}
              setHistory={setHistory}
              checkedHistory={checkedHistory}
              setCheckedHistory={setCheckedHistory}
              reloadHistoryData={reloadHistoryData}
              templateId={templateId}
              handleDeleteTemplate={handleDeleteTemplate}
              isShowAdvanced={isShowAdvanced}
              setIsShowAdvanced={setIsShowAdvanced}
              handleSetValuesToInputs={handleSetValuesToInputs}
              isOptimizedForSeoEnabled={isOptimizedForSeoEnabled}
              setIsOptimizedForSeoEnabled={setIsOptimizedForSeoEnabled}
              isGuruModeEnabled={isGuruModeEnabled}
              setIsGuruModeEnabled={setIsGuruModeEnabled}
              isHistoryVisible={isHistoryVisible}
              setIsHistoryVisible={setIsHistoryVisible}
              documentContext={documentContext}
              model={model}
              setModel={setModel}
            />
          )
        }
        rightSideContent={
          <WriterResult
            generatedText={generatedText}
            setGeneratedText={setGeneratedText}
            regenerate={() => handleSubmitWriterOptions('regenerate')}
            folded={folded}
            loading={loading}
            toolName='writer-modifyText'
            handleSaveGeneratedTextHistory={(text) =>
              dispatchAction(setModifyTextGeneratedResultHistory(text))
            }
            handleStopRequest={handleStopRequest}
            selectedLanguage={state.language}
            topic={myText}
            key={timeStamp}
          />
        }
      >
        <>
          {modalIsVisible.visible && (
            <ActionModal
              checkedHistory={checkedHistory}
              items={modalIsVisible.items}
              setCheckedHistory={setCheckedHistory}
              setReloadHistoryData={setReloadHistoryData}
              modalKey={modalIsVisible.content}
              templateId={templateId}
              templateName={templateName}
              setTemplateName={setTemplateName}
              handleTemplate={handleTemplate}
              loading={loading}
              setModalIsVisible={setModalIsVisible}
              reloadTemplatesData={reload}
              clearHistoryEndpoint={() => WriterAPIService.clearModifyHistory()}
              deleteSelectedHistoryEndpoint={(data) => WriterAPIService.deleteModifyHistory(data)}
              deleteTemplateEndpoint={() =>
                WriterAPIService.deleteModifyTextTemplate({
                  templateId: templateToDelete,
                })
              }
            />
          )}
          <ContextModal documentContext={documentContext} />
          <HelpTooltips group='writer' tool='modifyText' />
          <Tour steps={steps} name='writer-modify' callback={handleTourProgress} />
        </>
      </ToolLayoutPrimary>
    </>
  )
}

export default ModifyText
