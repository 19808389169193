import { useTranslate } from '@tolgee/react'
import { errorToast } from 'helpers'
import { useState, useCallback } from 'react'
import { toast } from 'react-toastify'
import { UserGroupsAPIService } from 'service/user-groups-api.service'

const useGetGroupListsInfo = () => {
  const { t } = useTranslate()
  const [isGroupListLoading, setIsGroupListLoading] = useState(false)
  const [groupListOptions, setGroupListOptions] = useState([])
  const [groupList, setGroupList] = useState([])

  const handleGetGroupList = useCallback(async (restrictGroupUser = false) => {
    setIsGroupListLoading(true)
    try {
      const response = await UserGroupsAPIService.getGroupList(restrictGroupUser)
      setGroupList(response.data)
      const options = response.data?.map((group) => {
        return {
          value: group._id,
          label: group.name,
        }
      })

      setGroupListOptions(options)
    } catch (error) {
      errorToast(t('eleo-error-generic'))
    } finally {
      setIsGroupListLoading(false)
    }
  }, [])

  return { isGroupListLoading, groupList, groupListOptions, handleGetGroupList }
}

export default useGetGroupListsInfo
