import { useTranslate } from '@tolgee/react'
import { errorToast, successToast, transformSelectOptionValue } from 'helpers'
import React, { useEffect, useState } from 'react'
import { UserGroupsAPIService } from 'service/user-groups-api.service'
import { Button, ReactSelect } from 'ui'

const ChangeRoleModalView = ({ invitationId, currentRole, groupId, callBack }) => {
  const [selectedRole, setSelectedRole] = useState('')

  const { t } = useTranslate()

  const roleOptions = [
    {
      label: t('eleo-groups-list-table-remove-from-group-permission-admin', 'admin'),
      value: 'admin',
    },
    {
      label: t('eleo-groups-list-table-remove-from-group-permission-member', 'member'),
      value: 'member',
    },
  ]

  const handleChangeGrantStatus = async () => {
    try {
      await UserGroupsAPIService.changeUserRole({
        invitationId,
        groupId,
        newRole: selectedRole,
      })
      successToast(t('eleo-user-group-member-role-changed', 'Member  role successfully updated'))
      callBack()
    } catch (err) {
      errorToast(t('eleo-error-generic'))
    }
  }

  useEffect(() => {
    if (currentRole) {
      setSelectedRole(currentRole)
    }
  }, [currentRole])

  return (
    <div>
      <div className='flex w-full flex-col gap-[10px] py-[15px]'>
        <ReactSelect
          options={roleOptions}
          menuPlacement='top'
          setSelectedValue={(cb) => setSelectedRole(cb.value)}
          value={transformSelectOptionValue(roleOptions, selectedRole, null)}
        />
        <Button
          color='green'
          variant='small'
          className='!w-full'
          onClick={() => handleChangeGrantStatus()}
        >
          {t('eleo-profile-edit-profile-save-button', 'save')}
        </Button>
      </div>
    </div>
  )
}

export default ChangeRoleModalView
