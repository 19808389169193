import { useTranslate } from '@tolgee/react'

export const useChatBotVisibilityOptions = () => {
  const { t } = useTranslate()

  const visibilityOptions = [
    {
      value: 'public',
      requiresApproval: true, //for later implementation
      label: t('eleo-chatbot-visibility-option-public', 'Public'),
    },
    {
      value: 'private',
      requiresApproval: false,
      label: t('eleo-chatbot-visibility-option-private', 'Private'),
    },
    {
      value: 'link',
      requiresApproval: false,
      label: t('eleo-chatbot-visibility-option-link', 'Link'),
    },
    {
      value: 'groups',
      requiresApproval: false,
      label: t('eleo-chatbot-visibility-option-groups', 'Groups'),
    },
  ]

  const visibilityOptionsEnum = {
    PUBLIC: 'public',
    PRIVATE: 'private',
    LINK: 'link',
    GROUPS: 'groups',
  }

  return { visibilityOptions, visibilityOptionsEnum }
}
