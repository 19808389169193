import React, { useState } from 'react'
import { T, useTranslate } from '@tolgee/react'
import moment from 'moment'
import classNames from 'classnames'
import { Button, ReactSelect } from 'ui'
import { Trash } from 'phosphor-react'
import { errorToast, successToast } from 'helpers'
import { UserGroupsAPIService } from 'service/user-groups-api.service'

const ManageAccessModalView = ({
  userChatBots,
  chatbotInfo,
  changeAccessLoader,
  handleChangeGrantStatus,
  invitationId,
  chatBotOptions,
  isAdminOrOwner,
  handleRemoveChatbotFromGroup,
  callBack,
  userGroup,
  secondary = false,
}) => {
  const { t } = useTranslate()
  const [selectedChatBot, setSelectedChatBot] = useState({ value: '', label: '' })

  const handleAddChatbotToTheGroup = async (chatBotId) => {
    if (!selectedChatBot.value) {
      return errorToast(t('eleo-user-groups-select-chatbot-alert', 'Please select chatbot!'))
    }

    try {
      await UserGroupsAPIService.addChatBotToTheGroup({ groupId: userGroup, chatBotId })
      callBack()
      successToast(t('eleo-user-groups-chatbot-added-successfully', 'Chatbot added successfully!'))
    } catch (err) {
      errorToast(t('eleo-user-groups-error-chatbot-attached', err.response.data.message))
    }
  }

  return (
    <div className='mt-[10px] flow-root w-full max-w-[500px]'>
      <div className='-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
        <div className='inline-block max-h-[500px] min-w-full overflow-y-scroll px-[25px] py-2 align-middle'>
          {secondary && isAdminOrOwner ? (
            <div className='mb-[20px] flex items-center justify-between gap-[10px]'>
              <ReactSelect
                options={chatBotOptions}
                setSelectedValue={(val) => setSelectedChatBot(val)}
              />
              <Button
                color='green'
                variant='small'
                className='!py-[8px]'
                onClick={() => handleAddChatbotToTheGroup(selectedChatBot.value)}
              >
                <p className='font-bold'>
                  <T keyName='eleo-user-groups-attach-chatbot'>Attach</T>
                </p>
              </Button>
            </div>
          ) : null}
          <table className='min-w-full divide-y divide-gray-300'>
            <thead>
              <tr>
                <th
                  scope='col'
                  className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0'
                >
                  <T keyName='eleo-groups-list-table-name'>Name</T>
                </th>
                {!secondary ? (
                  <>
                    {' '}
                    <th
                      scope='col'
                      className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0'
                    >
                      <T keyName='eleo-groups-list-table-last-interaction'>Last Interaction</T>
                    </th>
                    <th
                      scope='col'
                      className='py-3.5 pl-4  text-center text-sm font-semibold text-gray-900 sm:pl-0'
                    >
                      <T keyName='eleo-groups-list-table-access-status'>Access Status</T>
                    </th>
                  </>
                ) : null}
                {secondary && isAdminOrOwner ? (
                  <th
                    scope='col'
                    className='py-3.5 pl-4 pr-3 text-right text-sm font-semibold text-gray-900 sm:pl-0'
                  >
                    <T keyName='eleo-groups-list-table-remove-chatbot'>Remove chatbot</T>
                  </th>
                ) : null}
              </tr>
            </thead>
            <tbody className=' divide-y divide-gray-200  bg-white'>
              {secondary ? (
                <>
                  {chatbotInfo?.map((chatbot, index) => (
                    <tr key={index}>
                      <td className='whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0'>
                        <a
                          target='_blank'
                          className='text-brand-violet underline'
                          href={`${window.location.origin}/chatbot/chat/${chatbot.name}`}
                          rel='noreferrer'
                        >
                          <div className='flex items-center'>{chatbot.name}</div>
                        </a>
                      </td>
                      {isAdminOrOwner && (
                        <td className='whitespace-nowrap py-5   text-sm sm:pl-0'>
                          <div className='justify-center'>
                            <Button
                              color='pink'
                              variant='small'
                              className='!w-[60%]  !px-[10px] !py-[8px]'
                              onClick={() => handleRemoveChatbotFromGroup(chatbot.chatBotId)}
                            >
                              <p className='text-[13px] font-bold'>
                                <T keyName='eleo-user-groups-remove-chatbot'>Remove chatbot</T>
                              </p>
                            </Button>
                          </div>
                        </td>
                      )}
                    </tr>
                  ))}
                </>
              ) : (
                <>
                  {userChatBots?.map((chatbot, index) => (
                    <tr key={chatbot.chatBotId}>
                      <td className='whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0'>
                        <a
                          target='_blank'
                          className='text-brand-violet underline'
                          href={`${window.location.origin}/chat/${chatbot.name || chatbotInfo[index]}`}
                          rel='noreferrer'
                        >
                          <div className='flex items-center'>
                            {chatbot.name || chatbotInfo[index]}
                          </div>
                        </a>
                      </td>
                      <td className='whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0'>
                        <div className='flex items-center'>
                          {chatbot?.lastInteraction
                            ? moment(chatbot.lastInteraction).format('DD/MM/YYYY HH:mm')
                            : '--'}
                        </div>
                      </td>
                      <td className='whitespace-nowrap px-3 py-5 text-sm text-gray-500'>
                        <div
                          onClick={() =>
                            handleChangeGrantStatus(
                              chatbot.grantStatus === 'granted' ? 'denied' : 'granted',
                              invitationId,
                              chatbot.chatBotId
                            )
                          }
                          role='button'
                          className={classNames(
                            'hover:text-brand-violet/700 flex cursor-pointer items-center justify-center gap-[10px] font-bold',
                            chatbot.grantStatus === 'granted'
                              ? 'text-brand-pink'
                              : 'text-brand-violet',
                            {
                              'pointer-events-none opacity-60': changeAccessLoader,
                            }
                          )}
                        >
                          {chatbot.grantStatus === 'granted' ? (
                            <T keyName='eleo-groups-list-table-revoke-access-single-user'>
                              Revoke Access
                            </T>
                          ) : (
                            <T keyName='eleo-groups-list-table-grant-access-single-user'>
                              Grant Access
                            </T>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default ManageAccessModalView
