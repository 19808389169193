export const chatBotPanel = {
  activeChatBot: {
    title: 'Active Chatbot',
    content: `Select a previously created Chatbot to modify its settings. Manage multiple Chatbots for various uses.`,
    tolgee_id: 'eleo-tool-chat-bot-panel-help-tooltip-1',
  },

  yourChatBotName: {
    title: 'Your Chatbot name',
    content: `Pick a distinctive name for your Chatbot. This name will be featured in your Chatbot's unique link.`,
    tolgee_id: 'eleo-tool-chat-bot-panel-help-tooltip-2',
  },

  yourStory: {
    title: 'Your Story',
    content:
      "Choose 'Your Story' as the content source for your Chatbot. Create and regularly update this content in the 'Your Story' section under 'My Account' to reflect your personal, company, or product narrative.",
    tolgee_id: 'eleo-tool-chat-bot-panel-help-tooltip-3',
  },

  tone: {
    title: 'Tone',
    content:
      "Set the conversational tone of your Chatbot to align with your brand's voice or the intended user experience.",
    tolgee_id: 'eleo-tool-chat-bot-panel-help-tooltip-4',
  },

  language: {
    title: 'Language',
    content:
      "Select the default language for your Chatbot. It will automatically respond in the same language as the user's query if different.",
    tolgee_id: 'eleo-tool-chat-bot-panel-help-tooltip-5',
  },

  intro: {
    title: 'Intro',
    content: 'Input a welcoming message that your Chatbot will use to initiate conversations.',
    tolgee_id: 'eleo-tool-chat-bot-panel-help-tooltip-6',
  },

  avatar: {
    title: 'Avatar',
    content:
      "Upload an image file to serve as your Chatbot's avatar, giving it a recognizable visual identity.",
    tolgee_id: 'eleo-tool-chat-bot-panel-help-tooltip-7',
  },

  responseLength: {
    title: 'Response Length',
    content: 'Define the maximum word count for each response your Chatbot generates.',
    tolgee_id: 'eleo-tool-chat-bot-panel-help-tooltip-8',
  },

  monthlyLimit: {
    title: 'Monthly Limit',
    content:
      "Set a maximum word count for your Chatbot's responses each month. Track the start and end dates of your billing cycle in the 'Plans' section under 'My Account.",
    tolgee_id: 'eleo-tool-chat-bot-panel-help-tooltip-9',
  },

  clear: {
    title: 'CLEAR',
    content: "Click to revert your Chatbot's settings to their defaults",
    tolgee_id: 'eleo-tool-chat-bot-panel-help-tooltip-10',
  },
  generateChatBot: {
    title: 'GENERATE CHATBOT',
    content:
      'Create your Chatbot and access links and embedding codes in the right-side window or below, Copy and embed this link to your Chatbot on your website, emails, or social media platforms.',
    tolgee_id: 'eleo-tool-chat-bot-panel-help-tooltip-12',
  },
  copyLink: {
    title: 'COPY LINK',
    content: 'Easily copy the Chatbot link to paste elsewhere with shortcut commands like Ctrl-V.',
    tolgee_id: 'eleo-tool-chat-bot-panel-help-tooltip-13',
  },
  openChat: {
    title: 'OPEN CHAT',
    content:
      'Start a conversation with your Chatbot. As an owner, view all user interactions with the Chatbot.',
    tolgee_id: 'eleo-tool-chat-bot-panel-help-tooltip-14',
  },
  activeInactive: {
    title: 'Active/Inactive',
    content: 'Switch your Chatbot between active and inactive modes',
    tolgee_id: 'eleo-tool-chat-bot-panel-help-tooltip-15',
  },
  chatIcon: {
    title: 'Chat Icon',
    content: 'Choose an icon that will appear on your website to initiate Chatbot conversations.',
    tolgee_id: 'eleo-tool-chat-bot-panel-help-tooltip-16',
  },
  iconPlace: {
    title: 'Icon Place',
    content: 'Select the location on your website where the Chatbot icon will be displayed.',
    tolgee_id: 'eleo-tool-chat-bot-panel-help-tooltip-17',
  },
  chatWindowWidth: {
    title: 'Chat Window Width',
    content: 'Adjust the width of the Chatbot window in pixels to fit your website layout.',
    tolgee_id: 'eleo-tool-chat-bot-panel-help-tooltip-18',
  },
  chatWindowHeight: {
    title: 'Chat Window Height',
    content: 'Adjust the height of the Chatbot window in pixels to fit your website layout.',
    tolgee_id: 'eleo-tool-chat-bot-panel-help-tooltip-19',
  },
  websites: {
    title: 'Website URL(S)',
    content: 'Provide URLs where the Chatbot will be placed for user interaction.',
    tolgee_id: 'eleo-tool-chat-bot-panel-help-tooltip-20',
  },
  codeForWebsite: {
    title: 'Code For The website',
    content: 'Use this code to embed the Chatbot on your designated website.',
    tolgee_id: 'eleo-tool-chat-bot-panel-help-tooltip-21',
  },
  copyCode: {
    title: 'COPY CODE',
    content: 'Copy the embed code to your clipboard for easy pasting using shortcuts like Ctrl-V.',
    tolgee_id: 'eleo-tool-chat-bot-panel-help-tooltip-22',
  },
  applyYourSettings: {
    title: 'APPLY YOUR SETTINGS',
    content: "Click to save your current settings, including the Chatbot's Active/Inactive status.",
    tolgee_id: 'eleo-tool-chat-bot-panel-help-tooltip-23',
  },
  telegramToken: {
    title: 'Telegram Bot Token',
    content:
      'Token, which you can generate on Telegram via BotFather after creating a new Telegram bot. In order to allow Eleo bot to work, you need to add the Telegram bot as an admin in your desired channel.',
    tolgee_id: 'eleo-tool-chat-bot-panel-help-tooltip-24',
  },
  activate: {
    title: 'Activate',
    content:
      "Click 'Activate' to enable the Telegram webhook and receive the latest messages from the Telegram channel.",
    tolgee_id: 'eleo-tool-chat-bot-panel-help-tooltip-25',
  },
  turnOff: {
    title: 'Turn off',
    content:
      "Click 'Turn off' if you want to disable the Telegram webhook and stop receiving messages from the Telegram channel.",
    tolgee_id: 'eleo-tool-chat-bot-panel-help-tooltip-26',
  },

  instructions: {
    title: 'Instructions',
    content: 'Instructions for your chatbot to follow. This will dictate its behaviour.',
    tolgee_id: 'eleo-tool-chat-bot-panel-help-tooltip-27',
  },
}
