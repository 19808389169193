import { ContentCopy, MoreVert, OpenInNew, Settings } from '@mui/icons-material'
import { Popover } from '@mui/material'
import { T } from '@tolgee/react'
import classNames from 'classnames'
import { CopyToClipBoard } from 'helpers/clipboard'
import { useState } from 'react'
import { CheckBox, ListPosition, Microbutton } from 'ui'

const chatbotStates = {
  active: {
    styles: { background: '#00C19C' },
    text: <T keyName='eleo-chatbot-state-active-cta'>Chatbot is up to date</T>,
  },
  pending: {
    styles: { background: '#F38F2B' },
    text: <T keyName='eleo-chatbot-state-pending-cta'>Publish to update changes</T>,
  },
  disabled: {
    styles: { background: 'transparent', border: '1px solid #D9D9D9', color: '#363636' },
    text: <T keyName='eleo-chatbot-state-disabled-cta'>Disabled</T>,
  },
  unpublished: {
    styles: { background: '#FB427C' },
    text: <T keyName='eleo-chatbot-state-unpublished-cta'>Publish to activate</T>,
  },
}

export function PublishItem({
  text,
  state = 'unpublished',
  isActive = false,
  setIsActive,
  url,
  handleSettings,
}) {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const popoverId = open ? 'save-template' : undefined

  return (
    <div
      className='box-border h-[90px] max-h-[100px] min-w-[225px] max-w-[300px] cursor-pointer select-none rounded-[4px] text-white shadow-sm transition-colors duration-300'
      style={chatbotStates[isActive ? state : 'disabled'].styles}
      onClick={() => setIsActive(!isActive)}
    >
      <div
        className={classNames(
          'flex h-[43px] justify-between border-b p-[7px] pl-3',
          isActive ? 'border-white/20' : 'border-[#D9D9D9]'
        )}
      >
        <label className='pointer-events-none flex items-center gap-1'>
          <CheckBox
            checkBoxValue={isActive}
            classes='checked:!border-white checked:!bg-white checked:before:!bg-brand-white '
            checkColor={chatbotStates[state].styles.background}
          />
          <div className='text-[13px] leading-[1.5em]'>{text}</div>
        </label>

        <div className='flex' onClick={(e) => e.stopPropagation()}>
          {handleSettings && (
            <Microbutton
              variant='plain'
              className='hover:bg-brand-violet'
              icon={<Settings fontSize='inherit' />}
              onClick={handleSettings}
            />
          )}
          {url && (
            <>
              <Microbutton
                variant='plain'
                className='hover:bg-brand-violet'
                icon={<MoreVert fontSize='inherit' />}
                onClick={handleClick}
              />

              <Popover
                id={popoverId}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <div className='p-2 pb-[10px]'>
                  <ListPosition
                    text={<T keyName='eleo-open-in-new'>Open in new window</T>}
                    icon={<OpenInNew fontSize='inherit' />}
                    onClick={() => {
                      window.open(url, '_blank')
                      handleClose()
                    }}
                  />
                  <ListPosition
                    text={<T keyName='eleo-copy-link'>Copy link</T>}
                    icon={<ContentCopy fontSize='inherit' />}
                    onClick={() => {
                      CopyToClipBoard(url)
                      handleClose()
                    }}
                  />
                </div>
              </Popover>
            </>
          )}
        </div>
      </div>
      <div
        className={classNames(
          'flex items-center justify-center text-nowrap px-[14px] py-3 text-[13px] font-bold uppercase',
          !isActive && 'opacity-50'
        )}
      >
        {chatbotStates[isActive ? state : 'disabled'].text}
      </div>
    </div>
  )
}
